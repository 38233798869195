import { referenceDataAxios } from "./api";
referenceDataAxios.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_GAME_STATE_API_KEY ?? "";

// TODO make dynamic text a separate API endpoint and remove from refdata

export const getReferenceData = async () => {
  try {
    const response = await referenceDataAxios.get(`/GetReferenceData`);
    const { Text, ...rest } = response.data;
    return rest;
  } catch {
    return null;
  }
};

export const getDynamicText = async () => {
  try {
    const response = await referenceDataAxios.get(`/GetReferenceData`);
    return response.data.Text;
  } catch (e) {
    return null;
  }
};

export const getPlayerEvents = async () => {
  const response = await referenceDataAxios.get(`/GetPlayerEvents`, {
    params: {
      guid: localStorage.getItem("guid"),
    },
  });
  const { ...rest } = response.data;
  return rest;
};
