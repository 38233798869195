import React from "react";
import { Drawer, Divider, Button, Tooltip, Typography } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ReorderIcon from "@mui/icons-material/Reorder";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { IGameState } from "../../../../../types/gameState.types";
import { advanceOrderState } from "../../../../../modules/gameState/service";
import ProductionQueue from "../productionQueue/ProductionQueue";
import { IScreenProps } from "../../../../../types/app.types";
import { ITextSet } from "../../../../../types/text.types";
import AssignmentIcon from "@mui/icons-material/Assignment";

const useStyles = makeStyles((theme: Theme) => ({
  sideNav: {
    // marginTop: '-37px',
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
    top: "90vh",
    right: "0",
    // borderRadius: '8px 0 0 8px',
    backgroundColor: "rgba(0,0,0,.1)",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  paper: {
    // maxHeight: "22vh",
    minHeight: "22vh",
    overflowY: "auto",
  },
}));

interface Props {
  gameState: IGameState;
  text: ITextSet;
  queue: number;
}

const ProductionPanel: React.FC<Props> = ({ gameState, text, queue }) => {
  //const [panelOpen, setPanelOpen] = useState(false);
  const classes = useStyles();

  const productionItems = [[...gameState.ProductionItems[0]] || [], [...gameState.ProductionItems[1]] || []];

  productionItems.forEach((arr) => arr.shift());

  // const togglePanel = () => {
  //   setPanelOpen(!panelOpen)
  // }

  return (
    <Paper className={classes.paper}>
      {queue === 1 && (
        <ProductionQueue
          title={`${text.productionConsole.produtionQueue} 1`}
          text={text}
          productionItems={productionItems[0]}
        />
      )}

      {gameState.AdditionalLineActive && queue === 2 && gameState.ProductionItems.length > 1 && (
        <ProductionQueue
          title={`${text.productionConsole.produtionQueue} 2`}
          text={text}
          productionItems={productionItems[1]}
        />
      )}
    </Paper>
  );
};

export default ProductionPanel;
