import axios from "axios";
import config from "../../config";

export const gameStateAxios = (() => {
  const gameStateAxios = axios.create({
    baseURL: config.locations.gameStateAPI,
  });

  gameStateAxios.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.baseURL += `/${localStorage.getItem("dataset")}`;
    config.params.guid = localStorage.getItem("guid");
    return config;
  });
  gameStateAxios.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_GAME_STATE_API_KEY ?? "";

  return gameStateAxios;
})();
