import React from "react";

import { IScreenProps } from "../../../types/app.types";
import { ITradingReportViewModel } from "../../../types/gameState.types";
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import NumberFormat from "../../../components/numberFormat/NumberFormat";

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: theme.spacing(2),
  },
  tableCell: {
    padding: theme.spacing(0.1),
    //backgroundColor: theme.palette.secondary.light,
    borderBottom: "none",
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  head: {
    padding: theme.spacing(0.1),
    paddingLeft: theme.spacing(1),
  },
  tableBody: {
    overflowY: "auto",
    //backgroundColor: theme.palette.secondary.light
  },
  table: {
    //overflowY: 'auto',
    padding: theme.spacing(3),
  },
  padding: {
    paddingLeft: theme.spacing(1),
  },
  section: {
    backgroundColor: "white",
  },
  gap: {
    padding: theme.spacing(1),
  },
  subheader: {
    minWidth: "5%",
  },
}));

const TradingReport: React.FC<IScreenProps> = ({ gameState, text, currencyParser, referenceData }) => {
  document.title = `Reports | ${text.wrapper.gameName}`;

  const TradRep = gameState.TradingReports;

  const classes = useStyles();

  const extract = (value: keyof ITradingReportViewModel, addDataAttr: boolean, isNum: boolean, postfix?: string) => {
    return TradRep.map((report, index) => {
      const toReturn = Number(report[value]);

      if (gameState.Round <= index) {
        return <TableCell component="td" key={index} className={classes.tableCell}></TableCell>;
      }

      if (isNum) {
        const numberToReturn = currencyParser.format(report[value]);
        return (
          <TableCell component="td" key={index} className={classes.tableCell} data-attr={addDataAttr ? value : null}>
            {numberToReturn}
            {postfix}
          </TableCell>
        );
      }

      return (
        <TableCell component="td" key={index} className={classes.tableCell} data-attr={addDataAttr ? value : null}>
          {toReturn}
          {postfix}
        </TableCell>
      );
    });
  };

  const subHead = clsx(classes.tableCell, classes.subheader);
  const dataset = localStorage.getItem("dataset");

  return (
    <React.Fragment>
      <Grid container className={classes.cont}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} className={classes.table}>
          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell component="th" className={classes.head}></TableCell>
                  <TableCell component="th" className={classes.head}></TableCell>
                  {[...Array(gameState.NumberOfRounds)].map((e, i) => {
                    return (
                      <TableCell key={i} component="th" className={classes.head}>
                        LEVEL {i + 1}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={clsx(classes.tableBody, "tourTradingReportCash")}>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell} colSpan={gameState.NumberOfRounds + 2}>
                    <strong>{text.tradingReport.cash}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.startingBalance}
                  </TableCell>
                  {extract("StartingBalance", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.investment}
                  </TableCell>
                  {extract("Investment", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.endBalance}
                  </TableCell>
                  {extract("EndBalance", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.netChangeInCash}
                  </TableCell>
                  {extract("NETChange", true, true)}
                </TableRow>
              </TableBody>
              <TableBody className={clsx(classes.tableBody, "tourTradingReportOrders")}>
                <TableRow className={classes.section}>
                  <TableCell colSpan={gameState.NumberOfRounds + 2} className={classes.gap} />
                </TableRow>
                <TableRow>
                  <TableCell component="th" className={classes.tableCell} colSpan={gameState.NumberOfRounds + 2}>
                    <strong>{text.tradingReport.orderCompletion}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.totalAuthorisedOrders}
                  </TableCell>
                  {extract("OrdersPlacedProd", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.valueOrdersCompletion}
                  </TableCell>
                  {extract("OrdersCompletedProd", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.pctAuthorisedVsCompleted}
                  </TableCell>
                  {extract("ProdPlacedVSCompletedPct", false, false, "%")}
                </TableRow>
                {dataset?.includes("culture") ? (
                  <>
                    <TableRow>
                      <TableCell component="td" className={subHead}></TableCell>
                      <TableCell component="td" className={classes.tableCell}>
                        {text.tradingReport.bonuses}
                      </TableCell>
                      {extract("Bonuses", true, true)}
                    </TableRow>
                    <TableRow>
                      <TableCell component="td" className={subHead}></TableCell>
                      <TableCell component="td" className={classes.tableCell}>
                        {text.tradingReport.penalties}
                      </TableCell>
                      {extract("Penalties", true, true)}
                    </TableRow>
                  </>
                ) : null}
              </TableBody>
              <TableBody className={clsx(classes.tableBody, "tourTradingReportSales")}>
                <TableRow className={classes.section}>
                  <TableCell colSpan={gameState.NumberOfRounds + 2} className={classes.gap} />
                </TableRow>
                <TableRow>
                  <TableCell component="th" className={classes.tableCell} colSpan={gameState.NumberOfRounds + 2}>
                    <strong>{text.tradingReport.salesAndDirectCosts}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.moneyFromWIP}
                  </TableCell>
                  {extract("WIP", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.moneyFromOrdersPlaced}
                  </TableCell>
                  {extract("NewOrdersPlaced", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.creditFromInvoices}
                  </TableCell>
                  {extract("CreditFromOutstandingInvoices", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.totalCashIn}
                  </TableCell>
                  {extract("TotalMoneyReceived", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.totalDirectCosts}
                  </TableCell>
                  {extract("ProductionCosts", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.grossProfit}
                  </TableCell>
                  {extract("GrossProfit", true, true)}
                </TableRow>
              </TableBody>
              <TableBody className={clsx(classes.tableBody, "tourTradingReportIndirect")}>
                <TableRow className={classes.section}>
                  <TableCell colSpan={gameState.NumberOfRounds + 2} className={classes.gap} />
                </TableRow>
                <TableRow>
                  <TableCell component="th" className={classes.tableCell} colSpan={gameState.NumberOfRounds + 2}>
                    <strong>{text.tradingReport.indirectCosts}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.commissionPaidToSales}
                  </TableCell>
                  {extract("CommissionPaid", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.runningCosts}
                  </TableCell>
                  {extract("RunningCosts", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.additionalMarketingCosts}
                  </TableCell>
                  {extract("MarketingCosts", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.capexCosts}
                  </TableCell>
                  {extract("CapexAndRepayment", true, true)}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.totalIndirectCosts}
                  </TableCell>
                  {extract("TotalCosts", true, true)}
                </TableRow>
                <TableRow className={classes.section}>
                  <TableCell colSpan={gameState.NumberOfRounds + 2} className={classes.gap} />
                </TableRow>
                <TableRow>
                  <TableCell component="th" className={classes.tableCell} colSpan={gameState.NumberOfRounds + 2}>
                    <strong>{text.tradingReport.summary}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.tradingReport.netProfit}
                  </TableCell>
                  {gameState.TradingReports.map((report, index) => {
                    if (gameState.Round <= index) {
                      return <TableCell key={index} component="td" className={classes.tableCell}></TableCell>;
                    }
                    return (
                      <TableCell component="td" className={classes.tableCell} key={index} data-attr="NETProfit">
                        <span data-attr="NETProfit">{currencyParser.format(report.NETProfit)}</span>
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={subHead}></TableCell>
                  <TableCell component="td" className={classes.tableCell}></TableCell>
                  {gameState.TradingReports.map((report, index) => {
                    if (gameState.Round <= index) {
                      return <TableCell component="td" key={index} className={classes.tableCell}></TableCell>;
                    }

                    return (
                      <TableCell component="td" className={classes.tableCell} key={index} data-attr="NETProfit">
                        {
                          <span data-attr="ProfitPct">
                            <NumberFormat value={report.ProfitPct} />%
                          </span>
                        }
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TradingReport;
