import React, { useEffect } from "react";
import { ConditionallyRender } from "react-util-kit";
import Gist from "react-gist";

import styles from "./FinanceDocs.module.css";
import InformationBox from "../../InformationBox/InformationBox";
import CashflowImage from "../../../../../assets/img/cashflow.jpg";

const FinanceDocs = ({ infoBox, setState }) => {
  useEffect(() => {
    setState((state) => ({
      ...state,
      infoBox: "finance",
    }));
  }, [setState]);

  const showFinanceInfoBox = infoBox === "finance";

  return (
    <div>
      <ConditionallyRender
        ifTrue={showFinanceInfoBox}
        show={
          <InformationBox setState={setState}>
            <p className={styles.infoBoxParagraph}>
              Have you raised any invoices yet? Remember, the devil is in the detail.
            </p>
            <img src={CashflowImage} alt="Cash Flow diagram"></img>
            <p className={styles.infoBoxParagraph}>
              With all your free time just now is there anything you could do to help your team?
            </p>
          </InformationBox>
        }
      />
    </div>
  );
};

export default FinanceDocs;
