import { IRoleSelectionText } from "../types/text.types";
import roles from "../entities/roles";
import { AllRoutes } from "../routing/routes";
import { RoleIDs } from "../routing/routeWrapper";

const getRole = (text: IRoleSelectionText, path: string) => {
  const allRoles = roles(text);
  const currentRoute = AllRoutes.find((route) => route.toUrl() === path);
  const roleID = currentRoute ? currentRoute.getRoleID() : RoleIDs.Facilitator;
  return allRoles[roleID];
};

export default getRole;
