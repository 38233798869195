import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ThemeContainer from "../containers/ThemeContainer";
import GameContainer from "../containers/GameContainer";
import RoleSelectionPage from "../pages/login/RoleSelectionPage";
import LoginSelectionPage from "../pages/login/LoginSelectionPage";
import PlayerLoginPage from "../pages/login/PlayerLoginPage";
import FirewallConsole from "../pages/consoles/firewall/FirewallConsole";

import {
  GameBaseRoute,
  RoleSelectionPageRoute,
  LoginSelectionPageRoute,
  PlayerLoginPageRoute,
  FirewallRoute,
} from "./routes";
import AzureDataTransfer from "../pages/login/AzureDataTransfer";
import FirewallDone from "../pages/login/FirewallDone";
import NotFoundPage from "../pages/NotFoundPage";

export const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={LoginSelectionPageRoute.toUrl()} element={<LoginSelectionPage />} />
      <Route path={PlayerLoginPageRoute.toUrl()} element={<PlayerLoginPage />} />
      <Route path={RoleSelectionPageRoute.toUrl()} element={<PrivateRoute></PrivateRoute>}>
        <Route path="" element={<RoleSelectionPage />} />
      </Route>
      <Route path={GameBaseRoute.toUrl()} element={<PrivateRoute></PrivateRoute>}>
        <Route
          path="*"
          element={
            <ThemeContainer>
              <GameContainer />
            </ThemeContainer>
          }
        />
      </Route>
      <Route
        path={FirewallRoute.toUrl()}
        element={
          <ThemeContainer>
            <FirewallConsole />
          </ThemeContainer>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
