import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { blue, green, red } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      panelHeader: string;
      mainHeader: string;
      headerLogoUrl: string;
      sidebar: string;
    };
  }
  // allow configuration using `createTheme`
  interface DeprecatedThemeOptions {
    custom?: {
      panelHeader?: string;
      mainHeader?: string;
      headerLogoUrl?: string;
      sidebar?: string;
    };
  }
}

const panelHeaderGradient = "linear-gradient(60deg, #4b75b8, #495abf)";

const theme = createTheme({
  typography: {
    fontFamily: ["Lato"].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: "#331B3F",
    },
    secondary: {
      main: "#e3d5e5",
      light: "#d6cdd6",
    },
    success: {
      main: "#3CBD89",
    },
    error: {
      main: red[500],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "*::-webkit-scrollbar": {
            width: "0.4em",
          },
          "*::-webkit-scrollbar-track": {
            WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle2: {
          fontSize: "0.7rem",
          "@media (min-width:1000px)": {
            fontSize: "0.8rem",
          },
          "@media (min-width:2000px)": {
            fontSize: "1rem",
          },
          "@media (min-width:3000px)": {
            fontSize: "1.2rem",
          },
        },
        subtitle1: {
          "@media (min-width:1000px)": {
            fontSize: "1rem",
          },
          "@media (min-width:2000px)": {
            fontSize: "1.2rem",
          },
          "@media (min-width:3000px)": {
            fontSize: "1.5rem",
          },
        },
        h4: {
          fontWeight: 800,
          margin: "1.3em 0",
        },
        h5: {
          fontWeight: 800,
          margin: "1.12em 0",
        },
      },
    },
  },
  custom: {
    panelHeader: panelHeaderGradient,
    mainHeader: "#331B3F",
    sidebar: " #D8DCD6",
  },
});

const GlobalTheme = responsiveFontSizes(theme);

export default GlobalTheme;
