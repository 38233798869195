import { Grid, Link, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { IGameState } from "../../types/gameState.types";
import StatBox from "../statBox/StatBox";
import ScopeEmissionsForRoundTable from "../tableReportViews/ScopeEmissionsForRoundTable";
import "./bankBalanceChart.css";

const chartOptions = {
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      suggestedMax: 50,
      suggestedMin: 0,
    },
  },
  animation: {
    duration: 5,
  },
  responsive: true,
  maintainAspectRatio: false,
};

const generateChartData = (total: number[], scope1: number[], scope2: number[], scope3: number[], labels: string[]) => {
  return {
    labels,
    datasets: [
      {
        data: scope1,
        backgroundColor: "rgba(0, 255, 0, 0.4)",
        borderColor: "rgba(0, 255, 0, 0.4)",
        lineTension: 0,
        spanGaps: false,
        label: "Scope 1 Emissions (tCO2e)",
        fill: false,
        yAxisID: "y",
      },
      {
        data: scope2,
        backgroundColor: "rgba(0, 0, 255, 0.4)",
        borderColor: "rgba(0, 0, 255, 0.4)",
        lineTension: 0,
        spanGaps: false,
        label: "Scope 2 Emissions (tCO2e)",
        fill: false,
        yAxisID: "y",
      },
      {
        data: scope3,
        backgroundColor: "rgba(100, 100, 192, 0.4)",
        borderColor: "rgba(100, 100, 192, 0.4)",
        lineTension: 0,
        spanGaps: false,
        label: "Scope 3 Emissions (tCO2e)",
        fill: false,
        yAxisID: "y",
      },
    ],
  };
};

const useStyles = makeStyles((theme) => ({
  chart: {
    height: "50vh",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

interface IRequiredText {
  current: string;
  highest: string;
  lowest: string;
  bankBalance?: string;
}

interface Props {
  gameState: IGameState;
  text: IRequiredText;
  scope1?: number[];
  scope2?: number[];
  scope3?: number[];
  total: number[];
  mode?: "full" | "chart" | "values";
}

const ScopeBalanceChart: React.FC<Props> = ({ gameState, text, mode = "full", total, scope1, scope2, scope3 }) => {
  total = total || gameState.CO2ValueList;
  scope1 = scope1 || gameState.CO2S1ValueList;
  scope2 = scope2 || gameState.CO2S2ValueList;
  scope3 = scope3 || gameState.CO2S3ValueList;

  const classes = useStyles();

  const [tableView, setTableView] = useState(false);

  return (
    <React.Fragment>
      {["full", "values"].includes(mode) && (
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <StatBox value={gameState.CO2Min} title={text.lowest} icon="inline"></StatBox>
          </Grid>
          <Grid item xs={4}>
            <StatBox value={gameState.CO2Current} title={text.current}></StatBox>
          </Grid>
          <Grid item xs={4}>
            <StatBox value={gameState.CO2Max} title={text.highest}></StatBox>
          </Grid>
        </Grid>
      )}
      {["full", "chart"].includes(mode) && (
        <Grid item xs={12}>
          <Paper className={classes.chart}>
            <Typography>
              This graph shows the trend for the scope emissions over the selected round{" "}
              <Link onClick={() => setTableView(!tableView)} underline="hover">
                {tableView ? "View As Graph" : "View As Table"}
              </Link>
            </Typography>

            {tableView ? (
              <ScopeEmissionsForRoundTable
                total={total}
                scope1={scope1}
                scope2={scope2}
                scope3={scope3}
                labels={gameState.CO2LabelList}
              />
            ) : (
              <Line
                data={generateChartData(total, scope1, scope2, scope3, gameState.CO2LabelList)}
                options={chartOptions}
              />
            )}
          </Paper>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ScopeBalanceChart;
