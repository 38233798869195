import React from "react";
import { CardContent, Typography, Button, Card, Box } from "@mui/material";
import { Block, Cancel, Check } from "@mui/icons-material";

interface Props {
  handleAcceptEULA: (val: boolean) => void;
}

const Eula: React.FC<Props> = ({ handleAcceptEULA }) => {
  return (
    <Card>
      <CardContent style={{ width: "80vw", margin: "0 auto" }}>
        <Typography component={"h3"} variant="h3">
          Service Level Agreement
        </Typography>
        <iframe
          title="End User License Agreement"
          src="https://gingr.tech/eula/" // TODO: "https://shop.gingr.tech/general-terms-and-conditions/#eula"
          style={{
            width: "80vw",
            height: "80vh",
            display: "block",
          }}
        ></iframe>
        <Box>
          <Button onClick={() => handleAcceptEULA(true)} startIcon={<Check />}>
            I accept
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Eula;
