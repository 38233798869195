import React, { useEffect, useState } from "react";

import { IScreenProps } from "../../../types/app.types";
import { Box, Tabs, Tab, Paper, Grid, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import VideoBox from "../../../components/VideoBox/VideoBox";
import "../facilitator/mainScreen.css";
import * as CSS from "csstype";
import "./Videos.css";
import IframeResizer from "iframe-resizer-react";
import clsx from "clsx";
import ReportRoundSelector from "../../../components/reportRoundSelector/ReportRoundSelector";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  iframeWrapper: {
    height: "80vh",
  },
  closed: {
    width: "92vw",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  opened: {
    width: "79vw",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));
const EtherpadsOPS: React.FC<IScreenProps> = ({ gameState, text, sidebarExpanded }) => {
  document.title = `OPS | ${text.wrapper.gameName}`;

  const [value, setValue] = useState(gameState.Round);

  const classes = useStyles();

  const opened = clsx(classes.iframeWrapper, classes.opened);
  const closed = clsx(classes.iframeWrapper, classes.closed);

  return (
    <div>
      <Grid container className={classes.cont}>
        <Grid item xs={12} className={classes.row}>
          <ReportRoundSelector onChange={setValue} gameState={gameState} initial={value} sheet />
        </Grid>
        <Grid item xs={12}>
          <div className={sidebarExpanded ? opened : closed}>
            <IframeResizer
              log
              src={process.env.REACT_APP_ENV_ETHERPAD + "/sheet/OPS-" + localStorage.getItem("guid") + "." + value}
              style={{
                width: "1px",
                minWidth: "100%",
                height: "1px",
                minHeight: "100%",
              }}
              title="Operations Planning System"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EtherpadsOPS;
