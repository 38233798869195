import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { getConfluencePage } from "../../modules/gameState/service";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { Link } from "react-router-dom";

interface ConfluencePageProps {
  pageId: string;
}

const useStyles = makeStyles((theme) => ({
  padding: {
    maxWidth: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
  cheating: {
    paddingTop: "1px",
    padding: "10px 40px",
    height: "100%",
    maxWidth: "86vw",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonGrid: {
    borderRight: "1px gray solid",
  },
}));

const transform = (node: any, index: number) => {
  if (node.type === "tag" && node.name === "a") {
    const href = node.attribs.href;
    if (href.startsWith("/game/wiki/")) {
      return (
        <Link to={href} key={index}>
          {node.children[0].data}
        </Link>
      );
    }
  }
};

const ConfluencePage: React.FC<ConfluencePageProps> = ({ pageId }) => {
  const [content, setContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const classes = useStyles();

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response: { data: { body: { view: { value: string } } } } = await getConfluencePage(pageId);
        const pageContent = response.data.body.view.value;
        setContent(pageContent);
        const styleLinks = [
          "https://gingrtech.atlassian.net/wiki/s/d41d8cd98f00b204e9800998ecf8427e-CDN/en_GB/7200/10d9c2f3ecb65c9f7b1fd4f9abf0a98f/_/download/batch/com.atlassian.confluence.plugins.confluence-ui-resources:default/batch.css",
          "https://gingrtech.atlassian.net/wiki/s/d41d8cd98f00b204e9800998ecf8427e-CDN/en_GB/7200/10d9c2f3ecb65c9f7b1fd4f9abf0a98f/_/download/batch/com.atlassian.confluence.plugins.confluence-ui-resources:default/batch.js",
        ];

        styleLinks.forEach((link) => {
          const linkElement = document.createElement("link");
          linkElement.rel = "stylesheet";
          linkElement.href = link;
          document.head.appendChild(linkElement);
        });
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPageContent();
  }, [pageId]);

  const parsedHtml = ReactHtmlParser(content, { transform });

  return <div className={classes.padding}>{parsedHtml}</div>;
};

export default ConfluencePage;
