import React, { memo } from "react";
import { IGameState } from "../../types/gameState.types";
import { ITextSet } from "../../types/text.types";
import { Grid, Paper, Typography, GridSize } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AccessibleTooltip from "../accessibleTooltip/AccessibleTooltip";
import { SentimentVeryDissatisfied, SentimentDissatisfied, SentimentVerySatisfied } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: theme.spacing(0.4),
    borderBottom: "none",
    paddingLeft: theme.spacing(1),
  },
  numberCell: {
    paddingRight: theme.spacing(1),
    textAlign: "right",
  },
  head: {
    padding: theme.spacing(0.1),
    paddingLeft: theme.spacing(1),
    textAlign: "center",
  },
  tableBody: {
    overflowY: "auto",
    // backgroundColor: theme.palette.secondary.light
  },
  table: {
    height: "80vh",
    //overflowY: 'auto',
    padding: theme.spacing(3),
  },
  padding: {
    paddingLeft: theme.spacing(1),
  },
  section: {
    backgroundColor: "white",
    height: "3vh",
  },
  gap: {
    padding: theme.spacing(1),
  },
  subheader: {
    minWidth: "10%",
  },
  paper: {
    // margin: "10px",
    padding: "10px",
    backgroundColor: "white",
    // display: 'flex',
    // textAlign: 'center',
  },
  co2: {
    // height: '10vh',
    // width: '10vh',
    // display: 'flex',
    // flex: 1,
  },
}));

const getReduction = (value: number, oldValue: number) => {
  return -1 * Math.round(((value - oldValue) * 100) / oldValue);
};

interface Props {
  text?: ITextSet;
  itemSize: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  legend?: boolean;
  gameState: IGameState;
}

const renderIcons = (count: number, type: string) => {
  let IconComponent: OverridableComponent<SvgIconTypeMap<unknown, "svg">> | undefined;
  let color: string | undefined;

  switch (type) {
    case "detractor":
      IconComponent = SentimentVeryDissatisfied;
      color = "red";
      break;
    case "passive":
      IconComponent = SentimentDissatisfied;
      color = "amber";
      break;
    case "promoter":
      IconComponent = SentimentVerySatisfied;
      color = "green";
      break;
    default:
      return null;
  }

  return Array(count)
    .fill(0)
    .map((_, i) => (IconComponent ? <IconComponent key={i} style={{ color }} /> : null));
};

const calculateNPS = (promoters: number, passives: number, detractors: number) => {
  const total = promoters + passives + detractors;
  const nps = ((promoters - detractors) / total) * 100;
  return nps.toFixed(0);
};

const classifyNPS = (value: number): string => {
  if (value < 0) {
    return "detractor";
  } else if (value === 0) {
    return "passive";
  } else {
    return "promoter";
  }
};

const NpsStats: React.FC<Props> = (props) => {
  const { text, itemSize, gameState, legend } = props;
  const classes = useStyles();
  const legendSize = isNaN(Number(itemSize))
    ? 12
    : Number(itemSize) == 3
    ? 12
    : Number(itemSize) >= 12
    ? 12
    : Number(itemSize) * 2;

  type TupleType = { Item1: number; Item2: number; Item3: number };
  const defaultTuple: TupleType = { Item1: 0, Item2: 1, Item3: 0 };

  const currentCustomerNPS =
    gameState.Round > 0 && gameState.Week > 0
      ? gameState.CustomerNPSReportValues[gameState.Round - 1][gameState.Week]
      : defaultTuple;
  const currentEmployeeNPS =
    gameState.Round > 0 && gameState.Week > 0
      ? gameState.EmployeeNPSReportValues[gameState.Round - 1][gameState.Week]
      : defaultTuple;
  const previousCustomerNPS =
    gameState.Round > 0 && gameState.Week > 1
      ? gameState.CustomerNPSReportValues[gameState.Round - 1][gameState.Week - 1]
      : defaultTuple;
  const previousEmployeeNPS =
    gameState.Round > 0 && gameState.Week > 1
      ? gameState.EmployeeNPSReportValues[gameState.Round - 1][gameState.Week - 1]
      : defaultTuple;

  const currentCustomerNPSScore = calculateNPS(
    currentCustomerNPS.Item3,
    currentCustomerNPS.Item2,
    currentCustomerNPS.Item1
  );
  const currentEmployeeNPSScore = calculateNPS(
    currentEmployeeNPS.Item3,
    currentEmployeeNPS.Item2,
    currentEmployeeNPS.Item1
  );

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item md={itemSize} sm={6} xs={12}>
          <Typography component="span" variant="subtitle2">
            <AccessibleTooltip title="Customer Net Promoter Score">
              <span tabIndex={0}>Customer NPS</span>
            </AccessibleTooltip>
            <br />
          </Typography>
        </Grid>
        <Grid item md={itemSize} sm={6} xs={12} role="img" style={{ display: "flex", alignItems: "center" }}>
          {renderIcons(1, classifyNPS(Number(currentCustomerNPSScore)))}
          <span>{currentCustomerNPSScore}%</span>
        </Grid>
        <Grid item md={itemSize} sm={6} xs={12}>
          <Typography component="span" variant="subtitle2">
            <AccessibleTooltip title="Employee Net Promoter Score">
              <span tabIndex={0}>Employee NPS</span>
            </AccessibleTooltip>
            <br />
          </Typography>
        </Grid>
        <Grid item md={itemSize} sm={6} xs={12} role="img" style={{ display: "flex", alignItems: "center" }}>
          {renderIcons(1, classifyNPS(Number(currentEmployeeNPSScore)))}
          <span>{currentEmployeeNPSScore}%</span>
        </Grid>
        <Grid item xs={legendSize as GridSize}>
          <Typography component="p" variant="subtitle2"></Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default memo(NpsStats);
