import { Button, Grid, TextField, ThemeProvider, Theme, StyledEngineProvider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import StatBox from "../../../../components/statBox/StatBox";
import { setCommission, recordFormSubmission } from "../../../../modules/gameState/service";
import { IGameState } from "../../../../types/gameState.types";
import { IFacilitatorConsoleText } from "../../../../types/text.types";
import controlsTheme from "./controlsTheme";
import { IPlayerEvent } from "../../../../types/playerEvents.types";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  gameState: IGameState;
  text: IFacilitatorConsoleText;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginLeft: "0",
  },
}));

export const CommissionPanel: React.FC<Props> = ({ gameState, text }) => {
  const commissionSettings = [
    { key: "OnAuthorisation", value: text.onMDAuthRev },
    { key: "OnPaymentRevenue", value: text.onPaymentRev },
    { key: "OnPaymentProfit", value: text.onPaymentProfit },
  ];

  const classes = useStyles();

  const [commissionSettingToActivate, setCommissionSettingToActivate] = useState("");
  const [commissionValueToActivate, setCommissionValueToActivate] = useState("");
  const [commissionFeedback, setCommissionFeedback] = useState(<></>);

  const applyCommissionChanges = async () => {
    let message;
    const newDate: Date = new Date();
    try {
      const value = commissionValueToActivate;
      const setting = commissionSettingToActivate;
      const commissionText = (commissionSettings.find((opt) => opt.key === setting) || commissionSettings[0]).value;
      message = `${text.paymentSetting}: ${commissionText}. ${text.commissionWillBePaidAt} ${value}%.`;

      const form: IPlayerEvent = {
        id: localStorage.getItem("guid") + newDate.toString(),
        GameId: localStorage.getItem("guid"),
        Console: "Facilitator Controls",
        Round: gameState.Round.toString(),
        Week: gameState.Week.toString(),
        Submission: message,
        DateTime: newDate,
        Feedback: "",
      };

      await setCommission(value, setting);
      setCommissionSettingToActivate("");
      setCommissionValueToActivate("");
      form.Feedback = message;
      recordFormSubmission(form);
      setCommissionFeedback(<Typography style={{ color: "#3CBD89" }}>{message}</Typography>);
    } catch {
      message = "Error applying commission changes";

      const form: IPlayerEvent = {
        id: localStorage.getItem("guid") + newDate.toString(),
        GameId: localStorage.getItem("guid"),
        Console: "Facilitator Controls",
        Round: gameState.Round.toString(),
        Week: gameState.Week.toString(),
        Submission: message,
        DateTime: newDate,
        Feedback: message,
      };
      setCommissionFeedback(<Typography color="error">{message}</Typography>);
    }
  };

  const cancelCommissionChanges = () => {
    setCommissionValueToActivate("");
    setCommissionSettingToActivate("");
    setCommissionFeedback(<Typography style={{ color: "#3CBD89" }}>Commission cleared</Typography>);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <TextField
          id="commissionInput"
          type="number"
          value={commissionValueToActivate}
          onChange={(e) => setCommissionValueToActivate(e.target.value)}
          label={text.commissionAmount}
          aria-label="Commission Amount"
          name="Commission"
          style={{ width: "80%" }}
        />
      </Grid>
      <Grid item xs={6}>
        <StatBox title={text.commissionPaidAt} value={gameState.CommissionPct + "%"} />
      </Grid>
      <Grid item xs={12}>
        {commissionSettings.map((setting) => (
          <Button
            key={setting.key}
            variant={commissionSettingToActivate === setting.key ? "contained" : "outlined"}
            onClick={() => setCommissionSettingToActivate(setting.key)}
            className={classes.button}
            name={setting.value.replace(" ", "")}
          >
            <Typography component="p" variant="subtitle2">
              {setting.value}
            </Typography>
          </Button>
        ))}
      </Grid>
      <Grid item xs={6}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={controlsTheme}>
            <Button
              variant="contained"
              color="primary"
              onClick={applyCommissionChanges}
              className={classes.button}
              disabled={!commissionSettingToActivate || !commissionValueToActivate}
              name={text.apply}
            >
              <Typography component="p" variant="subtitle2">
                {text.apply}
              </Typography>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={cancelCommissionChanges}
              className={classes.button}
              name={text.cancel}
            >
              <Typography component="p" variant="subtitle2">
                {text.cancel}
              </Typography>
            </Button>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
      <Grid item xs={12} aria-live="polite">
        {commissionFeedback}
      </Grid>
    </Grid>
  );
};
