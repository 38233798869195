import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Card, Paper, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccessibleTooltip from "../accessibleTooltip/AccessibleTooltip";

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: "1px solid #eee",
    border: "0",
    margin: "0 15px 0px",
    padding: "0",
    alignItems: "center",
    borderRadius: "0",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  icon: {
    float: "left",
    padding: "3px",
    margin: "-4px 0px -20px 0px",
    borderRadius: "3px",
    backgroundColor: "#999",
    background: `linear-gradient(60deg, #66bb6a, #43a047)`,
    boxShadow: "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
  },
  inlineIcon: {
    float: "left",
    padding: "15px",
    margin: "-20px 15px 0px 5px",
  },
  main: {
    padding: theme.spacing(1),
  },
  container: {
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  left: {
    float: "left",
  },
  minimalValue: {
    paddingRight: "5px",
  },
  uppercase: {
    textTransform: "uppercase",
  },
}));

const getChangeColor = (value1: string, value2: string): "green" | "grey" | "red" => {
  const number1 = Number(value1.replace(/[^\d.-]/g, ""));
  const number2 = Number(value2.replace(/[^\d.-]/g, ""));

  if (isNaN(number1) || isNaN(number2)) {
    return "grey";
  }

  if (number1 < number2) {
    return "red";
  } else if (number1 > number2) {
    return "green";
  } else {
    return "grey";
  }
};

const getChange = (value1: string, value2: string): string => {
  const number1 = Number(value1.replace(/[^\d.-]/g, ""));
  const number2 = Number(value2.replace(/[^\d.-]/g, ""));
  if (isNaN(number1) || isNaN(number2)) {
    return "";
  }

  const sign = number1 < number2 ? "-" : "+";
  const change = 100 * ((number2 - number1) / number1);

  return `${sign}${change.toFixed(0)}% `;
};

interface Props {
  value: string | number;
  subValue?: string;
  title: string;
  color?: "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error" | undefined;
  colorCust?: string;
  icon?: "inline" | "raised" | undefined;
  variant?: "normal" | "minimal" | undefined;
  backgroundColor?: string;
  previousValue?: string | number;
  tooltip?: string;
  children?: React.ReactNode;
}

const StatBox: React.FC<Props> = (props) => {
  const { value, subValue, title, color, children, icon, variant, backgroundColor, colorCust, previousValue, tooltip } =
    props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <AccessibleTooltip title={tooltip ? tooltip : title}>
        {variant === "minimal" ? (
          <div>
            {color ? (
              <React.Fragment>
                <Typography component="span" variant="subtitle1" color={color} className={classes.minimalValue}>
                  {value} <span>{subValue}</span>
                </Typography>
                <Typography component="span" variant="subtitle2" className={classes.uppercase}>
                  {title}
                </Typography>
                {previousValue ? (
                  <Typography color={color} component="p" variant="subtitle2">
                    {previousValue}
                  </Typography>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{ color: colorCust }}
                  className={classes.minimalValue}
                >
                  {value} <span>{subValue}</span>
                </Typography>
                <Typography
                  component="span"
                  variant="subtitle2"
                  className={classes.uppercase}
                  style={{ color: colorCust }}
                >
                  {title}
                </Typography>
                {previousValue ? (
                  <Typography style={{ color: colorCust }} component="p" variant="subtitle2">
                    {previousValue}
                  </Typography>
                ) : null}
              </React.Fragment>
            )}
          </div>
        ) : (
          <Paper className={classes.container} style={{ backgroundColor: backgroundColor }}>
            <div className={classes.header}>
              {color ? (
                <Typography component="span" variant="subtitle2" color={color} className={classes.uppercase}>
                  {previousValue ? (
                    <span
                      style={{
                        color: getChangeColor(value.toString(), previousValue.toString()),
                      }}
                      className={classes.left}
                    >
                      {getChange(value.toString(), previousValue.toString())}
                    </span>
                  ) : undefined}
                  {title}
                </Typography>
              ) : (
                <Typography
                  component="span"
                  variant="subtitle2"
                  style={{ color: colorCust }}
                  className={classes.uppercase}
                >
                  {previousValue ? (
                    <span
                      style={{
                        color: getChangeColor(value.toString(), previousValue.toString()),
                      }}
                      className={classes.left}
                    >
                      {getChange(value.toString(), previousValue.toString())}
                    </span>
                  ) : undefined}
                  {title}
                </Typography>
              )}
            </div>
            <div className={classes.main}>
              {children && icon === undefined ? <div className={classes.icon}> {children}</div> : null}
              {children && icon === "inline" ? <div className={classes.inlineIcon}> {children}</div> : null}
              {color ? (
                <Typography component="span" variant="subtitle1" color={color}>
                  {value}
                  <span>{subValue}</span>
                </Typography>
              ) : (
                <Typography component="span" variant="subtitle1" style={{ color: colorCust }}>
                  {value}
                  <span>{subValue}</span>
                </Typography>
              )}
            </div>
          </Paper>
        )}
      </AccessibleTooltip>
    </React.Fragment>
  );
};

export default StatBox;
