import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { IGameState, IOrderDataViewModel } from "../../../../../types/gameState.types";
import { Button, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import controlsTheme from "../../../facilitator/controlPanels/controlsTheme";
import { recordFormSubmission } from "../../../../../modules/gameState/service";
import { IPlayerEvent } from "../../../../../types/playerEvents.types";
import { Feedback } from "react-bootstrap/lib/FormControl";
import { ITextSet } from "../../../../../types/text.types";
import appStyles from "../../../../../assets/appStyles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface SubmitHandler {
  (orderNumber: string): void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 40px",
  },
  cheating: {
    paddingTop: "1px",
    height: "100%",
  },
  panelContainer: {
    margin: "auto",
    width: "55vh",
  },
  field: {
    marginLeft: theme.spacing(1),
    width: "80%",
  },
  form: {
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface Props {
  orderList: IOrderDataViewModel[];
  progressOrderFunction: SubmitHandler;
  gameState: IGameState;
  console: string;
  text: ITextSet;
}

const CustomerAcceptance: React.FC<Props> = ({ orderList, progressOrderFunction, gameState, console, text }) => {
  const [show, setShow] = useState(false);
  const [feedback, setFeedback] = useState(<></>);
  const [orderNumberSuffix, setOrderNumberSuffix] = useState("");
  const [service, setService] = useState("");
  const [CAC, setCAC] = useState("");

  const updateOrderNumber = (event: any) => {
    setOrderNumberSuffix(event.target.value.replace(/\D/, ""));
  };

  const updateService = (event: any) => {
    if (!event.target.value) {
      return setService("");
    }
    setService(event.target.value[0].toUpperCase());
  };

  const updateCAC = (event: any) => {
    setCAC(event.target.value.replace(/\D/, ""));
  };

  const handleSubmit = async () => {
    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
      setFeedback(<Typography color="error">Cannot apply CAC Code - game has gone bust</Typography>);
      return false;
    }
    if (gameState.RunningState == "Finished Round" && gameState.Week >= 26) {
      setFeedback(<Typography color="error">Cannot apply CAC code - level has finished</Typography>);
      return false;
    }
    if (gameState.RunningState != "Running") {
      setFeedback(<Typography color="error">Cannot apply CAC code - Game not running</Typography>);
      return false;
    }
    if (!orderNumberSuffix || !service || !CAC) {
      setFeedback(<Typography color="error">All fields are required</Typography>);
      return false;
    }

    setFeedback(<></>);

    const orderNumber = `GOA00${orderNumberSuffix}`;
    const order = orderList.find((order) => order.OrderNumber === orderNumber);

    const newDate = new Date();

    const form: IPlayerEvent = {
      id: localStorage.getItem("guid") + newDate.toString(),
      GameId: localStorage.getItem("guid"),
      Console: "Delivery",
      Round: gameState.Round.toString(),
      Week: gameState.Week.toString(),
      Submission: orderNumber,
      DateTime: newDate,
      Feedback: "",
    };

    if (!order || order.OrderStatus !== "Authorised") {
      form.Feedback = "Invalid order number.";
      recordFormSubmission(form);
      provideFeedback("Invalid order number.", true);
      return;
    }

    if (order.ProductName !== service || order.CAC !== parseInt(CAC)) {
      const submissionProductName =
        order.ProductName === service ? service : `${service} (expected ${order.ProductName})`;
      const submissionCAC = order.CAC === parseInt(CAC) ? CAC : `${CAC} (expected ${order.CAC})`;
      form.Submission = `Order Number: ${orderNumber}, Service: ${submissionProductName}, CAC: ${submissionCAC}`;
      form.Feedback = "Provided order details are incorrect.";
      recordFormSubmission(form);
      provideFeedback("Provided order details are incorrect.", true);
      return;
    }

    try {
      await progressOrderFunction(orderNumber);
      form.Feedback = "Success!";
      recordFormSubmission(form);
      provideFeedback("Success!", false);
      setOrderNumberSuffix("");
      setService("");
      setCAC("");
    } catch {
      form.Feedback = "Error progressing order.";
      recordFormSubmission(form);
      provideFeedback("Error progressing order.", true);
    }

    function provideFeedback(message: string, error: boolean) {
      error
        ? setFeedback(<Typography color="error">{message}</Typography>)
        : setFeedback(<Typography style={{ color: "#3CBD89" }}>{message}</Typography>);
      setTimeout(() => setFeedback(<></>), 10000);
    }
  };

  const clearFields = (event: any) => {
    setOrderNumberSuffix("");
    setService("");
    setCAC("");
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  const classes = useStyles();
  const appClasses = appStyles();

  return (
    <Paper className={classes.cheating}>
      <div className={appClasses.panelHeader}>
        <h2 className={appClasses.panelTitle}>Validate Customer Acceptance Code (CAC)</h2>
      </div>
      <div className={classes.container}>
        <form className={classes.form} noValidate autoComplete="off">
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">GOA00</InputAdornment>,
                }}
                value={orderNumberSuffix}
                id="mdAuthInput"
                onChange={updateOrderNumber}
                autoFocus
                label="Order Number"
                className={classes.field}
                onKeyDown={handleEnterPress}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                value={service}
                id="ceoServiceInput"
                onChange={updateService}
                label={text.productionConsole.product}
                className={classes.field}
                onKeyDown={handleEnterPress}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                value={CAC}
                id="ceoCacInput"
                onChange={updateCAC}
                label="CAC"
                className={classes.field}
                onKeyDown={handleEnterPress}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={controlsTheme}>
                  <Button
                    id="cacCodeBtn"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    // disabled={!orderNumberSuffix || !service || !CAC || (gameState.RunningState == "Ready")}
                    className={classes.button}
                  >
                    {" "}
                    <Typography variant="subtitle2" component="p">
                      Submit
                    </Typography>
                  </Button>
                  <Button id="cacCodeClearBtn" variant="contained" color="secondary" onClick={clearFields}>
                    <Typography variant="subtitle2" component="p">
                      Clear
                    </Typography>
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
            </Grid>
            <Grid item xs={12} aria-live="polite">
              {feedback}
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
};

export default CustomerAcceptance;
