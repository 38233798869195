import { authAxios as API } from "./api";

API.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_GAME_STATE_API_KEY ?? "";

export const checkGUID = async (guid: string) => {
  const response = await API.get(`/CheckGUID`, {
    params: { guid },
  });
  return response.data;
};

export const challengeStart = async (guid: string | null) => {
  const response = await API.post("services/CommenceRocketChallenge?guid=" + guid);

  return response.data;
};
