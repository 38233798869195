import React, { useEffect, useState } from "react";

import ReportRoundSelector from "../../../components/reportRoundSelector/ReportRoundSelector";

import { IScreenProps } from "../../../types/app.types";
import LeadToCashChart from "../../../components/leadToCashChart/LeadToCashChart";
import { Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Co2ReportTable from "../../../components/co2ReportTable/Co2ReportTable";
import ScopeBalanceChart from "../../../components/bankBalanceChart/ScopeBalanceChart";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

const LeadToCashReport: React.FC<IScreenProps> = ({ gameState, text }) => {
  const [roundSelection, setRoundSelection] = useState(gameState.Round - 1);
  const [viewModels, setViewModels] = useState(gameState.LeadToCashViewModels[0]);
  const [weekReached, setWeekReached] = useState(gameState.WeeksReachedInRounds[0]);
  const [lineVisible, setLineVisible] = useState(false);

  useEffect(() => {
    setViewModels(gameState.LeadToCashViewModels[roundSelection - 1]);
    setWeekReached(gameState.WeeksReachedInRounds[roundSelection - 1]);
  }, [gameState, roundSelection]);

  useEffect(() => {
    const selectedRoundIsBankrupt = () => {
      return (
        ["Finished Game", "Finished Round"].includes(gameState.RunningState) ||
        (roundSelection < gameState.Round && weekReached > 0 && weekReached < 26)
      );
    };
    setLineVisible(selectedRoundIsBankrupt());
  }, [viewModels, weekReached, roundSelection, gameState.Round, gameState.RunningState]);

  const classes = useStyles();

  return (
    <Grid container className={classes.cont}>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12} className={classes.margin}>
            <ReportRoundSelector onChange={setRoundSelection} gameState={gameState} initial={roundSelection} />
          </Grid>
          <Grid item xs={12} className={clsx(classes.margin, "tourProcessReport")}>
            <LeadToCashChart
              gameState={gameState}
              text={text}
              data={gameState.LeadToCashViewModels[roundSelection]}
              height={"70vh"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default LeadToCashReport;
