import { Grid, Paper, Theme, Button, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChartType } from "chart.js";
import React from "react";
import { Chart } from "react-chartjs-2";
import { IGameState } from "../../types/gameState.types";
import formatAsGBP from "../../utils/formatAsGBP";
import formatAsUSD from "../../utils/formatAsUSD";
import StatBox from "../statBox/StatBox";
import "./bankBalanceChart.css";

const chartOptions = {
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      suggestedMin: -50000,
      suggestedMax: 150000,
      ticks: {
        callback: (value: any, index: any, ticks: any) => {
          if (parseInt(value) >= 1000) {
            return formatAsUSD(value / 1000) + "k";
          } else if (parseInt(value) <= -1000) {
            return "-" + formatAsUSD(Math.abs(value / 1000)) + "k";
          } else {
            return formatAsUSD(value);
          }
        },
      },
    },
    y1: {
      type: "linear" as const,
      display: true,
      suggestedMin: 0,
      suggestedMax: 150,
      position: "right" as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  animation: {
    duration: 5,
  },
  responsive: true,
  maintainAspectRatio: false,
};

const generateChartData = (
  data: number[],
  dataCo2: number[],
  direct: number[],
  indirect: number[],
  labels: string[]
) => {
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: "rgba(144, 238, 144, 0.4)",
        borderColor: "rgba(144, 238, 144, 0.4)",
        lineTension: 0,
        spanGaps: false,
        fill: true,
        label: "Bank Balance",
        id: "y",
        pointStyle: "circle",
        pointRadius: 5,
      },
      {
        data: direct,
        backgroundColor: "rgba(255, 0, 0, 0.4)",
        borderColor: "rgba(255, 0, 0, 0.4)",
        lineTension: 0,
        spanGaps: false,
        label: "Sales (tCO2e)",
        fill: false,
        yAxisID: "y1",
        pointStyle: "star",
        pointRadius: 5,
        borderDash: [5, 5],
      },
      {
        data: indirect,
        backgroundColor: "rgba(0, 0, 255, 0.4)",
        borderColor: "rgba(0, 0, 255, 0.4)",
        lineTension: 0,
        spanGaps: false,
        label: "Operational (tCO2e)",
        fill: false,
        yAxisID: "y1",
        pointStyle: "rectRot",
        pointRadius: 5,
        borderDash: [5, 1],
      },
    ],
  };
};

interface IRequiredText {
  current: string;
  highest: string;
  lowest: string;
  bankBalance?: string;
}

interface Props {
  gameState: IGameState;
  text: IRequiredText;
  data?: number[];
  dataCO2?: number[];
  direct?: number[];
  indirect?: number[];
  mode?: "full" | "chart" | "values";
  height: number;
  currencyParser: Intl.NumberFormat;
}

const BankBalanceCO2Chart: React.FC<Props> = ({
  gameState,
  text,
  mode = "full",
  data,
  dataCO2,
  direct,
  indirect,
  height,
  currencyParser,
}) => {
  data = data || gameState.BankValueList;
  dataCO2 = dataCO2 || gameState.CO2ValueList;
  direct = direct || gameState.CO2DirectValueList;
  indirect = indirect || gameState.CO2IndirectValueList;

  const canvasHeight = height - 8 + "vh";

  const useStyles = makeStyles((theme: Theme) => ({
    grid: {
      marginBottom: theme.spacing(5),
    },
    chart: {
      // height: height+'vh',
      padding: theme.spacing(1),
    },
    canvas: {
      // height: canvasHeight
    },
    carbonRow: {
      marginTop: theme.spacing(1),
    },
    link: {
      color: "blue",
      textDecoration: "underline",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      {["full", "values"].includes(mode) && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <StatBox
                value={currencyParser.format(gameState.BankCurrent)}
                title={text.current}
                color="primary"
                icon="inline"
              ></StatBox>
            </Grid>
            <Grid item md={4} xs={6}>
              <StatBox value={currencyParser.format(gameState.BankMin)} title={text.lowest} icon="inline"></StatBox>
            </Grid>
            <Grid item md={4} xs={6}>
              <StatBox
                value={currencyParser.format(gameState.BankMax)}
                title={text.highest}
                color="primary"
                icon="inline"
              ></StatBox>
            </Grid>
          </Grid>
        </Grid>
      )}
      {["full", "chart"].includes(mode) && (
        <Grid item xs={12}>
          <Paper className={classes.chart}>
            <Typography variant="subtitle2" component={"p"}>
              {" "}
              This chart displays the bank balance and scope C02 emissions for each week in the current round.{" "}
              <Link href="/game/bankco2table" className={classes.link} underline="hover">
                View As Table
              </Link>
            </Typography>
            <div className={classes.canvas}>
              <Chart
                type={"line" as ChartType}
                data={generateChartData(data, dataCO2, direct, indirect, gameState.BankLabelList)}
                options={chartOptions}
                role="img"
                aria-label="Chart of bank balance and tC02 Scope 1-3 values for the current round."
              />
            </div>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12} className={classes.carbonRow}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <StatBox
              value={
                gameState.Round
                  ? gameState.Co2Reports[gameState.Round].TotalCo2ScopeTotalEmissions + " tCO2e"
                  : "WALKAROUND"
              }
              title={"Total Emissions"}
              tooltip={"Total Emissions for Level " + gameState.Round}
            ></StatBox>
          </Grid>
          <Grid item xs={4}>
            <StatBox
              value={
                gameState.Round != 0
                  ? gameState.Co2Reports[gameState.Round].TotalSalesTotalEmissions + " tCO2e"
                  : "WALKAROUND"
              }
              title={"Sales Emissions"}
              tooltip={"Sales Emissions for Level " + gameState.Round}
            ></StatBox>
          </Grid>
          <Grid item xs={4}>
            <StatBox
              value={
                gameState.Round != 0
                  ? gameState.Co2Reports[gameState.Round].TotalOpsTotalEmissions + " tCO2e"
                  : "WALKAROUND"
              }
              title={"Ops Emissions"}
              tooltip={"Operational Emissions for Level " + gameState.Round}
            ></StatBox>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BankBalanceCO2Chart;
