export enum RoleIDs {
  Facilitator = "Fac",
  MD = "MD",
  Marketing = "Mark",
  Sales = "Sales",
  Finance = "Fin",
  Production = "Prod",
}

export class RouteWrapper {
  path: string;
  roleID: RoleIDs;

  constructor(path: string, roleID?: RoleIDs) {
    this.path = path;
    this.roleID = roleID || RoleIDs.Facilitator;
  }

  // TODO extend this to handle path parameters (we don't use any of those yet)
  toUrl(queryParams = {}) {
    let toReturn = this.path;
    Object.entries(queryParams).forEach(([key, value], index) => {
      const separator = index === 0 ? "?" : "&";
      toReturn += `${separator}${key}=${value}`;
    });
    return toReturn;
  }

  getRoleID() {
    return this.roleID;
  }
}
