import { useEffect, useState } from "react";
import { getTimeSinceChallengeStart } from "../modules/gameState/service";

function useChallengeTime(delay = 1000): string {
  const [time, setTime] = useState("");

  // useEffect(() => {
  //     let isMounted = true
  //     const tick = async () => {
  //         const newState = await getTimeSinceChallengeStart()
  //         if (newState && isMounted) {
  //             setTime(newState.data)
  //         }
  //     }

  //     let id = setInterval(tick, delay)
  //     return () => {
  //         clearInterval(id)
  //         isMounted = false
  //     }

  // }, [delay])

  return time;
}

export default useChallengeTime;
