import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import clsx from "clsx";
import FinanceTheme from "./financeTheme";
import RaiseInvoice from "./components/RaiseInvoice";
import {
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Link,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { AccountBalance, TrendingDown, TrendingFlat, TrendingUp } from "@mui/icons-material";
import { advanceOrderState } from "../../../modules/gameState/service";
import { IScreenProps } from "../../../types/app.types";
import { IFinanceOrderViewModel } from "../../../types/gameState.types";
import StatBox from "../../../components/statBox/StatBox";
import Co2Stats from "../../../components/co2Stats/Co2Stats";
import NpsStats from "../../../components/npsStats/NpsStats";
import appStyles from "../../../assets/appStyles";
import { AppContext } from "../../../context";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// import './financeConsole.css'

const useStyles = makeStyles((theme) => ({
  padding: {
    maxWidth: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  table: {
    background: "#fafafa",
  },
  tableCont: {
    // padding: theme.spacing(3),
    maxHeight: "100%",
  },
  background: {
    //backgroundColor: theme.palette.secondary.main
  },
  cheating: {
    paddingTop: "1px",
    marginTop: "20px",
  },
  wrapper: {
    // overflowY: 'auto',
    // height: '40vh',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const FinanceConsole: React.FC<IScreenProps> = ({ gameState, text, currencyParser }) => {
  document.title = `Finance | ${text.wrapper.gameName}`;

  const orderStateTextMap: any = {
    "Awaiting Payment": text.financeConsole.awaitingPayment,
    Paid: text.financeConsole.completed,
  };
  const classes = useStyles();
  const appClasses = appStyles();
  const dataset = localStorage.getItem("dataset");
  const { state, dispatch } = useContext(AppContext);

  return (
    <div className={classes.padding}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <RaiseInvoice
                orderList={gameState.VisibleOrderList}
                progressOrderFunction={advanceOrderState}
                gameState={gameState}
                console="Finance"
                text={text}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <StatBox value={gameState.InvoicesPaid} title={text.financeConsole.invoicesPaid}>
                    <AccountBalance />
                  </StatBox>
                </Grid>
                <Grid item xs={6}>
                  <StatBox
                    value={currencyParser.format(gameState.BankCurrent)}
                    title={text.financeConsole.current}
                    color="primary"
                  >
                    <TrendingFlat />
                  </StatBox>
                </Grid>
                <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
                  {dataset?.includes("gogreen") ? (
                    <Co2Stats
                      text={text}
                      itemSize={3}
                      currIndirectPerWeek={gameState.CO2IndirectPerWeek}
                      currDirectPerWeek={gameState.CO2DirectPerUnitPerWeek}
                      standardDirectPerUnit={gameState.Co2Reports[0].Co2SalesCostPerUnit}
                      standardOpsCostPerWeek={gameState.Co2Reports[0].Co2OperationalCostPerWeek}
                    />
                  ) : null}
                  {dataset?.includes("culture") ? <NpsStats text={text} itemSize={3} gameState={gameState} /> : null}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
              <Paper className={classes.cheating}>
                <div className={appClasses.panelHeader}>
                  <h2 className={appClasses.panelTitle}>{text.financeConsole.invoiceStatus}</h2>
                </div>
                <div className={classes.wrapper}>
                  <TableContainer className={classes.tableCont}>
                    <Table className={classes.table} aria-label="marketing table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>{text.financeConsole.invoiceStatus}</TableCell>
                          <TableCell>{text.financeConsole.product.toUpperCase()}</TableCell>
                          <TableCell>{text.financeConsole.unit}</TableCell>
                          <TableCell>{text.financeConsole.orderValue}</TableCell>
                          <TableCell>{text.financeConsole.paymentTerms}</TableCell>
                          <TableCell>{text.financeConsole.status}</TableCell>
                          <TableCell>{text.financeConsole.paymentDueIn}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gameState.FinanceOrderList.map((order) => (
                          <TableRow key={order.OrderNumber}>
                            <TableCell component="th" scope="row">
                              <div className="orderDetails">
                                <strong>
                                  {text.financeConsole.orderNumber} - {order.OrderNumber}
                                </strong>
                                <p>
                                  {order.ContactForename} {order.ContactSurname} at {order.CompanyName}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <strong>{order.ProductName}</strong>
                            </TableCell>
                            <TableCell>
                              <strong>{order.NumberOfUnits}</strong>
                            </TableCell>
                            <TableCell>
                              <strong>{currencyParser.format(order.TotalCost)}</strong>
                            </TableCell>
                            <TableCell>
                              <strong>{order.PaymentTermsInWeeks} Weeks</strong>
                            </TableCell>
                            <TableCell>
                              <strong>{orderStateTextMap[order.OrderStatus]}</strong>
                            </TableCell>
                            {order.OrderStatus === "Awaiting Payment" ? (
                              <TableCell>
                                <strong>{order.PaymentDueInWeeks} Weeks</strong>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <strong>N/A</strong>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FinanceConsole;
