import React from "react";
import { Route, Navigate, RouteProps, useLocation, Outlet, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const PrivateRoute: any = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const guid = searchParams.get("guid");
  const dataset = searchParams.get("dataset");
  const authFac = searchParams.get("authFac");

  if (!localStorage.getItem("rocketSessionId")) {
    localStorage.setItem("rocketSessionId", uuidv4());
  }

  if (guid && dataset) {
    localStorage.setItem("guid", guid);
    localStorage.setItem("dataset", dataset);
    localStorage.setItem("authFac", authFac ? authFac : "");

    window.history.replaceState(null, "", window.location.pathname);
  }

  if (!localStorage.getItem("guid") || !localStorage.getItem("dataset")) {
    return <Navigate to={"/"} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
