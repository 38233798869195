import { useContext, useEffect, useState } from "react";
import { getDynamicText } from "../modules/referenceData/service";
import { ITextSet } from "../types/text.types";
import { AppContext } from "../context";

function useDynamicText(): ITextSet | null {
  const [text, setText] = useState(null);
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    async function retrieve() {
      const text = await getDynamicText();
      setText(text);
      dispatch({ type: "SET_TEXT", payload: text });
    }

    retrieve();
  }, []);

  return text;
}

export default useDynamicText;
