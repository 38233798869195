import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  total: number[];
  scope1: number[];
  scope2: number[];
  scope3: number[];
  labels: string[];
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "45vh",
  },
}));

const ScopeEmissionsForRoundTable: React.FC<Props> = ({ total, scope1, scope2, scope3, labels }) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableCell>Week</TableCell>
          <TableCell>Scope 1 (tCO2e)</TableCell>
          <TableCell>Scope 2 (tCO2e)</TableCell>
          <TableCell>Scope 3 (tCO2e)</TableCell>
          <TableCell>Total (tCO2e)</TableCell>
        </TableHead>
        <TableBody>
          {scope1.map((s1, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{labels[index]}</TableCell>
                <TableCell>{s1}</TableCell>
                <TableCell>{scope2[index]}</TableCell>
                <TableCell>{scope3[index]}</TableCell>
                <TableCell>{total[index]}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScopeEmissionsForRoundTable;
