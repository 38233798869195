import * as CSS from "csstype";
import IframeResizer from "iframe-resizer-react";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import VideoBox from "../../../components/VideoBox/VideoBox";
import { IScreenProps } from "../../../types/app.types";
import "../facilitator/mainScreen.css";
import "./Videos.css";

const Videos: React.FC<IScreenProps> = ({ gameState, text }) => {
  const verticalBoxStyle: CSS.Properties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const [showPracVideo, setShowPracVideo] = useState(false);
  const [showAPassVideo, setShowAPassVideo] = useState(false);
  const [showAFailVideo, setShowAFailVideo] = useState(false);
  const [showBPassVideo, setShowBPassVideo] = useState(false);
  const [showBFailVideo, setShowBFailVideo] = useState(false);
  const [key, setKey] = useState("VideoOne");
  const otslvl1 = "https://etherpadtest2.azurewebsites.net/sheet/OTS-" + localStorage.getItem("guid") + ".1";
  const otslvl2 = "https://etherpadtest2.azurewebsites.net/sheet/OTS-" + localStorage.getItem("guid") + ".2";
  const otslvl3 = "https://etherpadtest2.azurewebsites.net/sheet/OTS-" + localStorage.getItem("guid") + ".3";

  useEffect(() => {
    if (gameState.Round == 1 && (gameState.Week == 26 || gameState.BankCurrentNumeric < -60000)) {
      setShowPracVideo(true);
    }
    if (gameState.Round == 2 && gameState.Week == 26 && gameState.BankCurrentNumeric > -60000) {
      setShowAPassVideo(true);
    }
    if (gameState.Round == 2 && gameState.BankCurrentNumeric < -60000) {
      setShowAFailVideo(true);
    }
    if (gameState.Round == 3 && gameState.Week >= 26 && gameState.BankCurrentNumeric > -60000) {
      setShowBPassVideo(true);
    }
    if (gameState.Round == 3 && gameState.BankCurrentNumeric < -60000) {
      setShowBFailVideo(true);
    }
  }, [gameState.BankCurrentNumeric]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Tabs defaultActiveKey="Home" id="controlled-tab-example">
            <Tab eventKey="home" title="Video">
              <VideoBox
                videoLink="https://drive.google.com/file/d/1KZFC4JyKKklXfDUjMzvucdq7wL3cPGdA/preview"
                videoHeader="Introduction Video"
              />
            </Tab>
            <Tab eventKey="otslvl1" title="Level 1">
              <IframeResizer
                log
                src={otslvl1}
                style={{
                  width: "1px",
                  minWidth: "100%",
                  height: "1px",
                  minHeight: "100vh",
                }}
              />
            </Tab>
            <Tab eventKey="otslvl2" title="Level 2">
              <IframeResizer
                log
                src={otslvl2}
                style={{
                  width: "1px",
                  minWidth: "100%",
                  height: "1px",
                  minHeight: "100vh",
                }}
              />
            </Tab>
            <Tab eventKey="otslvl3" title="Level 3">
              <IframeResizer
                log
                src={otslvl3}
                style={{
                  width: "1px",
                  minWidth: "100%",
                  height: "1px",
                  minHeight: "100vh",
                }}
              />
            </Tab>
            <Tab eventKey="contact" title="Contact">
              <IframeResizer
                log
                src="https://docs.google.com/document/d/e/2PACX-1vQ_K2qindKc2IlTu-KRlFMg91i0kISTJgyOepjwP8S4pO0KS5ovNbNV_PFBGN3mabMtcDQtqD0oL3kU/pub?embedded=true"
                style={{
                  width: "1px",
                  minWidth: "100%",
                  height: "1px",
                  minHeight: "100vh",
                }}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
  {
    /*
            <div className="topVideoRow">
                    <VideoBox videoLink="https://drive.google.com/file/d/1KZFC4JyKKklXfDUjMzvucdq7wL3cPGdA/preview" videoHeader="Introduction Video" />
                    <VideoBox videoLink="https://drive.google.com/file/d/1J9st-00dKKsKjwlt8TRPzzwTgvfGl1pf/preview" videoHeader="Walkaround Video" />
                    { showPracVideo ? <div className="topVideo"><VideoBox videoLink="https://drive.google.com/file/d/1vxf-ZE5RcGiWa4YSZAIqsSLJFYIQjZwB/preview" videoHeader="End of Practice Round" /></div>: null}
                    { showAFailVideo  ? <div className="topVideo"><VideoBox videoLink="https://drive.google.com/file/d/1cPqbDCgmkQIMq5CQ2q1NwYzehKXrxO2S/preview" videoHeader="You've Gone Bust Round A" /></div>: null}
                    { showAPassVideo  ? <div className="topVideo"><VideoBox videoLink="https://drive.google.com/file/d/1myT8NrRi9hjeCcRWqv-DL7HS7sQ2HJ-P/preview" videoHeader="You've made it through Round A" /></div>: null}
                    { showBFailVideo   ? <div className="topVideo"><VideoBox videoLink="https://drive.google.com/file/d/1Bm8IFNvVJYMKdRlSLuITqf8OTZDeIfyo/preview" videoHeader="You've gone Bust Round B" /></div>: null}
                    { showBPassVideo   ? <div className="topVideo"><VideoBox videoLink="https://drive.google.com/file/d/1U_2hIA7lYtWKlHKM1d_MlCtEFHwyKjTR/preview" videoHeader="You've made it through Round B" /></div>: null}
            </div>

            <Row>
                <div  className="consoleHeader">
                    <h1>Consoles</h1>
                </div>
                <div className="consoleVideo leftVid">
                    <VideoBox videoLink="https://drive.google.com/file/d/111lHtf72ut6W9czIJQWmeXv2QZQqWxKj/preview" videoHeader="MD Console"/>
                </div>
                <div className="consoleVideo">
                    <VideoBox videoLink="https://drive.google.com/file/d/1-Z4Z9s03COBKfmNy2eXFyrT1I1u5NYIl/preview"  videoHeader="Sales Console" />
                </div>
                <div className="consoleVideo">
                    <VideoBox videoLink="https://drive.google.com/file/d/1By--zkTfJ1earrrzWsX9siPKhupwDSBH/preview"  videoHeader="Marketing Console" />
                </div>
                <div className="consoleVideo">
                    <VideoBox videoLink="https://drive.google.com/file/d/1w-Od_-wgbzsf6wf9f3lfJAwrmcoMAUnh/preview" videoHeader="Finance Console" />
                </div>
                <div className="consoleVideo">
                    <VideoBox videoLink="https://drive.google.com/file/d/1qFA5UoY6OsYE7QJ_f4jhjAisIjS8gas-/preview" videoHeader="Delivery Console" />
                </div>
            </Row>
            */
  }
};

export default Videos;
