import axios from "axios";
import config from "../../config";

export const authAxios = (() => {
  const authAxios = axios.create({
    baseURL: config.locations.gameStateAPI,
  });
  authAxios.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_GAME_STATE_API_KEY ?? "";
  return authAxios;
})();
