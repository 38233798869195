import {
  Button,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { IScreenProps } from "../../../types/app.types";

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: theme.spacing(3),
  },
}));

const BankCO2TableViewer: React.FC<IScreenProps> = ({ gameState, text, sidebarExpanded, currencyParser }) => {
  document.title = `Bank CO2 Table | ${text.wrapper.gameName}`;

  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.cont}>
        <Grid item xs={12}>
          <Link color="primary" href="/game/main" underline="hover">
            Back to Summary Screen
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="subtitle1">
            This is the table for the current bank balance and CO2 values for each week played of the current round
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <TableContainer component={Paper}>
              <Table aria-label="Bank Balance and CO2 values for Current Round">
                <TableHead>
                  <TableRow>
                    <TableCell>Week</TableCell>
                    <TableCell>Scope 1 tCO2e</TableCell>
                    <TableCell>Scope 2 tCO2e</TableCell>
                    <TableCell>Scope 3 tCO2e</TableCell>
                    <TableCell>Bank Balance ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gameState.BankValueList.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {gameState.BankLabelList[index]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {gameState.CO2S1ValueList[index]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {gameState.CO2S2ValueList[index]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {gameState.CO2S3ValueList[index]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {currencyParser.format(row)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default BankCO2TableViewer;
