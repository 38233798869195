import React, { useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Card, Container, Divider, Grid, Paper, Typography, Link, CssBaseline } from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Done, QueuePlayNext, TrendingUp, Warning } from "@mui/icons-material";
import clsx from "clsx";

import OrderSelector from "../../../components/orderSelector/OrderSelector";
import BankBalanceChart from "../../../components/bankBalanceChart/BankBalanceChart";
import { authoriseOrder, recordFormSubmission } from "../../../modules/gameState/service";
import StatBox from "../../../components/statBox/StatBox";
import Co2Stats from "../../../components/co2Stats/Co2Stats";
import NpsStats from "../../../components/npsStats/NpsStats";

import { IScreenProps } from "../../../types/app.types";

//import './mdConsole.css'
import NewsReel from "../../../components/newsReel/NewsReel";
import InvestmentPanel from "../../../pages/consoles/facilitator/controlPanels/InvestmentPanel";
import MessagesModal from "../../../components/modals/MessagesModal";
import BankCO2BalanceChart from "../../../components/bankBalanceChart/bankCO2BalanceChart";

import { IPlayerEvent } from "../../../types/playerEvents.types";
import { AppContext } from "../../../context";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  padding: {
    maxWidth: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    //display: 'flex',
    overflow: "auto",
    maxHeight: "90%",
    //flexDirection: 'column',
    //marginBottom: '21px',
  },
  fixedHeight: {
    //height: 'auto',
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    textAlign: "center",
  },
  cheating: {
    width: "auto",
    margin: "0",
  },
  main: {
    // backgroundColor: theme.palette.secondary.main,
    height: "100%",
  },
  news: {
    height: "85vh",
    maxHeight: "85vh",
    overflow: "auto",
  },
  grid: {
    padding: theme.spacing(3),
  },
}));

const MDConsole: React.FC<IScreenProps> = ({ gameState, referenceData, text, currencyParser }) => {
  document.title = `CEO | ${text.wrapper.gameName}`;

  const [searchInput, setSearchInput] = useState("");
  const [authSuccessFeedback, setAuthSuccessFeedback] = useState(<></>);
  const [authErrorFeedback, setAuthErrorFeedback] = useState(<></>);
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const newsGrid = clsx(classes.news, classes.grid);
  const { state, dispatch } = useContext(AppContext);

  const updateOnNumericInput = (event: any) => {
    setSearchInput(event.target.value.replace(/\D/, ""));
  };

  const orderAuth = async () => {
    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
      setAuthErrorFeedback(<Typography color="error">Cannot authorise order - game has gone bust</Typography>);
      return false;
    }
    if (gameState.RunningState == "Finished Round" && gameState.Week >= 26) {
      setAuthErrorFeedback(<Typography color="error">Cannot authorise order - level has finished</Typography>);
      return false;
    }
    if (gameState.RunningState != "Running") {
      setAuthErrorFeedback(<Typography color="error">Cannot authorise order - Game not running</Typography>);
      return false;
    }
    let orderNumber = searchInput;
    setAuthErrorFeedback(<></>);

    const newDate: Date = new Date();
    const form: IPlayerEvent = {
      id: localStorage.getItem("guid") + newDate.toString(),
      GameId: localStorage.getItem("guid"),
      Console: "CEO",
      Round: gameState.Round.toString(),
      Week: gameState.Week.toString(),
      Submission: orderNumber,
      DateTime: newDate,
      Feedback: "",
    };

    orderNumber = "GOA00" + searchInput;

    if (gameState.PreviouslyAuthorisedOrderNumbers.includes(orderNumber)) {
      form.Feedback = `${text.mdConsole.order} ${orderNumber} ${text.mdConsole.hasBeenPreviouslyAuthorised}`;
      recordFormSubmission(form);
      setErrorFeedback(`${text.mdConsole.order} ${orderNumber} ${text.mdConsole.hasBeenPreviouslyAuthorised}`);
    } else if (gameState.AvailableOrderNumbers.includes(orderNumber)) {
      await authorise(orderNumber);
      form.Feedback = `${text.mdConsole.order} ${orderNumber} ${text.mdConsole.authorised}.`;
      recordFormSubmission(form);
      setAuthErrorFeedback(
        <Typography
          style={{ color: "#3CBD89" }}
        >{`${text.mdConsole.order} ${orderNumber} ${text.mdConsole.authorised}.`}</Typography>
      );
    } else {
      form.Feedback = `${text.mdConsole.invalidOrderNumber} - ${orderNumber}.`;
      recordFormSubmission(form);
      setErrorFeedback(`${text.mdConsole.invalidOrderNumber} - ${orderNumber}.`);
    }

    async function authorise(orderNumber: string) {
      try {
        await authoriseOrder(orderNumber);
      } catch {
        setErrorFeedback("Error authorising order.");
      }
    }

    function setErrorFeedback(message: string) {
      setAuthErrorFeedback(<Typography color="error">{message}</Typography>);
      setTimeout(() => setAuthErrorFeedback(<></>), 10000);
    }
  };

  const cancelOrderAuth = () => {
    setSearchInput("");
  };

  const dataset = localStorage.getItem("dataset");
  const currencyLocale = Intl.NumberFormat(referenceData.local, {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 4,
  });

  return (
    <div className={classes.padding}>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
              {dataset?.includes("gogreen") ? (
                <BankCO2BalanceChart
                  gameState={gameState}
                  text={text.mdConsole}
                  height={30}
                  currencyParser={currencyParser}
                />
              ) : (
                <BankBalanceChart
                  gameState={gameState}
                  text={text.mdConsole}
                  height={30}
                  currencyParser={currencyParser}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item md={3} xs={6}>
                  <StatBox value={gameState.NewOrders} title={text.mdConsole.ordersAuthorised} color="primary">
                    <QueuePlayNext />
                  </StatBox>
                </Grid>
                <Grid item md={3} xs={6}>
                  <StatBox value={gameState.TotalSales} title={text.mdConsole.totalSales} color="primary">
                    <TrendingUp />
                  </StatBox>
                </Grid>
                <Grid item md={3} xs={6}>
                  <StatBox value={gameState.CompletedOrders} title={text.mdConsole.ordersCompleted} color="primary">
                    <Done />
                  </StatBox>
                </Grid>
                <Grid item md={3} xs={6}>
                  <StatBox value={gameState.BreachedSLAs} title={text.mdConsole.breachedSLA} color="primary">
                    <Warning />
                  </StatBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <OrderSelector
                labels={{
                  title: text.mdConsole.mdAuthorisation,
                  enterOrderNumber: text.mdConsole.enterOrderNumber,
                  lastThreeNumbers: text.mdConsole.lastThreeNumbers,
                  submit: text.mdConsole.authorise,
                  clear: text.mdConsole.clear,
                  feedback: text.mdConsole.feedback,
                }}
                onChange={updateOnNumericInput}
                onSubmit={orderAuth}
                onClear={cancelOrderAuth}
                value={searchInput}
                feedback={authErrorFeedback}
                role={"md"}
                gameState={gameState}
                ariaLabel={"CEO Authorization Order"}
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
              <InvestmentPanel gameState={gameState} referenceData={referenceData} text={text} console="CEO" />
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />

        <Grid item xs={12} md={3}>
          {dataset?.includes("gogreen") ? (
            <div style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
              <Co2Stats
                text={text}
                itemSize={6}
                currIndirectPerWeek={gameState.CO2IndirectPerWeek}
                currDirectPerWeek={gameState.CO2DirectPerUnitPerWeek}
                standardDirectPerUnit={gameState.Co2Reports[0].Co2SalesCostPerUnit}
                standardOpsCostPerWeek={gameState.Co2Reports[0].Co2OperationalCostPerWeek}
              />
              <Divider style={{ margin: "5px" }} />
            </div>
          ) : null}
          {dataset?.includes("culture") ? (
            <div style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
              <NpsStats text={text} itemSize={6} gameState={gameState} />
              <Divider style={{ margin: "5px" }} />
            </div>
          ) : null}
          <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
            {text.mdConsole.detailedNewsUpdates}
          </Typography>
          <NewsReel title="" newsItems={gameState.NewsItems} role={"main"} full />
        </Grid>
      </Grid>
    </div>
  );
};

export default MDConsole;
