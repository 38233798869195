import React, { useState } from "react";

import BankBalanceChart from "../../../components/bankBalanceChart/BankBalanceChart";
import ReportRoundSelector from "../../../components/reportRoundSelector/ReportRoundSelector";

import { IScreenProps } from "../../../types/app.types";
import ScopeBalanceChart from "../../../components/bankBalanceChart/ScopeBalanceChart";
import { Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Co2ReportTable from "../../../components/co2ReportTable/Co2ReportTable";

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

const Co2Report: React.FC<IScreenProps> = ({ gameState, text }) => {
  const [roundSelection, setRoundSelection] = useState(gameState.Round - 1);

  const classes = useStyles();

  return (
    <Grid container className={classes.cont}>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12} className={classes.margin}>
            <ReportRoundSelector onChange={setRoundSelection} gameState={gameState} initial={roundSelection} />
          </Grid>
          <Grid item xs={12} className={classes.margin}>
            <ScopeBalanceChart
              gameState={gameState}
              text={text.cashReport}
              total={gameState.CO2ReportValues[roundSelection]}
              scope1={gameState.CO2S1ReportValues[roundSelection]}
              scope2={gameState.CO2S2ReportValues[roundSelection]}
              scope3={gameState.CO2S3ReportValues[roundSelection]}
              mode={"chart"}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Co2ReportTable gameState={gameState} text={text} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default Co2Report;
