import React, { useContext } from "react";
import { IScreenProps } from "../types/app.types";
import useReferenceData from "../hooks/useReferenceData";
import Loading from "../components/loading/Loading";
import useDynamicText from "../hooks/useDynamicText";
import { ThemeProvider, Theme, StyledEngineProvider, CssBaseline } from "@mui/material";
import { AppContext } from "../context";
import { IGameState } from "../types/gameState.types";
import ConfluencePage from "../pages/confluence/ConfluencePage";
import Handbook from "../pages/consoles/Documents/Handbook";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  component: React.FC<IScreenProps>;
  togglePanel?: any;
  panelOpen?: boolean;
  sidebarExpanded?: boolean;
}

const ScreenContainer: React.FC<Props> = (props) => {
  const { component: Component, togglePanel, panelOpen, sidebarExpanded } = props;
  const { state, dispatch } = useContext(AppContext);

  if (!state.gameState || !state.referenceData || !state.text) {
    return <Loading />;
  }
  const currencyLocale = Intl.NumberFormat(state.referenceData.local, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    currencySign: "accounting",
  });

  const numberFormat = (arg: number): string => {
    return `${arg < 0 ? "&minus;" : ""}${arg}`;
  };

  return Component === Handbook &&
    typeof state.referenceData.HandbookPageId === "number" &&
    state.referenceData.HandbookPageId > 0 ? (
    <ConfluencePage pageId={state.referenceData.HandbookPageId.toString()} />
  ) : (
    <Component
      gameState={state.gameState}
      referenceData={state.referenceData}
      text={state.text}
      currencyParser={currencyLocale}
      numberParser={numberFormat}
      togglePanel={togglePanel}
      panelOpen={panelOpen}
      sidebarExpanded={sidebarExpanded}
    />
  );
};

export default ScreenContainer;
