import {
  Paper,
  Theme,
  Link,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChartData, ChartDataset, ChartType } from "chart.js";
import { registerables } from "chart.js";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { IGameState, ILeadToCashViewModel } from "../../types/gameState.types";
import { ITextSet } from "../../types/text.types";

const chartOptions = {
  type: "bar",
  legend: {
    display: true,
  },
  indexAxis: "y" as const,
  scales: {
    y: {
      stacked: true,
    },
    x: {
      stacked: true,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  // plugins: {
  //     datalabels: {
  //         display: true,
  //     }
  // }
};

const generateChartData = (data: ILeadToCashViewModel[], text: ITextSet): any => {
  const labels: string[] = [];
  const marketSalesLength: number[] = [];
  const authLength: number[] = [];
  const queuedLength: number[] = [];
  const setUpLength: number[] = [];
  const prodLength: number[] = [];
  const financeLength: number[] = [];
  const paymentLength: number[] = [];
  const datasets: ChartDataset[] = [];
  data.forEach((element) => {
    labels.push(element.OrderNumber);
    authLength.push(element.AuthorisedLength);
    queuedLength.push(element.QueuedLength);
    setUpLength.push(element.SetupLength);
    prodLength.push(element.ProducingLength);
    financeLength.push(element.FinanceLength);
    paymentLength.push(element.PaymentLength);
  });

  const authDataSet: ChartDataset = {
    data: authLength,
    label: "Authorised",
    backgroundColor: "rgb(128,107,169)",
    stack: "a",
  };
  const queued: ChartDataset = {
    data: queuedLength,
    label: "In Queue",
    backgroundColor: "rgba(119,155,180)",
    stack: "a",
  };
  const setup: ChartDataset = {
    data: setUpLength,
    label: text.leadToCashReport.configuration,
    backgroundColor: "rgb(150,198,138)",
    stack: "a",
  };
  const prodDataSet: ChartDataset = {
    data: prodLength,
    label: text.leadToCashReport.production,
    backgroundColor: "rgb(85,146,123)",
    stack: "a",
  };
  const financedDataSet: ChartDataset = {
    data: financeLength,
    label: "Awaiting Invoicing",
    backgroundColor: "rgb(210,55,59)",
    stack: "a",
  };
  const paymentDataSet: ChartDataset = {
    data: paymentLength,
    label: "Awaiting Payment",
    backgroundColor: "rgb(220,220,220)",
    stack: "a",
  };
  datasets.push(authDataSet);
  datasets.push(queued);
  datasets.push(setup);
  datasets.push(prodDataSet);
  datasets.push(financedDataSet);
  datasets.push(paymentDataSet);

  return {
    labels,
    datasets: datasets,
  };
};

interface Props {
  gameState: IGameState;
  text: ITextSet;
  data?: ILeadToCashViewModel[];
  mode?: "full" | "chart" | "values";
  height: string;
}

const LeadToCashChart: React.FC<Props> = ({ gameState, text, mode = "full", data, height }) => {
  data = data || gameState.LeadToCashViewModels[0];

  const [tableView, setTableView] = useState(false);

  const useStyles = makeStyles((theme: Theme) => ({
    grid: {
      marginBottom: theme.spacing(5),
    },
    chart: {
      height: height,
    },
    carbonRow: {
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  return (
    <React.Fragment>
      {["full", "chart"].includes(mode) && (
        <Paper className={classes.chart}>
          <Typography>
            This chart represents the status of orders processed throughout the round, indicating how many weeks each
            order spent in each state.{" "}
            <Link onClick={() => setTableView(!tableView)} underline="hover">
              {" "}
              {tableView ? "View as Graph" : "View as Table"}
            </Link>
          </Typography>
          {tableView ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order</TableCell>
                    <TableCell>Authorzied</TableCell>
                    <TableCell>In Queue</TableCell>
                    <TableCell>{text.leadToCashReport.configuration}</TableCell>
                    <TableCell>{text.leadToCashReport.production}</TableCell>
                    <TableCell>Awaiting Invoicing</TableCell>
                    <TableCell>Awaiting Payment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((element) => {
                    return (
                      <TableRow key={element.OrderNumber}>
                        <TableCell>{element.OrderNumber}</TableCell>
                        <TableCell>{element.AuthorisedLength}</TableCell>
                        <TableCell>{element.QueuedLength}</TableCell>
                        <TableCell>{element.SetupLength}</TableCell>
                        <TableCell>{element.ProducingLength}</TableCell>
                        <TableCell>{element.FinanceLength}</TableCell>
                        <TableCell>{element.PaymentLength}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Bar data={generateChartData(data, text)} options={chartOptions} />
          )}
        </Paper>
      )}
    </React.Fragment>
  );
};

export default LeadToCashChart;
