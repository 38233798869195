import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

import Timeline from "../../../../../components/timeline/Timeline";

import { IProductionItemViewModel } from "../../../../../types/gameState.types";
import { IProductionConsoleText, ITextSet } from "../../../../../types/text.types";

interface Props {
  title: string;
  text: ITextSet;
  productionItems: IProductionItemViewModel[];
}

const ProductionQueue: React.FC<Props> = ({ text, productionItems, title }) => {
  const orderStateTextMap: any = {
    "IN PRODUCTION": text.productionConsole.inProduction,
    "SETTING UP": text.productionConsole.settingUp,
    QUEUED: text.productionConsole.queued,
  };

  return (
    <div
      style={{
        borderBottom: "solid 1px gainsboro",
        borderRight: "solid 1px gainsboro",
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Order Number</TableCell>
            <TableCell>{text.financeConsole.product}</TableCell>
            <TableCell>{text.financeConsole.billableHours}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productionItems.map((item) => (
            <TableRow key={item.OrderNumber}>
              <TableCell>{item.OrderNumber}</TableCell>
              <TableCell>{item.Product}</TableCell>
              <TableCell>{item.NumberOfUnits}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProductionQueue;
