import React, { useState, SyntheticEvent, ChangeEvent } from "react";
import { Nav } from "react-bootstrap";
import { Box, Tabs, Tab, Paper, Button, createTheme, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { green, red } from "@mui/material/colors";
import { IGameState } from "../../types/gameState.types";
import { StyledTabs, StyledTab } from "../tabs/Tabs";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
  },
  button: {
    marginRight: theme.spacing(1),
    float: "left",
  },
}));

interface OnChangeFunction {
  (value: number): void;
}

interface Props {
  onChange: OnChangeFunction;
  gameState: IGameState;
  setQueueSelection?: any;
  queue?: number;
  initial: number;
  sheet?: boolean;
}

const ReportRoundSelector: React.FC<Props> = ({ onChange, gameState, setQueueSelection, queue, initial, sheet }) => {
  const [value, setValue] = useState(initial);

  const classes = useStyles();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    onChange(newValue);
  };

  function changeLabels(round: number) {
    if (round == 1) {
      return "PRACTICE LEVEL";
    } else if (round == 2) {
      return "LEVEL A";
    } else if (round == 3) {
      return "LEVEL B";
    } else {
      return "null";
    }
  }

  return (
    <div className={classes.paper}>
      <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
        {sheet && <StyledTab label="WALKAROUND" disabled={gameState.Round < 0} />}
        {[...Array(gameState.NumberOfRounds)].map((e, i) => {
          const level = i + 1;
          const tabIndex = i + 16; //to allow tab to follow report selector

          return <StyledTab key={level} label={"Level " + level} disabled={gameState.Round < level} tabIndex={4} />;
        })}
      </StyledTabs>
    </div>
  );
};

export default ReportRoundSelector;
