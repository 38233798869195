import { Button, Grid, TextField, ThemeProvider, Theme, StyledEngineProvider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import StatBox from "../../../../components/statBox/StatBox";
import { depositCash, repayInvestment, recordFormSubmission } from "../../../../modules/gameState/service";
import { IGameState } from "../../../../types/gameState.types";
import { IFacilitatorConsoleText } from "../../../../types/text.types";
import controlsTheme from "./controlsTheme";
import { IPlayerEvent } from "../../../../types/playerEvents.types";
import formatAsUSD from "../../../../utils/formatAsUSD";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  gameState: IGameState;
  text: IFacilitatorConsoleText;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export const CashPanel: React.FC<Props> = ({ gameState, text }) => {
  const [valueToDeposit, setValueToDeposit] = useState("");
  const [valueToRepay, setValueToRepay] = useState("");
  const [cashFeedback, setCashFeedback] = useState("");

  const deposit = async () => {
    const newDate: Date = new Date();
    const form: IPlayerEvent = {
      id: localStorage.getItem("guid") + newDate.toString(),
      GameId: localStorage.getItem("guid"),
      Console: "Facilitator Controls",
      Round: gameState.Round.toString(),
      Week: gameState.Week.toString(),
      Submission: "Deposited: " + valueToDeposit.toString(),
      DateTime: newDate,
      Feedback: "",
    };

    try {
      await depositCash(valueToDeposit);
      form.Feedback = `${parseInt(valueToDeposit)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${text.deposited}.`;
      recordFormSubmission(form);
      setCashFeedback(
        `${parseInt(valueToDeposit)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${text.deposited}.`
      );
      setValueToDeposit("");
      setValueToRepay("");
    } catch {
      form.Feedback = "Error depositing cash";
      recordFormSubmission(form);
      setCashFeedback("Error depositing cash.");
      setValueToDeposit("");
      setValueToRepay("");
    }
  };

  const repay = async () => {
    const newDate: Date = new Date();
    const form: IPlayerEvent = {
      id: localStorage.getItem("guid") + newDate.toString(),
      GameId: localStorage.getItem("guid"),
      Console: "Facilitator Controls",
      Round: gameState.Round.toString(),
      Week: gameState.Week.toString(),
      Submission: "Repayed: " + valueToDeposit.toString(),
      DateTime: newDate,
      Feedback: "",
    };
    try {
      await repayInvestment(valueToRepay);
      form.Feedback = `${parseInt(valueToRepay)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${text.repaid}.`;
      recordFormSubmission(form);
      setCashFeedback(
        `${parseInt(valueToRepay)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${text.repaid}.`
      );
      setValueToDeposit("");
      setValueToRepay("");
    } catch {
      form.Feedback = "Error repaying investment.";
      recordFormSubmission(form);
      setCashFeedback("Error repaying investment.");
      setValueToDeposit("");
      setValueToRepay("");
    }
  };

  const classes = useStyles();

  const cancelCashAction = () => {
    setValueToRepay("");
    setValueToDeposit("");
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <TextField
          type="number"
          id="depositCashInput"
          value={valueToDeposit}
          onChange={(e) => setValueToDeposit(e.target.value)}
          label={text.depositCash}
          aria-label="Deposit Cash Amount"
          name="Deposit"
        />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={controlsTheme}>
            <Button
              className={classes.button}
              onClick={deposit}
              variant="contained"
              color="primary"
              disabled={!valueToDeposit}
              name={text.deposit}
            >
              <Typography component="p" variant="subtitle2">
                {text.deposit}
              </Typography>
            </Button>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
      <Grid item xs={6}>
        <StatBox title={text.currentBalance} value={formatAsUSD(gameState.BankCurrent)} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="number"
          id="repaymentCashInput"
          value={valueToRepay}
          onChange={(e) => setValueToRepay(e.target.value)}
          label={text.repaymentAmount}
          aria-label="Repay Cash Amount"
          name="Repay"
        />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={controlsTheme}>
            <Button
              className={classes.button}
              onClick={repay}
              variant="contained"
              color="secondary"
              disabled={!valueToRepay}
              name={text.repay}
            >
              <Typography component="p" variant="subtitle2">
                {text.repay}
              </Typography>
            </Button>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
      <Grid item xs={6}>
        <p>
          <strong>{text.feedback}</strong>
        </p>
        <p aria-live="polite">{cashFeedback}</p>
      </Grid>
    </Grid>
  );
};
