import { createTheme, Theme, responsiveFontSizes } from "@mui/material";
import { green, red } from "@mui/material/colors";

const controlsTheme: Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: green[700],
    },
    secondary: {
      main: red[700],
    },
  },
});
export default responsiveFontSizes(controlsTheme);
