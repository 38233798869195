import { socketDataAxios } from "./api";
socketDataAxios.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_GAME_STATE_API_KEY ?? "";

export const getSocketData = async () => {
  try {
    const response = await socketDataAxios.get(`/Negotiate?user=${localStorage.getItem("rocketSessionId")}`);
    const { Text, ...rest } = response.data;

    return rest;
  } catch {
    return null;
  }
};
