import { CircularProgress } from "@mui/material";
import React from "react";
// import "bootstrap-css-only/css/bootstrap.min.css";
import { Container, Spinner } from "react-bootstrap";

const Loading: React.FC = () => {
  return (
    <Container style={{ textAlign: "center", paddingTop: "30vh" }}>
      <CircularProgress color="primary" />
    </Container>
  );
};

export default Loading;
