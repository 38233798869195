import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TRCLogo from "../../assets/TRCLOGOBLACK.png";
import "./Initials.css";
import { challengeStart } from "../../modules/auth/service";
import useChallengeTime from "../../hooks/useChallengeTime";
import { marginBottom } from "html2canvas/dist/types/css/property-descriptors/margin";

const LoginSelectionPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const challengeTime = Number(useChallengeTime());
  const [btnEnabled, setBtnEnabled] = useState(false);

  const DescNotReady = "If you and your team are ready to start, you can click on the button below";
  const ButtonTextNotReady = "Click to Proceed";
  const DescReady = "The game has now started, click the button to proceed";

  useEffect(() => {
    if (challengeTime > 0) {
      setBtnEnabled(true);
    }
  }, [challengeTime]);

  const commenceChallenge = () => {
    challengeStart(localStorage.getItem("guid"));
    navigate("/");
  };

  return (
    <div className="loginScreen">
      <div className="loginWrapper">
        <div className="loginHeader uiTextShadow">
          <div>
            <img src={TRCLogo} alt="The Rocket Challenge" />
          </div>
        </div>
        <div className="loginBox guidBox">
          <div className="guidEntryCont">
            <h2 className="headerLogin">{DescNotReady}</h2>
            <p
              style={{
                fontWeight: "bold",
                marginLeft: "10%",
                marginRight: "10%",
              }}
            >
              Welcome to Rocket!
            </p>
            {btnEnabled ? (
              <button
                className="guidBtn uiBoxShadow uiTextShadow buttonLogin"
                style={{ marginBottom: "10px" }}
                disabled={!btnEnabled}
                onClick={() => navigate("/")}
              >
                {ButtonTextNotReady}
              </button>
            ) : null}
            <span></span>
            {!btnEnabled ? (
              <button
                className="guidBtn uiBoxShadow uiTextShadow buttonLogin"
                disabled={btnEnabled}
                onClick={commenceChallenge}
              >
                Enter Rocket
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSelectionPage;
