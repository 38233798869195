import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import ScreenRoute from "./ScreenRoute";

import TradingReport from "../pages/reports/trading/TradingReport";
import CashReport from "../pages/reports/cash/CashReport";
import ProductionReport from "../pages/reports/production/ProductionReport";
import LeadToCashReport from "../pages/reports/leadToCash/LeadToCashReport";
import FinalReport from "../pages/reports/final/FinalReport";

import {
  TradingReportRoute,
  CashReportRoute,
  ProductionReportRoute,
  Co2ReportRoute,
  LeadToCashReportRoute,
  FinalReportRoute,
} from "./routes";
import Co2Report from "../pages/reports/co2/Co2Report";

const dataset = localStorage.getItem("dataset");

export const ReportRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={TradingReportRoute.toUrl()} element={<ScreenRoute path="*" component={TradingReport} />} />
      <Route
        path={CashReportRoute.toUrl()}
        element={<ScreenRoute path={CashReportRoute.toUrl()} component={CashReport} />}
      />
      <Route
        path={ProductionReportRoute.toUrl()}
        element={<ScreenRoute path={ProductionReportRoute.toUrl()} component={ProductionReport} />}
      />
      <Route
        path={LeadToCashReportRoute.toUrl()}
        element={<ScreenRoute path={LeadToCashReportRoute.toUrl()} component={LeadToCashReport} />}
      />
      <Route
        path={TradingReportRoute.toUrl()}
        element={<ScreenRoute path={TradingReportRoute.toUrl()} component={TradingReport} />}
      />
      {dataset?.includes("gogreen") ? (
        <Route
          path={Co2ReportRoute.toUrl()}
          element={<ScreenRoute path={Co2ReportRoute.toUrl()} component={Co2Report} />}
        />
      ) : null}
      {dataset?.includes("culture") ? (
        <Route
          path={FinalReportRoute.toUrl()}
          element={<ScreenRoute path={FinalReportRoute.toUrl()} component={FinalReport} />}
        />
      ) : null}
      <Route
        path="/"
        element={
          <PrivateRoute path="/">
            <Navigate to={TradingReportRoute.toUrl()} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
