import React from "react";
import "./VideoBox.css";

interface Props {
  videoLink: string;
  videoHeader: string;
}

const VideoBox: React.FC<Props> = (props) => {
  const { videoLink, videoHeader } = props;

  return (
    <div className="embed-responsive embed-responsive-16by9" style={{ height: "80vh" }}>
      <iframe title="embedsPage" className="embed-responsive-item" src={videoLink} allowFullScreen></iframe>
    </div>
  );
};

export default VideoBox;
