import React, { useState } from "react";
import { IScreenProps } from "../../../types/app.types";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SentimentVeryDissatisfied, SentimentDissatisfied, SentimentVerySatisfied } from "@mui/icons-material";
import { Radar } from "react-chartjs-2";
import "./FinalReport.css";
import { LinearGradient } from "@visx/gradient";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import formatAsUSD from "../../../utils/formatAsUSD";

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
  root: {
    height: 20,
    backgroundColor: "lightgray",
    borderRadius: 10,
    position: "relative",
    margin: "10px 0",
    "& div": {
      borderRadius: 10,
      position: "absolute",
      // top: 0,
      left: 0,
      height: "100%",
    },
  },
}));

const calculateNPS = (promoters: number, passives: number, detractors: number) => {
  const total = promoters + passives + detractors;
  const nps = ((promoters - detractors) / total) * 100;
  return nps.toFixed(0);
};

type TupleType = { Item1: number; Item2: number; Item3: number };
const defaultTuple: TupleType = { Item1: 0, Item2: 1, Item3: 0 };

const renderIcons = (count: number, type: string) => {
  let IconComponent: OverridableComponent<SvgIconTypeMap<unknown, "svg">> | undefined;
  let color: string | undefined;

  switch (type) {
    case "detractor":
      IconComponent = SentimentVeryDissatisfied;
      color = "red";
      break;
    case "passive":
      IconComponent = SentimentDissatisfied;
      color = "amber";
      break;
    case "promoter":
      IconComponent = SentimentVerySatisfied;
      color = "green";
      break;
    default:
      return null;
  }

  return Array(count)
    .fill(0)
    .map((_, i) => (IconComponent ? <IconComponent key={i} style={{ color }} /> : null));
};

const options = {
  scales: {
    r: {
      min: 0,
      max: 5,
      ticks: {
        stepSize: 1,
      },
    },
  },
};
export const RadarOptions2 = {
  scale: {
    ticks: {
      min: 0,
      max: 25,
      stepSize: 2,
      showLabelBackdrop: false,
      backdropColor: "rgba(203, 197, 11, 1)",
    },
    angleLines: {
      color: "rgba(255, 255, 255, .3)",
      lineWidth: 1,
    },
    gridLines: {
      color: "rgba(255, 255, 255, .3)",
      circular: true,
    },
  },
};

const FinalReport: React.FC<IScreenProps> = ({ gameState, referenceData, text, currencyParser }) => {
  const [roundSelection, setRoundSelection] = useState(gameState.Round - 1);

  const classes = useStyles();

  const financialResult = gameState.BankCurrent;
  const min = -300000;
  const max = 1200000;
  const percentage = ((financialResult - min) / (max - min)) * 100;

  const data1 = {
    labels: referenceData.Capabilities.map((c) => c),
    datasets: [
      {
        label: "Level 0",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        pointBackgroundColor: "rgba(255,99,132,1)",
        poingBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(255,99,132,1)",
        data: referenceData.Capabilities.map((c) => gameState.CapabilityValues[c]?.[0] ?? 0),
      },
    ],
  };

  const data2 = {
    labels: referenceData.Capabilities.map((c) => c),
    datasets: [
      {
        label: "Level 2",
        backgroundColor: "rgba(54,162,235,0.2)",
        borderColor: "rgba(54,162,235,1)",
        pointBackgroundColor: "rgba(54,162,235,1)",
        poingBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(54,162,235,1)",
        data: referenceData.Capabilities.map((c) => gameState.CapabilityValues[c]?.[1] ?? 0),
      },
    ],
  };

  const currentCustomerNPS =
    gameState.Round > 0 && gameState.Week > 0
      ? gameState.CustomerNPSReportValues[gameState.Round - 1][gameState.Week]
      : defaultTuple;
  const currentEmployeeNPS =
    gameState.Round > 0 && gameState.Week > 0
      ? gameState.EmployeeNPSReportValues[gameState.Round - 1][gameState.Week]
      : defaultTuple;

  const currentCustomerNPSScore = calculateNPS(
    currentCustomerNPS.Item3,
    currentCustomerNPS.Item2,
    currentCustomerNPS.Item1
  );
  const currentEmployeeNPSScore = calculateNPS(
    currentEmployeeNPS.Item3,
    currentEmployeeNPS.Item2,
    currentEmployeeNPS.Item1
  );

  return (
    <Grid container className={classes.cont}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12} className={classes.margin}>
            <h2>Financial Result: {formatAsUSD(gameState.BankCurrent)}</h2>
            <div className={classes.root}>
              <svg width="100%" height="20">
                <LinearGradient id="gradient" from="#f00" to="#0f0" x1="0%" x2="100%" />
                <rect width={`${percentage}%`} height="20" fill="url(#gradient)" />
              </svg>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ position: "absolute", left: "0" }}>-$300k</span>
                <span
                  style={{
                    position: "absolute",
                    left: "20%",
                    transform: "translateX(-50%)",
                  }}
                >
                  $0
                </span>
                <span style={{ position: "absolute", right: "0" }}>$1200k</span>
                <span
                  style={{
                    position: "absolute",
                    left: `${percentage}%`,
                    transform: "translateX(-50%)",
                  }}
                >
                  <strong>{formatAsUSD(gameState.BankCurrent, true)}</strong>
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <h3>Customer NPS Score: {currentCustomerNPSScore}%</h3>
            </div>
            <div className="icons-container">
              <div className="icons-group">
                <h4>Detractors</h4>
                {renderIcons(currentCustomerNPS.Item1, "detractor")}
              </div>
              <div className="icons-group">
                <h4>Passives</h4>
                {renderIcons(currentCustomerNPS.Item2, "passive")}
              </div>
              <div className="icons-group">
                <h4>Promoters</h4>
                {renderIcons(currentCustomerNPS.Item3, "promoter")}
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <h3>Employee NPS Score: {currentEmployeeNPSScore}%</h3>
            </div>
            <div className="icons-container">
              <div className="icons-group">
                <h4>Detractors</h4>
                {renderIcons(currentEmployeeNPS.Item1, "detractor")}
              </div>
              <div className="icons-group">
                <h4>Passives</h4>
                {renderIcons(currentEmployeeNPS.Item2, "passive")}
              </div>
              <div className="icons-group">
                <h4>Promoters</h4>
                {renderIcons(currentEmployeeNPS.Item3, "promoter")}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <hr />
            <h2>Capabilities</h2>
          </Grid>
          <Grid item xs={6}>
            <Radar data={data1} options={options} />
          </Grid>
          <Grid item xs={6}>
            <Radar data={data2} options={options} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};

export default FinalReport;
