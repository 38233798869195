import { Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Questioning from "../assets/Questioning.png";

class NotFoundPage extends Component {
  render() {
    return (
      <Grid container spacing={5} style={{ alignContent: "center" }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img src={Questioning} alt="Bad Robot" height="200px" />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography component="span" variant="h4">
            404
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography component="span" variant="subtitle1">
            Oh no. We can&apos;t find what you are looking for... Try going{" "}
            <a href="/game/main">back to the simulation</a>.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default NotFoundPage;
