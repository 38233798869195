import React from "react";
import Options from "../Options/Options";

const GeneralOptions = (props) => {
  const options = [
    {
      name: "restart tour",
      handler: props.actionProvider.handleWelcomeTourAction,
      id: 1,
    },
  ];

  return <Options options={options} />;
};

export default GeneralOptions;
