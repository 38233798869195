import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import Overview from "./widgets/Overview/Overview";
import MessageParserDocs from "./widgets/docs/MessageParserDocs/MessageParserDocs";
import ActionProviderDocs from "./widgets/docs/ActionProviderDocs/ActionProviderDocs";
import Config from "./widgets/docs/Config/Config";
import WidgetDocs from "./widgets/docs/WidgetDocs/WidgetDocs";
import TourOptions from "./widgets/options/TourOptions/TourOptions";
import FinanceDocs from "./widgets/docs/FinanceDocs/FinanceDocs";

const botName = "RocketBot";

const config = {
  botName: botName,
  lang: "no",
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#5ccc9d",
    },
  },
  initialMessages: [
    createChatBotMessage(`Hi I'm ${botName}. How can I help?`),
    createChatBotMessage("Here's a quick overview over what I can help with. Click an option for more information", {
      withAvatar: false,
      delay: 500,
      widget: "overview",
    }),
  ],
  state: {
    gist: "",
    infoBox: "",
  },
  customComponents: {},
  widgets: [
    {
      widgetName: "overview",
      widgetFunc: (props) => <Overview {...props} />,
      props: null,
      mapStateToProps: ["gist"],
    },
    {
      widgetName: "restartTour",
      widgetFunc: (props) => <TourOptions {...props} />,
      props: null,
      mapStateToProps: ["gist"],
    },
    {
      widgetName: "messageParser",
      widgetFunc: (props) => <MessageParserDocs {...props} />,
      props: null,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "actionProviderDocs",
      widgetFunc: (props) => <ActionProviderDocs {...props} />,
      props: null,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "config",
      widgetFunc: (props) => <Config {...props} />,
      props: null,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "widget",
      widgetFunc: (props) => <WidgetDocs {...props} />,
      props: null,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "finance",
      widgetFunc: (props) => <FinanceDocs {...props} />,
      props: null,
      mapStateToProps: ["gist", "infoBox"],
    },
  ],
};

export default config;
