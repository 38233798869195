import React, { useContext, useEffect, useState } from "react";
import useGameSecondsWeek from "../../hooks/useGameSeconds";
import { IGameState } from "../../types/gameState.types";
import StatBox from "../statBox/StatBox";
import { AppContext } from "../../context";
import { useTheme } from "@mui/material";

interface Props {
  text: string;
  week: number;
}
const SecondsWrapper: React.FC<Props> = (props) => {
  const { text } = props;
  const [week, setWeek] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const { state, dispatch } = useContext(AppContext);
  // const seconds =  useGameSecondsWeek(1000);

  const theme = useTheme();

  useEffect(() => {
    let isMounted = true;

    const tick = async () => {
      const lastUpdated = new Date(state.gameState.LastUpdated);

      const totalSecondsSinceLevelStart = state.gameState.Week * 60 + state.gameState.SecondsElapsedInWeek;
      const totalSecondsSinceLastUpdate =
        state.gameState.RunningState !== "Running"
          ? 0
          : Math.floor((new Date().getTime() - lastUpdated.getTime()) / 1000);
      const minutes = Math.floor((totalSecondsSinceLevelStart + totalSecondsSinceLastUpdate) / 60);
      const seconds = totalSecondsSinceLevelStart + totalSecondsSinceLastUpdate - minutes * 60;
      if (isMounted) {
        setWeek(minutes);
        setSeconds(seconds);
      }
    };

    const id = setInterval(tick, 1000);
    return () => {
      clearInterval(id);
      isMounted = false;
    };
  });

  return (
    <React.Fragment>
      <StatBox
        value={week}
        subValue={` (${seconds}s)`}
        title={text}
        colorCust="white"
        backgroundColor={theme.palette.primary.main}
      />
    </React.Fragment>
  );
};

export default SecondsWrapper;
