import React, { useContext } from "react";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Card, Container, CssBaseline, Grid, Paper, Typography, Link } from "@mui/material";
import clsx from "clsx";
import ProductionTheme from "./productionTheme";
import { Block, Done, QueuePlayNext, Warning } from "@mui/icons-material";

import StatBox from "../../../components/statBox/StatBox";
import Co2Stats from "../../../components/co2Stats/Co2Stats";
import NpsStats from "../../../components/npsStats/NpsStats";

import ProductionQueueStatus from "./components/productionQueueStatus/ProductionQueueStatus";
import ProductionPanel from "./components/ProductionPanel/ProductionPanel";
import { advanceOrderState } from "../../../modules/gameState/service";
import CustomerAcceptance from "./components/CustomerAcceptance/CustomerAcceptance";

import { IScreenProps } from "../../../types/app.types";

import image43 from "../../../assets/image43.png";
import image45 from "../../../assets/image45.png";
import image57 from "../../../assets/image57.png";
import image71 from "../../../assets/image71.png";
import { AppContext } from "../../../context";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
  padding: {
    maxWidth: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginBottom: "21px",
  },
  fixedHeight: {
    height: "auto",
  },
  cheating: {
    paddingTop: "1px",
  },
  background: {
    //backgroundColor: theme.palette.secondary.main,
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ProductionConsole: React.FC<IScreenProps> = ({ gameState, text, togglePanel, panelOpen }) => {
  document.title = `Delivery | ${text.wrapper.gameName}`;

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const dataset = localStorage.getItem("dataset");
  const { state, dispatch } = useContext(AppContext);

  //debugger;

  return (
    <div className={classes.padding}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CustomerAcceptance
                orderList={gameState.VisibleOrderList}
                progressOrderFunction={advanceOrderState}
                gameState={gameState}
                console={"Delivery"}
                text={text}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <StatBox value={gameState.NewOrders} title={text.productionConsole.newOrdersTaken}>
                    <QueuePlayNext />
                  </StatBox>
                </Grid>
                <Grid item xs={6}>
                  <StatBox
                    value={gameState.OrdersCompletedProduction}
                    title={text.productionConsole.ordersCompleted}
                    color="primary"
                  >
                    <Done />
                  </StatBox>
                </Grid>
                <Grid item xs={6}>
                  <StatBox value={gameState.BreachedSLAs} title={text.productionConsole.breachedSLA} color="primary">
                    <Warning color="primary" />
                  </StatBox>
                </Grid>
                <Grid item xs={6}>
                  <StatBox value={gameState.ProductionDowntime} title={text.productionConsole.downtimeWeeks}>
                    <Block />
                  </StatBox>
                </Grid>
                <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
                  {dataset?.includes("gogreen") ? (
                    <Co2Stats
                      text={text}
                      itemSize={3}
                      currIndirectPerWeek={gameState.CO2IndirectPerWeek}
                      currDirectPerWeek={gameState.CO2DirectPerUnitPerWeek}
                      standardDirectPerUnit={gameState.Co2Reports[0].Co2SalesCostPerUnit}
                      standardOpsCostPerWeek={gameState.Co2Reports[0].Co2OperationalCostPerWeek}
                    />
                  ) : null}
                  {dataset?.includes("culture") ? <NpsStats text={text} itemSize={3} gameState={gameState} /> : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item md={gameState.AdditionalLineActive ? 6 : 12} xs={12}>
                  <ProductionQueueStatus
                    title={`${text.productionConsole.produtionQueue} 1 ${text.productionConsole.status}`}
                    text={text.productionConsole}
                    prodText={text}
                    gameState={gameState}
                    currentProductionItem={gameState.CurrentProductionItems[0]}
                    lastCompletedItem={gameState.LastCompletedItems[0]}
                    variation={gameState.AdditionalLineActive ? "normal" : "minimal"}
                    queue={1}
                  />
                </Grid>
                {gameState.AdditionalLineActive && (
                  <Grid item md={6} xs={12}>
                    <ProductionQueueStatus
                      title={`${text.productionConsole.produtionQueue} 2 ${text.productionConsole.status}`}
                      text={text.productionConsole}
                      prodText={text}
                      gameState={gameState}
                      currentProductionItem={gameState.CurrentProductionItems[1]}
                      lastCompletedItem={gameState.LastCompletedItems[1]}
                      variation="minimal"
                      queue={2}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductionConsole;
