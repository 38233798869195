import { IGameState } from "../types/gameState.types";

const READY = "Ready";
const RUNNING = "Running";
const PAUSED = "Paused";
const FINISHED_GAME = "Finished Game";
const FINISHED_ROUND = "Finished Round";
const GONE_BUST = "Gone Bust";

export const canStartRound = (state: IGameState) => {
  return state.RunningState === READY && !state.IsLocked;
};

export const canPauseRound = (state: IGameState) => {
  return state.RunningState === RUNNING && !state.IsLocked;
};

export const canResumeRound = (state: IGameState) => {
  return state.RunningState === PAUSED && !state.IsLocked;
};

export const canRestartRound = (state: IGameState) => {
  return (
    (state.RunningState === PAUSED || state.RunningState === GONE_BUST || state.RunningState === FINISHED_ROUND) &&
    state.RestartCount < 2 &&
    !state.IsLocked
  );
};

export const canSetupRound = (state: IGameState) => {
  return (
    state.RunningState !== FINISHED_ROUND &&
    state.RunningState !== GONE_BUST &&
    state.RunningState !== FINISHED_GAME &&
    !state.IsLocked
  );
};

export const canEndRound = (state: IGameState) => {
  return state.RunningState === PAUSED && !state.IsLocked;
};

export const canSelectPreviousRound = (state: IGameState) => {
  return (
    state.RunningState !== RUNNING &&
    state.RunningState !== PAUSED &&
    state.Round > 0 &&
    state.RestartCount < 2 &&
    !state.IsLocked
  );
};

export const canSelectNextRound = (state: IGameState) => {
  return (
    (state.RunningState === READY || state.RunningState === FINISHED_ROUND || state.RunningState == GONE_BUST) &&
    state.Round < state.NumberOfRounds &&
    !state.IsLocked
  );
};
