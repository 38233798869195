import React from "react";
import { IScreenProps } from "../../../../types/app.types";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import formatAsGBP from "../../../../utils/formatAsGBP";
import clsx from "clsx";
import appStyles from "../../../../assets/appStyles";

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(3),
    background: "#fafafa",
  },
  number: {
    textAlign: "right",
    width: "10vh",
  },
  table: {
    width: "100%",
  },
  container: {
    //justifyContent: 'space-between',
    padding: theme.spacing(1),
    paddingTop: 0,
    // height: '25vh',
    flexDirection: "column",
    display: "flex",
    marginTop: "-16px",
    maxWidth: "86vw",
  },
  cheating: {
    paddingTop: "1px",
  },
  panelContainer: {
    margin: "auto",
    width: "55vh",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "21ch",
    },
  },
  overflow: {
    overflow: "scroll",
    width: "100%",
  },
}));

const CurrentOrderStatus: React.FC<IScreenProps> = ({ gameState, text, currencyParser }) => {
  const orderStateCSSMap: any = {
    "In Production": "darkBlue",
    "Awaiting Invoicing": "darkOrange",
    "Awaiting Payment": "lightGreen",
  };

  const orderStateTextMap: any = {
    Authorised: text.gameScreen.authorised,
    "In Production": text.gameScreen.inProduction,
    "Awaiting Invoicing": text.gameScreen.awaitingInvoicing,
    "Awaiting Payment": text.gameScreen.awaitingPayment,
  };

  const classes = useStyles();
  const appClasses = appStyles();
  const cellNumber = clsx(classes.cell, classes.number);

  return (
    <div>
      <Paper className={classes.cheating}>
        <div className={appClasses.panelHeader}>
          <h2 className={appClasses.panelTitle}>{text.gameScreen.currentOrderStatus}</h2>
        </div>
        <div className={classes.container}>
          <TableContainer className={classes.overflow}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell component="th" className={classes.cell} />
                  <TableCell component="th" className={classes.cell}>
                    {text.gameScreen.orderNumber}
                  </TableCell>
                  <TableCell component="th" className={classes.cell}>
                    {text.gameScreen.company}
                  </TableCell>
                  <TableCell component="th" className={classes.cell}>
                    {text.gameScreen.value}
                  </TableCell>
                  <TableCell component="th" className={classes.cell}>
                    {text.gameScreen.status}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gameState.VisibleOrderList.map((order) => (
                  <TableRow key={order.OrderNumber}>
                    <TableCell component="td" className={classes.cell}>
                      <div className={`square ${orderStateCSSMap[order.OrderStatus]}`} />
                    </TableCell>
                    <TableCell component="td" className={classes.cell}>
                      {order.OrderNumber}
                    </TableCell>
                    <TableCell component="td" className={classes.cell}>
                      {order.CompanyName}
                    </TableCell>
                    <TableCell component="td" className={cellNumber}>
                      {currencyParser.format(order.TotalCost)}
                    </TableCell>
                    <TableCell component="td" className={classes.cell}>
                      {orderStateTextMap[order.OrderStatus]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    </div>
  );
};

export default CurrentOrderStatus;
