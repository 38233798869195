import React from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import ReportContainer from "../containers/ReportContainer";
import EtherpadsOPS from "../pages/consoles/Documents/EtherpadsOPS";
import EtherpadsOTS from "../pages/consoles/Documents/EtherpadsOTS";
import Videos from "../pages/consoles/Documents/Videos";
import GameControls from "../pages/consoles/facilitator/GameControls";
import MainScreen from "../pages/consoles/facilitator/MainScreen";
import FinanceConsole from "../pages/consoles/finance/FinanceConsole";
import MarketingConsole from "../pages/consoles/marketing/MarketingConsole";
import MDConsole from "../pages/consoles/md/MDConsole";
import ProductionConsole from "../pages/consoles/production/ProductionConsole";
import SalesConsole from "../pages/consoles/sales/SalesConsole";
import { IGameState } from "../types/gameState.types";
import PrivateRoute from "./PrivateRoute";
import {
  EtherpadOPSRoute,
  EtherpadOTSRoute,
  FinanceRoute,
  GameControlsRoute,
  MainScreenRoute,
  MarketingRoute,
  MDRoute,
  ProductionRoute,
  ReportsBaseRoute,
  SalesRoute,
  VideoRoute,
  HandbookRoute,
  FirewallRoute,
  BankBalanceCO2TableRoute,
  BankBalanceTableRoute,
} from "./routes";
import ScreenRoute from "./ScreenRoute";
import Handbook from "../pages/consoles/Documents/Handbook";
import FirewallConsole from "../pages/consoles/firewall/FirewallConsole";
import NotFoundPage from "../pages/NotFoundPage";
import BankCO2TableViewer from "../pages/consoles/TableViewers/BankCO2TableViewer";
import BankTableViewer from "../pages/consoles/TableViewers/BankTableViewer";
import ConfluencePage from "../pages/confluence/ConfluencePage";

interface Props {
  togglePanel: any;
  panelOpen: boolean;
  sidebarExpanded?: boolean;
}

const ConfluencePageWrapper = () => {
  const { pageId = "" } = useParams();

  return <ConfluencePage pageId={pageId} />;
};

export const GameRouter: React.FC<Props> = ({ togglePanel, panelOpen, sidebarExpanded }) => {
  const location = useLocation();

  return (
    <Routes>
      <Route
        path={MainScreenRoute.toUrl()}
        element={<ScreenRoute path={MainScreenRoute.toUrl()} component={MainScreen} />}
      />
      <Route path={VideoRoute.toUrl()} element={<ScreenRoute path={VideoRoute.toUrl()} component={Videos} />} />
      <Route
        path={EtherpadOTSRoute.toUrl()}
        element={
          <ScreenRoute path={EtherpadOTSRoute.toUrl()} component={EtherpadsOTS} sidebarExpanded={sidebarExpanded} />
        }
      />
      <Route
        path={EtherpadOPSRoute.toUrl()}
        element={
          <ScreenRoute path={EtherpadOPSRoute.toUrl()} component={EtherpadsOPS} sidebarExpanded={sidebarExpanded} />
        }
      />
      <Route
        path={BankBalanceCO2TableRoute.toUrl()}
        element={
          <ScreenRoute
            path={BankBalanceCO2TableRoute.toUrl()}
            component={BankCO2TableViewer}
            sidebarExpanded={sidebarExpanded}
          />
        }
      />
      <Route
        path={BankBalanceTableRoute.toUrl()}
        element={
          <ScreenRoute
            path={BankBalanceTableRoute.toUrl()}
            component={BankTableViewer}
            sidebarExpanded={sidebarExpanded}
          />
        }
      />
      <Route path={HandbookRoute.toUrl()} element={<ScreenRoute path={HandbookRoute.toUrl()} component={Handbook} />} />
      <Route
        path={GameControlsRoute.toUrl()}
        element={<ScreenRoute path={GameControlsRoute.toUrl()} component={GameControls} />}
      />
      <Route
        path={MarketingRoute.toUrl()}
        element={
          <ScreenRoute
            path={MarketingRoute.toUrl()}
            component={MarketingConsole}
            togglePanel={togglePanel}
            panelOpen={panelOpen}
          />
        }
      />
      <Route
        path={FirewallRoute.toUrl()}
        element={
          <ScreenRoute
            path={FirewallRoute.toUrl()}
            component={FirewallConsole}
            togglePanel={togglePanel}
            panelOpen={panelOpen}
          />
        }
      />
      <Route path={SalesRoute.toUrl()} element={<ScreenRoute path={SalesRoute.toUrl()} component={SalesConsole} />} />
      <Route
        path={FinanceRoute.toUrl()}
        element={<ScreenRoute path={FinanceRoute.toUrl()} component={FinanceConsole} />}
      />
      <Route
        path={MDRoute.toUrl()}
        element={
          <ScreenRoute path={MDRoute.toUrl()} component={MDConsole} togglePanel={togglePanel} panelOpen={panelOpen} />
        }
      />
      <Route
        path={ProductionRoute.toUrl()}
        element={
          <ScreenRoute
            path={ProductionRoute.toUrl()}
            component={ProductionConsole}
            togglePanel={togglePanel}
            panelOpen={panelOpen}
          />
        }
      />
      <Route path={ReportsBaseRoute.toUrl()} element={<PrivateRoute></PrivateRoute>}>
        <Route path="*" element={<ReportContainer />} />
      </Route>
      <Route
        path="/"
        element={
          <PrivateRoute path="/">
            <Navigate to={MainScreenRoute.toUrl()} />
          </PrivateRoute>
        }
      />
      <Route path="wiki/:pageId" element={<ConfluencePageWrapper />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
