// import './mainScreen.css'
// import * as CSS from 'csstype'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Moment from "react-moment";
import usePlayerEvents from "../../../../hooks/usePlayerEvents";
import { IScreenProps } from "../../../../types/app.types";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    //display: 'flex',
    overflow: "auto",
    //flexDirection: 'column',
    height: "75vh",
  },
  orderListImg: {
    width: "2.5vh",
    marginTop: "-0.8vh",
  },
  orderListSpan: {
    marginLeft: "1vh",
    fontSize: "2vh",
  },
  fixedHeight: {
    //height: '80vh',
    width: "100%",
  },
  grid: {
    padding: theme.spacing(3),
  },
  newsGrid: {
    // padding: theme.spacing(3),
    height: "85vh",
    maxHeight: "85vh",
    paddingTop: "24px",
  },
  main: {
    // backgroundColor: theme.palette.secondary.main,
  },
  news: {
    // backgroundColor: theme.palette.secondary.light,
    height: "100%",
  },
  padding: {
    maxWidth: "100%",
    height: "100%",
    // padding: theme.spacing(3),
  },
  bankBal: {
    marginBottom: theme.spacing(1),
  },
  fullHeight: {
    height: "100%",
    // backgroundColor: theme.palette.secondary.main,
  },
  verticalDivider: {
    margin: "10px 0 10px -1px",
  },
  noVerticalPadding: {
    padding: "0 12px !important",
    width: "10vw",
  },
  overflow: {
    overflow: "scroll",
    width: "100%",
  },
}));

const PlayerEvents: React.FC<IScreenProps> = ({ gameState, text, referenceData }) => {
  const classes = useStyles();

  const playerEvents = usePlayerEvents();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer className={classes.overflow}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date and Time</TableCell>
            <TableCell>Console</TableCell>
            <TableCell>Round</TableCell>
            <TableCell>Week</TableCell>
            <TableCell colSpan={2}>Submission</TableCell>
            <TableCell>Feedback</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {playerEvents?.playerEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ev) => {
            const date = new Date(ev.DateTime);
            date.toString();

            return (
              <TableRow key={`${ev.GameId}-${ev.DateTime}`}>
                <TableCell>
                  {
                    <Moment format="DD/MM/YY hh:mm:ss" local>
                      {ev.DateTime}
                    </Moment>
                  }
                </TableCell>
                <TableCell>{ev.Console}</TableCell>
                <TableCell>{ev.Round}</TableCell>
                <TableCell>{ev.Week}</TableCell>
                <TableCell colSpan={2}>{ev.Submission}</TableCell>
                <TableCell>{ev.Feedback}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        count={playerEvents?.playerEvents.length ? playerEvents?.playerEvents.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        style={{ overflow: "none" }}
      />
    </TableContainer>
  );
};

export default PlayerEvents;
