import { useContext, useEffect, useState } from "react";
import { getReferenceData } from "../modules/referenceData/service";
import { IReferenceData } from "../types/gameState.types";
import { AppContext } from "../context";

function useReferenceData(): IReferenceData | null {
  const [referenceData, setReferenceData] = useState(null);
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    async function retrieve() {
      const data = await getReferenceData();
      setReferenceData(data);
      dispatch({ type: "SET_REFERENCEDATA", payload: data });
    }

    retrieve();
  }, []);

  return referenceData;
}

export default useReferenceData;
