import { Avatar, CssBaseline, Drawer, Grid, ThemeProvider, StyledEngineProvider, Typography } from "@mui/material";
import { createTheme, Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ContactSupportRounded } from "@mui/icons-material";
import clsx from "clsx";
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Loading from "../components/loading/Loading";
import MessagesModal from "../components/modals/MessagesModal";
import Sidebar from "../components/sidebar/Sidebar";
import useChallengeTime from "../hooks/useChallengeTime";
import useDynamicText from "../hooks/useDynamicText";
import useGameState from "../hooks/useGameState";
import { GameRouter } from "../routing/GameRouter";
import getRole from "../utils/getRole";
import ErrorBoundary from "./ErrorBoundary";
import { AppContext } from "../context";
import useReferenceData from "../hooks/useReferenceData";
import DemoWelcomeTour from "../components/tour/DemoWelcomeTour";
import DemoWrapUpTour from "../components/tour/DemoWrapUpTour";
import config from "../components/chatBot/config";
import ActionProvider from "../components/chatBot/ActionProvider";
import MessageParser from "../components/chatBot/MessageParser";
import ChatBot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import Eula from "../components/eula/Eula";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

interface StyleProps {
  drawerColour: string;
}

// const Tour = dynamic(
//     () => import('../tours/DemoWelcomeTour'),
//     { ssr: false }
//   )

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  container: {
    display: "flex",
    flex: 1,
    //float: 'left',
    background: "#fafafa",
  },
  main: {
    //maxHeight: "85vh",
    flex: 1,
  },
  drawer: {
    background: ({ drawerColour }) => drawerColour,
    position: "static",
    minHeight: "92vh",
    zIndex: -1,
  },
  closed: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
    overflowX: "hidden",
  },
  opened: {
    width: "250px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  header: {
    minHeight: "8vh",
    //backgroundColor: theme.palette.secondary.light,
    //color: theme.palette.primary.light
  },
  side: {
    background: "#D8DCD6",
    minHeight: "92vh",
  },
  gameCont: {
    //background: "#D8DCD6",
    minHeight: "92vh",
  },
  arrowOpen: {
    position: "absolute",
    left: "230px",
    paddingLeft: "10px",
    bottom: 40,
    //paddingTop: '10px',
    //marginRight: -theme.spacing(2),
    zIndex: 1400,
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  arrowClosed: {
    position: "absolute",
    left: "35px",
    bottom: 40,
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //paddingTop: '10px',
    //marginRight: -theme.spacing(2),
    zIndex: 1400,
  },
  arrowInner: {},
}));

const GameContainer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [textSidebar, setTextSidebar] = useState(false);
  const [panelOpen, setPanelOpen] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const [showChatbot, toggleChatbot] = useState(false);
  const [runWelcomeTour, setRunWelcomeTour] = useState(true);
  const [runWrapUpTour, setRunWrapUpTour] = useState(false);
  const [meh, setMeh] = useState(false);

  const restartWelcomeTour = () => {
    setRunWelcomeTour(true);
  };
  const restartWrapUpTour = () => {
    setRunWrapUpTour(true);
  };

  const text = useDynamicText();
  //const documents = useHandbookDoc()
  const challengeTime = useChallengeTime();
  const gameState = useGameState(1000);
  const referenceData = useReferenceData();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const rebranded = state.gameState.Investments?.find((i) => i.InvestmentType == "Rebrand")?.isActive;
  const sidebarBackgroundColour = rebranded
    ? referenceData?.Themes?.rebrand.sidebarBackgroundColour
    : referenceData?.Themes?.standard.sidebarBackgroundColour;

  const classes = useStyles({
    drawerColour: sidebarBackgroundColour ?? "#D8DCD6",
  });
  const { pathname } = useLocation();

  const toggleSidebarExpanded = async () => {
    if (sidebarExpanded) {
      setSidebarExpanded(!sidebarExpanded);
      setTextSidebar(!textSidebar);
    } else {
      setSidebarExpanded(!sidebarExpanded);
      await timeout(200);
      setTextSidebar(!textSidebar);
    }
  };

  function handleAcceptEULA(val: boolean) {
    dispatch({
      type: "SET_EULA_ACCEPTED",
      payload: val,
    });
    localStorage.setItem("eulaAccepted", "true");
  }

  useEffect(() => {
    const isEulaAccepted = localStorage.getItem("eulaAccepted");

    if (isEulaAccepted === "true") {
      handleAcceptEULA(true);
    }
  }, []);

  useEffect(() => {
    const handleChatbotEvent = (event: any) => {
      switch (event.detail.message) {
        case "RunWelcomeTour":
          restartWelcomeTour();
          break;
        case "RunWrapUpTour":
          restartWrapUpTour();
          break;
        default:
          console.log("No tour found for this event message");
          break;
      }

      toggleChatbot(false);
    };

    window.addEventListener("chatbotEvent", handleChatbotEvent);

    return () => window.removeEventListener("chatbotEvent", handleChatbotEvent);
  }, []);

  useEffect(() => {
    setSidebarExpanded(isLargeScreen);
    setTextSidebar(isLargeScreen);
    dispatch({ type: "SET_ISLARGESCREEN", payload: isLargeScreen });
  }, [isLargeScreen]);

  if (!text || !(state.gameState.NumberOfRounds > 0)) {
    return <Loading />;
  } else if (!state.eulaAccepted) {
    return <Eula handleAcceptEULA={handleAcceptEULA} />;
  }

  const togglePanel = () => {
    setPanelOpen(!panelOpen);
  };

  const role = getRole(text.roleSelection, location.pathname);

  return (
    <ErrorBoundary>
      <CssBaseline />
      {gameState?.AutoFac && <DemoWelcomeTour run={runWelcomeTour} setRun={setRunWelcomeTour} text={text.wrapper} />}
      {gameState?.AutoFac && (
        <DemoWrapUpTour run={runWrapUpTour} setRun={setMeh} text={text.wrapper} gameState={gameState} />
      )}
      <Grid container>
        <Grid item xs={12} className={clsx(classes.header, "tourMain")}>
          <Header
            toggleExpandSidebar={toggleSidebarExpanded}
            sidebarExpanded={sidebarExpanded}
            isLargeScreen={isLargeScreen}
            role={role}
            currentPage={"main"}
            challengeTime={Number(challengeTime)}
            text={text.wrapper}
            gameState={state.gameState}
          />
        </Grid>
        <Grid item xs={12} className={classes.container}>
          {pathname != "/game/firewall" && (
            <Drawer
              variant={isLargeScreen ? "permanent" : "temporary"}
              open={!isLargeScreen && sidebarExpanded ? true : false}
              onClose={() => toggleSidebarExpanded()}
              classes={{
                paper: clsx(
                  classes.drawer,
                  {
                    [classes.closed]: !sidebarExpanded,
                    [classes.opened]: sidebarExpanded,
                  },
                  "tourDrawer"
                ),
              }}
            >
              <Sidebar
                role={role}
                text={text}
                sidebarExpanded={sidebarExpanded}
                textSidebar={textSidebar}
                togglePanel={togglePanel}
                toggleSidebarExpanded={toggleSidebarExpanded}
                gameState={state.gameState}
              />
            </Drawer>
          )}

          <MessagesModal gameState={state.gameState} />
          <div id="mainContainer">
            <GameRouter togglePanel={togglePanel} panelOpen={panelOpen} sidebarExpanded={sidebarExpanded} />
          </div>
        </Grid>
      </Grid>
      <div>
        {gameState?.AutoFac && (
          <ContactSupportRounded
            style={{
              position: "fixed",
              bottom: "20px",
              left: "20px",
              cursor: "pointer",
              width: "50px",
              height: "50px",
              color: theme.palette.primary.main,
            }}
            onClick={() => toggleChatbot((prevState) => !prevState)}
            className="tourHelp"
          />
        )}
        {pathname != "/game/firewall" && showChatbot && (
          <div
            style={{
              position: "fixed",
              bottom: "100px",
              left: "20px",
              zIndex: 3,
              // boxShadow: "1px 1px 14px 0px rgba(0,0,0,0.75)",
            }}
          >
            <ChatBot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default GameContainer;
