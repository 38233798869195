import makeStyles from "@mui/styles/makeStyles";

const appStyles = makeStyles((theme) => ({
  panelHeader: {
    background: theme.custom.panelHeader,
    boxShadow: "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%)",
    borderRadius: "3px",
    width: "auto",
    margin: "-26px 15px 0 15px",
    color: "white",
    textAlign: "center",
  },
  panelHeaderX: {
    marginTop: "-20px",
    textAlign: "center",
  },
  panelTitle: {
    fontSize: "1.1em",
  },
  feedback: {
    width: "100%",
    paddingLeft: theme.spacing(5),
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    color: theme.palette.warning.main,
  },
}));

export default appStyles;
