import React, { useState, memo } from "react";
import GaugeChart from "react-gauge-chart";
import { IGameState, ICo2ReportViewModel } from "../../types/gameState.types";
import { ITextSet } from "../../types/text.types";
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  GridSize,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import StatBox from "../statBox/StatBox";
import { games } from "googleapis/build/src/apis/games";
import AccessibleTooltip from "../accessibleTooltip/AccessibleTooltip";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: theme.spacing(0.4),
    borderBottom: "none",
    paddingLeft: theme.spacing(1),
  },
  numberCell: {
    paddingRight: theme.spacing(1),
    textAlign: "right",
  },
  head: {
    padding: theme.spacing(0.1),
    paddingLeft: theme.spacing(1),
    textAlign: "center",
  },
  tableBody: {
    overflowY: "auto",
    // backgroundColor: theme.palette.secondary.light
  },
  table: {
    height: "80vh",
    //overflowY: 'auto',
    padding: theme.spacing(3),
  },
  padding: {
    paddingLeft: theme.spacing(1),
  },
  section: {
    backgroundColor: "white",
    height: "3vh",
  },
  gap: {
    padding: theme.spacing(1),
  },
  subheader: {
    minWidth: "10%",
  },
  paper: {
    // margin: "10px",
    padding: "10px",
    backgroundColor: "white",
    // display: 'flex',
    // textAlign: 'center',
  },
  co2: {
    // height: '10vh',
    // width: '10vh',
    // display: 'flex',
    // flex: 1,
  },
}));

const getDiff = (value: number, oldValue: number) => {
  return value / oldValue;
};

// const getReduction = (value: number, oldValue: number) => {
//     let toReturn = value > oldValue ? "+" + (((value - oldValue) / oldValue) * 100).toFixed(2).replace(/[.,]0$/, "") : -(((oldValue - value) / oldValue) * 100).toFixed(2).replace(/[.,]0$/, "");
//     return toReturn + "%";
// }

const getReduction = (value: number, oldValue: number) => {
  return -1 * Math.round(((value - oldValue) * 100) / oldValue);
};

interface Props {
  text?: ITextSet;
  itemSize: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  currIndirectPerWeek: number;
  currDirectPerWeek: number;
  standardDirectPerUnit: number;
  standardOpsCostPerWeek: number;
  legend?: boolean;
}

const Co2Stats: React.FC<Props> = (props) => {
  const {
    text,
    itemSize,
    currIndirectPerWeek,
    currDirectPerWeek,
    standardDirectPerUnit,
    standardOpsCostPerWeek,
    legend,
  } = props;
  const classes = useStyles();
  const numberCell = clsx(classes.tableCell, classes.numberCell);
  const subHead = clsx(classes.tableCell, classes.subheader);
  const legendSize = isNaN(Number(itemSize))
    ? 12
    : Number(itemSize) == 3
    ? 12
    : Number(itemSize) >= 12
    ? 12
    : Number(itemSize) * 2;

  // gameState.CO2DirectPerUnitPerWeek, gameState.Co2Reports[0].Co2SalesCostPerUnit
  //gameState.CO2IndirectPerWeek, gameState.Co2Reports[0].Co2OperationalCostPerWeek
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item md={itemSize} sm={6} xs={12}>
          <Typography component="span" variant="subtitle2">
            <AccessibleTooltip title="Reduction of Sales Emissions Per Unit (tCO2e / Unit) vs the Standard Period">
              <span tabIndex={0}>SALES CO2 REDUCTION</span>
            </AccessibleTooltip>
            <br />
          </Typography>
          <Typography component="span" variant="subtitle2">
            <span>S: {standardDirectPerUnit}</span> /
            <span> C: {currDirectPerWeek.toFixed(2).replace(/[.,]0$/, "")}</span> /
            <span>
              {" "}
              R:&nbsp;
              {getReduction(currDirectPerWeek, standardDirectPerUnit).toString()}%
            </span>
            <br />
          </Typography>
        </Grid>
        <Grid
          item
          md={itemSize}
          sm={6}
          xs={12}
          role="img"
          title={`Sales Reduction: ${getReduction(currDirectPerWeek, standardDirectPerUnit)}%. Target: 45% - 100%`}
        >
          <GaugeChart
            id="gauge-chart-sales"
            textColor="#000"
            nrOfLevels={3}
            arcsLength={[0.2, 0.25, 0.65]}
            colors={["#EA4228", "#F5CD19", "#5BE12C"]}
            percent={getReduction(currDirectPerWeek, standardDirectPerUnit) / 100}
            arcPadding={0.02}
            animate={false}
          />
        </Grid>
        <Grid item md={itemSize} sm={6} xs={12}>
          <Typography component="span" variant="subtitle2">
            <AccessibleTooltip title=" Reduction of Operational Emissions Per Week (tCO2e / week) vs the Standard Period">
              <span tabIndex={0}>OPS CO2 REDUCTION</span>
            </AccessibleTooltip>
            <br />
          </Typography>
          <Typography component="span" variant="subtitle2">
            <span>S: {standardOpsCostPerWeek}</span> /
            <span> C: {currIndirectPerWeek.toFixed(2).replace(/[.,]0$/, "")}</span> /
            <span>
              {" "}
              R:&nbsp;
              {getReduction(currIndirectPerWeek, standardOpsCostPerWeek).toString()}%
            </span>
            <br />
          </Typography>
        </Grid>
        <Grid
          item
          md={itemSize}
          sm={6}
          xs={12}
          role="img"
          title={`Ops Reduction: ${getReduction(currIndirectPerWeek, standardOpsCostPerWeek)}%. Target: 5% - 100%`}
        >
          <GaugeChart
            id="gauge-chart-ops"
            textColor="#000"
            nrOfLevels={2}
            arcsLength={[0.05, 0.95]}
            colors={["#F5CD19", "#5BE12C"]}
            percent={getReduction(currIndirectPerWeek, standardOpsCostPerWeek) / 100}
            arcPadding={0.02}
            animate={false}
          />
        </Grid>
        <Grid item xs={legendSize as GridSize}>
          <Typography component="p" variant="subtitle2">
            S:&nbsp;Standard&nbsp;Period C:&nbsp;Current&nbsp;Period R:&nbsp;Reduction
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default memo(Co2Stats);
