import React from "react";
import { sendChatMessage } from "../../modules/gameState/service";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleHello = () => {
    const message = createChatBotMessage("Hello. Nice to meet you.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };
  const handleWelcomeTourRequest = () => {
    const message = createChatBotMessage("Do you want to see the welcome tour again?", {
      widget: "restartTour",
      withAvatar: true,
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleWelcomeTourAction = () => {
    const message = createChatBotMessage("No problem, here's the tour again.", {});

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));

    const event = new CustomEvent("chatbotEvent", {
      detail: {
        message: "RunWelcomeTour",
      },
    });
    window.dispatchEvent(event);
  };

  const handleWrapUpTourAction = () => {
    const message = createChatBotMessage("Here's the wrap up tour.", {});

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));

    const event = new CustomEvent("chatbotEvent", {
      detail: {
        message: "RunWrapUpTour",
      },
    });
    window.dispatchEvent(event);
  };

  const handleMessageParserDocs = () => {
    const message = createChatBotMessage(
      "The message parser controls how the bot reads input and decides which action to invoke.",
      { widget: "messageParser", withAvatar: true }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleActionProviderDocs = () => {
    const message = [
      this.createChatBotMessage("The action provider defines the bots response after the message is parsed.", {
        widget: "actionProviderDocs",
        withAvatar: true,
      }),
    ];

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleConfigDocs = () => {
    const message = createChatBotMessage("The config controls every configurable aspect of the chatbot.", {
      widget: "config",
      withAvatar: true,
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleWidgetDocs = () => {
    const message = createChatBotMessage(
      "Widgets are custom components that you want to render with a chatbot response.",
      { widget: "widget", withAvatar: true }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleFinanceDocs = () => {
    const message = createChatBotMessage(
      "Finance has the toughest role in the simulations. At least that's what our FD says...",
      { widget: "finance", withAvatar: true }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleSimpleMessages = (response) => {
    const message = createChatBotMessage(response, {});

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  // const handleDefault = () => {
  //   const message = createChatBotMessage("How can I help? ", {});

  //   setState((prev) => ({
  //     ...prev,
  //     messages: [...prev.messages, message],
  //   }));
  // };

  const handleDefault = async (userInput) => {
    try {
      // const response = await fetch("/api/path-to-your-function", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ message: userInput }),
      // });
      // if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }
      const data = await sendChatMessage(userInput);
      const message = createChatBotMessage(data.data.content[0].text.value);
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    } catch (error) {
      console.error("Failed to send message to backend:", error);
      // Handle error or fallback scenario
    }
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleWelcomeTourRequest,
            handleWelcomeTourAction,
            handleWrapUpTourAction,
            handleMessageParserDocs,
            handleActionProviderDocs,
            handleConfigDocs,
            handleWidgetDocs,
            handleFinanceDocs,
            handleSimpleMessages,
            handleDefault,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
