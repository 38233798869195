import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { AppRouter } from "./routing/AppRouter";
import { AppProvider } from "./context";

const App: React.FC = () => {
  useEffect(() => {
    const isReferredFromGGT = localStorage.getItem("eulaAccepted");

    if (isReferredFromGGT !== "true") {
      if (document.referrer.includes("go.gingr.tech")) {
        localStorage.setItem("eulaAccepted", "true");
      } else {
        localStorage.setItem("eulaAccepted", "false");
      }
    }
  }, []);

  return (
    <AppProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
