import { Avatar, CssBaseline, Drawer, Grid, ThemeProvider, StyledEngineProvider, Typography } from "@mui/material";
import { createTheme, Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import { AppContext } from "../context";
import useGameState from "../hooks/useGameState";
import useReferenceData from "../hooks/useReferenceData";
import GlobalTheme from "../components/themes/GlobalTheme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  children: React.ReactNode;
}

const ThemeContainer: React.FC<Props> = ({ children }) => {
  const { state, dispatch } = useContext(AppContext);
  const referenceData = useReferenceData();
  const theme = useTheme();

  const rebranded = state.gameState.Investments?.find((i) => i.InvestmentType == "Rebrand")?.isActive;

  const standardTheme = createTheme({
    typography: {
      fontFamily: referenceData?.Themes?.standard?.fontFace ?? theme.typography.fontFamily,
    },
    palette: {
      primary: {
        main: referenceData?.Themes?.standard?.primaryTextColour ?? theme.palette.primary.main,
      },
      secondary: {
        main: referenceData?.Themes?.standard?.secondaryTextColour ?? theme.palette.secondary.main,
      },
      background: {
        // default: referenceData?.Themes.standard.headerBackgroundColour ?? "#555",
      },
    },
    custom: {
      panelHeader: referenceData?.Themes?.standard.panelHeader ?? theme.custom?.panelHeader ?? "#555",
      mainHeader: referenceData?.Themes?.standard.mainHeader ?? theme.custom?.mainHeader ?? "#555",
      headerLogoUrl: referenceData?.Themes?.standard.headerLogo ?? theme.custom?.headerLogoUrl ?? "#555",
    },
  });

  const rebrandTheme = createTheme({
    typography: {
      fontFamily: referenceData?.Themes?.rebrand?.fontFace ?? theme.typography.fontFamily,
    },
    palette: {
      primary: {
        main: referenceData?.Themes?.rebrand?.primaryTextColour ?? theme.palette.primary.main,
      },
      secondary: {
        main: referenceData?.Themes?.rebrand?.secondaryTextColour ?? theme.palette.secondary.main,
      },
      background: {
        // default: referenceData?.Themes.rebrand.headerBackgroundColour ?? "#555",
      },
    },
    custom: {
      panelHeader: referenceData?.Themes?.rebrand?.panelHeader ?? theme.custom?.panelHeader ?? "#555",
      mainHeader: referenceData?.Themes?.rebrand?.mainHeader ?? theme.custom?.mainHeader ?? "#555",
      headerLogoUrl: referenceData?.Themes?.rebrand?.headerLogo ?? theme.custom?.headerLogoUrl ?? "#555",
    },
  });

  const mergedTheme = rebranded ? createTheme(GlobalTheme, rebrandTheme) : createTheme(GlobalTheme, standardTheme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeContainer;
