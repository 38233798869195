import { RoleIDs, RouteWrapper } from "./routeWrapper";

export const RoleSelectionPageRoute = new RouteWrapper("/");
export const LoginSelectionPageRoute = new RouteWrapper("/login");
export const PlayerLoginPageRoute = new RouteWrapper("/playerLogin");
export const FirewallLoginRoute = new RouteWrapper("/firewallLogin");
export const FirewallDocsRoute = new RouteWrapper("/firewalldoc");
export const FirewallYumpuRoute = new RouteWrapper("/firewalyumpu");
export const FirewallAzureDataTestRoute = new RouteWrapper("/firewallDataTest");
export const FirewallDoneRoute = new RouteWrapper("/firewallDone");

export const GameBaseRoute = new RouteWrapper("/game/*");
export const MainScreenRoute = new RouteWrapper("/main");
export const GameControlsRoute = new RouteWrapper("/controls");
export const GameDocsRoute = new RouteWrapper("/game/docs");

export const MarketingRoute = new RouteWrapper("/marketing", RoleIDs.Marketing);
export const FirewallRoute = new RouteWrapper("/firewall");
export const SalesRoute = new RouteWrapper("/sales", RoleIDs.Sales);
export const FinanceRoute = new RouteWrapper("/finance", RoleIDs.Finance);
export const MDRoute = new RouteWrapper("/ceo", RoleIDs.MD);
export const VideoRoute = new RouteWrapper("/videos", RoleIDs.Facilitator);
export const EtherpadOTSRoute = new RouteWrapper("/ots", RoleIDs.Facilitator);
export const EtherpadOPSRoute = new RouteWrapper("/ops", RoleIDs.Facilitator);
export const HandbookRoute = new RouteWrapper("/handbook", RoleIDs.Facilitator);

export const BankBalanceTableRoute = new RouteWrapper("/banktable", RoleIDs.Facilitator);
export const BankBalanceCO2TableRoute = new RouteWrapper("/bankco2table", RoleIDs.Facilitator);

export const ProductionRoute = new RouteWrapper("/production", RoleIDs.Production);
export const MarketCampaignRoute = new RouteWrapper("marketcampaign", RoleIDs.Marketing);

export const ReportsBaseRoute = new RouteWrapper("/reports/*");
export const TradingReportRoute = new RouteWrapper("/trading");
export const CashReportRoute = new RouteWrapper("/cash");
export const Co2ReportRoute = new RouteWrapper("/co2");
export const ProductionReportRoute = new RouteWrapper("/production");
export const LeadToCashReportRoute = new RouteWrapper("/lead_cash");
export const FinalReportRoute = new RouteWrapper("/final");

export const AllRoutes = [
  RoleSelectionPageRoute,
  LoginSelectionPageRoute,
  PlayerLoginPageRoute,
  GameBaseRoute,
  MainScreenRoute,
  GameControlsRoute,
  MarketingRoute,
  SalesRoute,
  MDRoute,
  ProductionRoute,
  FinanceRoute,
  ReportsBaseRoute,
  TradingReportRoute,
  CashReportRoute,
  Co2ReportRoute,
  ProductionReportRoute,
  LeadToCashReportRoute,
  MarketingRoute,
  GameDocsRoute,
  FirewallDocsRoute,
  FirewallYumpuRoute,
  FirewallAzureDataTestRoute,
  FirewallLoginRoute,
  VideoRoute,
  EtherpadOTSRoute,
  EtherpadOPSRoute,
  HandbookRoute,
  FirewallDoneRoute,
  FirewallRoute,
  BankBalanceCO2TableRoute,
  BankBalanceTableRoute,
  FinalReportRoute,
];
