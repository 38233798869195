import React from "react";
import Options from "../Options/Options";

const GeneralOptions = (props) => {
  const options = [
    {
      name: "restart tour",
      handler: props.actionProvider.handleWelcomeTourAction,
      id: 1,
    },
    {
      name: "finance",
      handler: props.actionProvider.handleFinanceDocs,
      id: 2,
    },
    {
      name: "wrapup tour",
      handler: props.actionProvider.handleWrapUpTourAction,
      id: 3,
    },
  ];

  return <Options options={options} />;
};

export default GeneralOptions;
