import { Theme, Tabs, Tab } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import React from "react";
interface StyledTabProps {
  label: React.ReactNode;
  disabled?: boolean;
  value?: any;
  tabIndex?: number;
}
interface StyledTabsProps {
  value: any;
  variant?: "fullWidth" | "scrollable" | "standard";
  onChange: (event: React.ChangeEvent<object>, newValue: number) => void;
  children: React.ReactNode;
}

export const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "8px",
      color: "black",
    },
    indicator: {
      height: 7,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  })
)((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
// selectionFollowsFocus means that

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 0,
      width: "20%",
      // fontWeight: theme.typography.fontWeightRegular,
      // TODO: package upgrade
      [theme.breakpoints.up("md")]: {
        minWidth: 0,
      },
    },
    wrapper: {
      fontWeight: "bold",
      letterSpacing: 0.5,
    },
    disabled: {
      backgroundColor: "#fff",
      color: "gray",
    },
    selected: {
      fontWeight: 1000,
      color: theme.palette.primary.main,
    },
    focused: {
      fontWeight: 2000,
      color: "green",
      backgroundColor: "black",
    },
    "&.MuiTab-focusVisible": {
      color: "red",
      backgroundColor: "blue",
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} tabIndex={0} />);
