import {
  Button,
  Grid,
  Paper,
  Select,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import StatBox from "../../../../components/statBox/StatBox";
import {
  depositCash,
  repayInvestment,
  recordFormSubmission,
  chargeConsultancyFee,
} from "../../../../modules/gameState/service";
import { IGameState } from "../../../../types/gameState.types";
import { IFacilitatorConsoleText } from "../../../../types/text.types";
import controlsTheme from "./controlsTheme";
import { IPlayerEvent } from "../../../../types/playerEvents.types";
import clsx from "clsx";
import appStyles from "../../../../assets/appStyles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  gameState: IGameState;
  text: IFacilitatorConsoleText;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  container: {
    //display: 'flex',
    //justifyContent: 'space-between',
    padding: "10px 10px",
    // height: '20vh'
  },
  cheating: {
    height: "100%",
    padding: "1px 40px 10px 40px",
  },
}));

export const CacConsultancy: React.FC<Props> = ({ gameState, text }) => {
  const [valueToDeposit, setValueToDeposit] = useState("");
  const [valueToRepay, setValueToRepay] = useState("10000");
  const [cashFeedback, setCashFeedback] = useState(<Typography></Typography>);

  const repay = async () => {
    const newDate: Date = new Date();
    const form: IPlayerEvent = {
      id: localStorage.getItem("guid") + newDate.toString(),
      GameId: localStorage.getItem("guid"),
      Console: "Facilitator Controls",
      Round: gameState.Round.toString(),
      Week: gameState.Week.toString(),
      Submission: "Consultancy CAC Fee: " + valueToDeposit.toString(),
      DateTime: newDate,
      Feedback: "",
    };

    try {
      await chargeConsultancyFee(valueToRepay);
      form.Feedback = `${parseInt(valueToRepay)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${text.capexCharged}.`;
      recordFormSubmission(form);
      setCashFeedback(
        <Typography>
          $
          {`${parseInt(valueToRepay)
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} ${text.capexCharged}.`}
        </Typography>
      );
      setValueToDeposit("");
      setValueToRepay("");
    } catch {
      form.Feedback = "Error repaying investment.";
      recordFormSubmission(form);
      setCashFeedback(<Typography color="error">{"Error repaying investment."}</Typography>);
      setValueToDeposit("");
      setValueToRepay("");
    }
  };

  const classes = useStyles();
  const appClasses = appStyles();

  const cancelCashAction = () => {
    setValueToRepay("");
    setValueToDeposit("");
  };

  const handleChange = (event: any) => {
    setValueToRepay(event.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormControl style={{ minWidth: 220 }}>
          <InputLabel id="lblCac">{text.cacConsultancyFeeDesc}</InputLabel>
          <Select
            // defaultValue={10000}
            label={"Select Amount to Charge"}
            aria-label={"Amount to Charge"}
            onChange={handleChange}
            value={valueToRepay}
            name="Amount"
            labelId="lblCac"
          >
            <MenuItem value={10000}>$10,000</MenuItem>
            <MenuItem value={15000}>$15,000</MenuItem>
            <MenuItem value={20000}>$20,000</MenuItem>
            <MenuItem value={25000}>$25,000</MenuItem>
            <MenuItem value={30000}>$30,000</MenuItem>
          </Select>
        </FormControl>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={controlsTheme}>
            <Button
              className={classes.button}
              onClick={repay}
              variant="contained"
              color="secondary"
              disabled={!valueToRepay}
              name={text.charge}
            >
              <Typography component="div" variant="subtitle2">
                {text.charge}
              </Typography>
            </Button>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
      <Grid item xs={6}>
        <p>
          <strong>{text.feedback}</strong>
        </p>
        <div aria-live="polite">{cashFeedback}</div>
      </Grid>
    </Grid>
  );
};
