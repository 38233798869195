import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { checkGUID } from "../../modules/auth/service";
import Logo from "../../assets/poweredByGingrTech.png";
import "./Initials.css";
import { Grid, Box, Button, Paper } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  cont: {
    marginTop: "15vh",
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0),
  },
  btnRight: {
    marginLeft: theme.spacing(2),
  },
  btnLeft: {},
}));

const PlayerLoginPage: React.FC = () => {
  const [guid, setGuid] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ btnReady: true });

  const classes = useStyles();

  localStorage.removeItem("guid");
  localStorage.removeItem("dataset");

  const authenticate = async () => {
    if (guid === "TEST".toLowerCase()) {
      try {
        const dataset = await checkGUID("rkt-rc-101627");

        localStorage.setItem("guid", "rkt-rc-101627");
        localStorage.setItem("dataset", dataset);

        navigate("/firewalldoc");
      } catch {
        // TODO show failure
      }
    } else {
      try {
        const dataset = await checkGUID(guid);

        localStorage.setItem("guid", guid);
        localStorage.setItem("dataset", dataset);
        localStorage.setItem("authFac", "");

        navigate("/game/main");
      } catch {
        // TODO show failure
      }
    }
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      authenticate();
    }
  };

  return (
    <Grid container>
      <Grid container direction="row" className={classes.cont}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <img src={Logo} alt="The Rocket Challenge" />
            <h2>Please Enter Your Unique Game Code</h2>
            <input
              className="guidInput"
              id="guidInput"
              type="text"
              value={guid}
              autoFocus
              onKeyDown={handleEnterPress}
              onChange={(e) => setGuid(e.target.value)}
            />
            <h4>
              By playing ROCKET you accept our{" "}
              <a href="https://shop.gwof.uk/general-terms-and-conditions/#eula">EULA Agreement</a>, please confirm by
              clicking &quot;I ACCEPT&quot; below:
            </h4>
            <Button variant="contained" className={classes.btnLeft} onClick={() => setState({ btnReady: false })}>
              I ACCEPT
            </Button>

            <Button variant="contained" className={classes.btnRight} disabled={state.btnReady} onClick={authenticate}>
              LOGIN
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Grid>
  );
};

export default PlayerLoginPage;
