import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import formatAsGBP from "../../utils/formatAsGBP";
import { Col, Row } from "react-bootstrap";
import { IGameState } from "../../types/gameState.types";
import { Grid, Paper, Typography, Theme, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import StatBox from "../statBox/StatBox";

import "./bankBalanceChart.css";
import formatAsUSD from "../../utils/formatAsUSD";

Chart.register(...registerables);

const chartOptions = {
  plugins: {
    legend: {
      display: true,
    },
  },
  animation: {
    duration: 5,
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      id: "y-axis-bank",
      suggestedMin: -50000,
      suggestedMax: 150000,
      ticks: {
        callback: (value: any) => {
          if (parseInt(value) >= 1000) {
            return formatAsUSD(value / 1000) + "k";
          } else if (parseInt(value) <= -1000) {
            return "-" + formatAsUSD(Math.abs(value / 1000)) + "k";
          } else {
            return formatAsUSD(value);
          }
        },
      },
    },
  },
};

const chartData = (data: number[], labels: string[]) => {
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: "rgba(144, 238, 144, 0.4)",
        lineTension: 0,
        spanGaps: false,
        fill: true,
        label: "Bank Balance",
        id: "y",
      },
    ],
  };
};

interface IRequiredText {
  current: string;
  highest: string;
  lowest: string;
  bankBalance?: string;
}

interface Props {
  gameState: IGameState;
  text: IRequiredText;
  data?: number[];
  dataCO2?: number[];
  mode?: "full" | "chart" | "values";
  height: number;
  currencyParser: Intl.NumberFormat;
}

const BankBalanceChart: React.FC<Props> = ({ gameState, text, mode = "full", data, height }) => {
  data = data || gameState.BankValueList;

  const canvasHeight = height - 8 + "vh";

  const useStyles = makeStyles((theme: Theme) => ({
    chart: {
      // height: height+'vh'
    },
    canvas: {
      // height: canvasHeight
    },
  }));

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {["full", "values"].includes(mode) && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <StatBox value={formatAsUSD(gameState.BankMin)} title={text.lowest} icon="inline"></StatBox>
          </Grid>
          <Grid item xs={4}>
            <StatBox value={formatAsUSD(gameState.BankCurrent)} title={text.current}></StatBox>
          </Grid>
          <Grid item xs={4}>
            <StatBox value={formatAsUSD(gameState.BankMax)} title={text.highest}></StatBox>
          </Grid>
        </Grid>
      )}
      {["full", "chart"].includes(mode) && (
        <Grid item xs={12}>
          <Paper className={classes.chart}>
            <Typography component={"p"}>
              This chart displays the bank balance emitted over the current round.{" "}
              <Link href="/game/banktable" underline="hover">
                View As Table
              </Link>
            </Typography>
            <Line data={chartData(data, gameState.BankLabelList)} options={chartOptions} />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default BankBalanceChart;
