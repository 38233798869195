import React, { useEffect, useReducer, useState } from "react";
import { Modal } from "react-bootstrap";

import "./modals.css";
import useChallengeTime from "../../hooks/useChallengeTime";
import { IGameState } from "../../types/gameState.types";
import VideoBox from "../VideoBox/VideoBox";

type Message = {
  id: number;
  text: string;
  image: string;
  start: number;
  end: number;
  seen: boolean;
  inPlay: boolean;
};

type MessageAction = {
  type: string;
  id: number;
};

const initialState: Message[] = [
  {
    id: 1,
    text: "Please go and Watch the Introductory Video",
    image: "",
    start: 1 * 60,
    end: 2 * 120,
    seen: false,
    inPlay: false,
  },
  {
    id: 2,
    text: "30 minutes to go until the Practice Level",
    image: "",
    start: 10 * 60,
    end: 11 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 3,
    text: "15 minutes to go until the Practice Level",
    image: "",
    start: 25 * 60,
    end: 26 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 4,
    text: "5 minutes to go until the Practice Level",
    image: "",
    start: 35 * 60,
    end: 36 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 5,
    text: "1 minute to go - Good Luck!",
    image: "",
    start: 39 * 60,
    end: 40 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 6,
    text: "YOUR PRACTICE LEVEL HAS STARTED",
    image: "",
    start: 40 * 60,
    end: 41 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 7,
    text: "HELP: Is someone keeping an eye on the news?",
    image: "",
    start: 52 * 60,
    end: 53 * 60,
    seen: false,
    inPlay: true,
  },
  {
    id: 8,
    text: "NOTE: Please go and watch the 'Important Information for Level A' video",
    image: "https://drive.google.com/file/d/174HaMeG0-SQ-J0Jxx2eaq5NcarnzzQUb/view",
    start: 71 * 60,
    end: 72 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 9,
    text: "1 Hour to go until LEVEL A starts",
    image: "",
    start: 81 * 60,
    end: 82 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 10,
    text: "30 minutes to go until LEVEL A starts",
    image: "",
    start: 111 * 60,
    end: 112 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 11,
    text: "15 minutes to go until LEVEL A starts",
    image: "",
    start: 126 * 60,
    end: 127 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 12,
    text: "5 minutes to go until LEVEL A starts",
    image: "",
    start: 136 * 60,
    end: 137 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 13,
    text: "1 minute to go - Good luck!",
    image: "",
    start: 140 * 60,
    end: 141 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 14,
    text: "LEVEL A HAS STARTED",
    image: "",
    start: 141 * 60,
    end: 142 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 15,
    text: "HELP: Is someone keeping an eye on the news?",
    image: "",
    start: 153 * 60,
    end: 154 * 60,
    seen: false,
    inPlay: true,
  },
  {
    id: 16,
    text: "HELP: Any deals that have finished production and have been invoiced will be credited to your account at the end of the level",
    image: "",
    start: 161 * 60,
    end: 162 * 60,
    seen: false,
    inPlay: true,
  },
  {
    id: 17,
    text: "NOTE: Please go and watch the 'Important Information for Level B' video",
    image: "https://drive.google.com/file/d/1TTlzxXO-Qlo6a8rjA5iffuNpsBaf09Dp/view",
    start: 172 * 60,
    end: 173 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 18,
    text: "1 Hour to go until LEVEL B starts",
    image: "",
    start: 182 * 60,
    end: 183 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 19,
    text: "30 minutes to go until LEVEL B starts",
    image: "",
    start: 212 * 60,
    end: 213 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 20,
    text: "15 minutes to go until LEVEL B starts",
    image: "",
    start: 227 * 60,
    end: 228 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 21,
    text: "5 minutes to go until LEVEL B starts",
    image: "",
    start: 237 * 60,
    end: 238 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 22,
    text: "1 minute to go - Good luck!",
    image: "",
    start: 241 * 60,
    end: 242 * 60,
    seen: false,
    inPlay: false,
  },
  {
    id: 23,
    text: "HELP: Any deals that have finished production and have been invoiced will be credited to your account at the end of the level",
    image: "",
    start: 262 * 60,
    end: 262 * 60,
    seen: false,
    inPlay: true,
  },
];

function reducer(messages: Message[], action: MessageAction): Message[] {
  switch (action.type) {
    case "display":
      return [...messages].map((message) => (message.id === action.id ? { ...message, seen: true } : message));
    default:
      throw new Error();
  }
}

interface Props {
  gameState: IGameState;
}

const MessagesModal: React.FC<Props> = ({ gameState }) => {
  const [show, setShow] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [image, setImage] = useState("");

  const challengeTime = Math.floor(Number(useChallengeTime()));
  const handleClose = () => setShow(false);

  const [messages, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const toTrigger = messages.filter(
      (message) => !message.seen && challengeTime > message.start && challengeTime <= message.end
    );

    if (toTrigger.length > 0) {
      const message = toTrigger[0];

      if (message.inPlay && gameState.RunningState != "Running") {
        dispatch({ type: "display", id: message.id });
      } else {
        setMessageText(message.text);
        setImage(message.image);
        setShow(true);
        dispatch({ type: "display", id: message.id });
      }
    }
  }, [challengeTime]);

  return (
    <Modal show={show} onHide={handleClose} size={"lg"}>
      <Modal.Header closeButton className={"background_md"}>
        <Modal.Title>Messages</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table>
          <tbody>
            <tr>
              <th>
                <h1>Messages</h1>
              </th>
            </tr>
            <p>{messageText}</p>
            {image != "" ? <VideoBox videoLink={image} videoHeader="" /> : null}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default MessagesModal;
