import { Typography } from "@mui/material";
import React from "react";
import Tickertape from "../tickertape/tickertape";

interface Props {
  investmentTape: string;
}

const InvestmentReel: React.FC<Props> = (props) => {
  const { investmentTape } = props;

  return (
    <React.Fragment>
      {investmentTape && investmentTape.length > 30 ? (
        <Tickertape duration={30}>
          <Typography component="span" variant="subtitle1" color="primary">
            {investmentTape}
          </Typography>
        </Tickertape>
      ) : (
        <span>{investmentTape}</span>
      )}
    </React.Fragment>
  );
};

export default React.memo(InvestmentReel);
