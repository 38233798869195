import React from "react";
import { Button } from "@mui/material";

interface Props {
  setQueueSelection?: any;
  queue?: number;
}

const LineSelector: React.FC<Props> = ({ setQueueSelection, queue }) => {
  return (
    <>
      <Button
        style={{ float: "right", marginLeft: "8px", padding: "1px" }}
        onClick={() => setQueueSelection(1)}
        variant="contained"
        color={queue === 1 ? "primary" : "secondary"}
      >
        Line 2
      </Button>
      <Button
        style={{ float: "right", padding: "1px" }}
        onClick={() => setQueueSelection(0)}
        variant="contained"
        color={queue === 0 ? "primary" : "secondary"}
      >
        Line 1
      </Button>
    </>
  );
};

export default LineSelector;
