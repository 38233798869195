import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import ReportRoundSelector from "../../../components/reportRoundSelector/ReportRoundSelector";
import { IScreenProps } from "../../../types/app.types";
import { IProductionReportViewModel } from "../../../types/gameState.types";
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Button, Card, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import StatBox from "../../../components/statBox/StatBox";
import { games } from "googleapis/build/src/apis/games";
import LineSelector from "../../../components/lineSelector/LineSelector";
import { amber, green, red } from "@mui/material/colors";
import clsx from "clsx";

const ProductionReport: React.FC<IScreenProps> = ({ gameState, text }) => {
  document.title = `Reports | ${text.wrapper.gameName}`;

  const [roundSelection, setRoundSelection] = useState(gameState.Round - 1);
  const [queueSelection, setQueueSelection] = useState(0);
  const [endWidthCSS, setEndWidthCSS] = useState("50%");
  const [graphSections, setGraphSections] = useState(gameState.ProductionGraphSections[0][0]);

  useEffect(() => {
    const newSections = gameState.ProductionGraphSections[queueSelection][roundSelection];
    setGraphSections(newSections);
    setEndWidthCSS(newSections.length > 0 ? `calc(100% / 26.25 * 0.125)` : `50%`);
  }, [gameState, roundSelection, queueSelection]);

  const ProdRep = gameState.ProductionReports;

  const changeProdLine = () => {
    if (graphSections === gameState.ProductionGraphSections[0][0]) {
      setGraphSections(gameState.ProductionGraphSections[1][0]);
    }
  };

  const extract = (value: keyof IProductionReportViewModel) => {
    return ProdRep.map((report, index) => (
      <TableCell component="td" key={index} className={classes.tableCell}>
        {report.ProductionWeeks > 0 && report[value]}
      </TableCell>
    ));
  };

  const useStyles = makeStyles((theme) => ({
    cont: {
      padding: theme.spacing(2),
    },
    dev: {
      backgroundColor: "#008A00",
      color: "white",
    },
    downTime: {
      backgroundColor: "#A36900",
      color: "",
    },
    inComplete: {
      backgroundColor: red[700],
      color: "white",
    },
    tableCell: {
      padding: theme.spacing(0.1),
    },
    graph: {
      padding: theme.spacing(1),
    },
    margin: {
      marginBottom: theme.spacing(1),
    },
    numbers: {
      minWidth: "100%",
      borderBottom: "1px solid black",
    },
    lineSelect: {
      float: "right",
    },
    Card: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      color: "white",
      fontWeight: 800,
    },
  }));

  const stateClassMap: any = {
    Producing: "pgProducing",
    "Setting Up": "pgSetup",
    Idle: "pgIdle",
    Incomplete: "pgIncomplete",
  };

  const chooseBgColor = (type: string) => {
    switch (type) {
      case "Producing":
        return "#008A00";
        break;
      case "Setting Up":
        return "#A36900";
        break;
      case "Idle":
        return "white";
        break;
      case "Incomplete":
        return red[700];
        break;
      default:
        break;
    }
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.cont}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12} className={classes.margin}>
            <Table className="tourProductionReportTable">
              <TableHead>
                <TableRow>
                  <TableCell component="th" className={classes.tableCell}>
                    <strong>{text.productionReport.title}</strong>
                  </TableCell>
                  {[...Array(gameState.NumberOfRounds)].map((e, i) => {
                    return (
                      <TableCell key={i} component="th" className={classes.tableCell}>
                        LEVEL {i + 1}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.productionWeeks}
                  </TableCell>
                  {extract("ProductionWeeks")}
                </TableRow>
                <TableRow id="tradingTableGreyDivider" className="uiBoxShadow uiTextShadow">
                  <TableCell component="td" className={classes.tableCell}>
                    <strong>{text.productionReport.equipment}</strong>
                  </TableCell>
                  <TableCell component="td" className={classes.tableCell}></TableCell>
                  <TableCell component="td" className={classes.tableCell}></TableCell>
                  <TableCell component="td" className={classes.tableCell}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.inUseWeeks}
                  </TableCell>
                  {extract("EquipmentWeeksInUse")}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.reconfigurationWeeks}
                  </TableCell>
                  {extract("EquipmentWeeksReconfiguration")}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.downtimeWeeks}
                  </TableCell>
                  {extract("EquipmentWeeksDowntime")}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.pctOfMaxUtilisation}
                  </TableCell>
                  {gameState.ProductionReports.map((report, index) => (
                    <TableCell
                      key={index}
                      component="td"
                      style={{
                        color: report.PercentOfTMU < report.TargetTMU ? red[700] : "black",
                        textAlign: "left",
                        padding: 0,
                      }}
                    >
                      {report.ProductionWeeks > 0 && report.PercentOfTMU + "%"}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow id="tradingTableGreyDivider" className="uiBoxShadow uiTextShadow">
                  <TableCell component="td" className={classes.tableCell}>
                    <strong>{text.productionReport.productDelivery}</strong>
                  </TableCell>
                  <TableCell component="td" className={classes.tableCell}></TableCell>
                  <TableCell component="td" className={classes.tableCell}></TableCell>
                  <TableCell component="td" className={classes.tableCell}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.totalOrders}
                  </TableCell>
                  {extract("TotalOrders")}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.ordersCompleted}
                  </TableCell>
                  {extract("OrdersCompleted")}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.slaBreaches}
                  </TableCell>
                  {extract("BreachedSLAs")}
                </TableRow>
                <TableRow>
                  <TableCell component="td" className={classes.tableCell}>
                    {text.productionReport.slaPct}
                  </TableCell>
                  {gameState.ProductionReports.map((report, index) => (
                    <TableCell key={index} component="td" className={classes.tableCell}>
                      {report.ProductionWeeks > 0 && report.SLAPct + "%"}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <ReportRoundSelector
              onChange={setRoundSelection}
              gameState={gameState}
              setQueueSelection={setQueueSelection}
              queue={queueSelection}
              initial={roundSelection}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={clsx(classes.graph, "tourProductionReportChart")}>
              <div className="prodGraphSection pgIdle" style={{ width: endWidthCSS }}>
                <div />
                <div className="prodGraphPanel" />
                <div />
              </div>
              <div className={classes.numbers}>
                {gameState.BankLabelList.map((num) => (
                  <div
                    key={num}
                    style={{
                      width: (100 / gameState.BankLabelList.length).toString() + "%",
                      float: "left",
                    }}
                  >
                    {num}
                  </div>
                ))}
              </div>
              {graphSections.map((section, index) => {
                if (section.StartWeek > gameState.Week) {
                  return null;
                } else {
                  return (
                    <div
                      className={`prodGraphSection ${stateClassMap[section.State]}`}
                      style={{
                        width: `calc(100% / 26.25 * ${section.LengthInWeeks})`,
                        backgroundColor: chooseBgColor(section.State),
                        color: section.State !== "Idle" ? "white" : "black",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <div>
                        <Typography variant="subtitle2" component="span">
                          {section.LengthInWeeks > 1 ? section.OrderNumber : ".." + section.OrderNumber.substring(5)}
                        </Typography>
                      </div>
                      <div className="prodGraphPanel">
                        <Typography variant="subtitle1" component="span">
                          {section.ProductName}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="subtitle2" component="span">
                          {section.LengthInWeeks} {section.LengthInWeeks > 0.5 && "Week"}
                          {section.LengthInWeeks > 1 && "s"}
                        </Typography>
                      </div>
                    </div>
                  );
                }
              })}
              <div className="prodGraphSection pgIdle" style={{ width: endWidthCSS }}>
                <div />
                <div className="prodGraphPanel" />
                <div />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.Card} style={{ backgroundColor: "#008A00" }}>
              {text.productionReport.productionTime}
            </Box>

            <Box className={classes.Card} style={{ backgroundColor: "#A36900" }}>
              {text.productionReport.downtimeReconfiguration}
            </Box>

            <Box className={classes.Card} style={{ backgroundColor: red[700] }}>
              {text.productionReport.orderIncomplete}
            </Box>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={4}>
            {gameState.AdditionalLineActive && (
              <LineSelector setQueueSelection={setQueueSelection} queue={queueSelection} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};

export default ProductionReport;
