import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { IScreenProps } from "../../../types/app.types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Cancel } from "@mui/icons-material";
import { getReferenceData } from "../../../modules/referenceData/service";
import { IReferenceData } from "../../../types/gameState.types";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    borderRadius: "4px 4px 0 0", // match border radius of the test box
  },
  logo: {
    maxWidth: "60%", // ensure the logo fits within the header
    height: "auto",
  },
  testBox: {
    marginTop: theme.spacing(2),
  },
}));

interface Test {
  name: string;
  fn: (data?: any) => Promise<any>;
}

interface TestResult {
  name: string;
  status: "pending" | "loading" | "complete";
  result?: boolean;
}

const FirewallConsole: React.FC = () => {
  document.title = `Firewall Test`;
  const classes = useStyles();

  const tests: Test[] = [
    {
      name: "Internet Connectivity Test",
      fn: () =>
        fetch("https://docs.gingr.tech/gogreen-english-usd-3l/logo.png", { mode: "no-cors" })
          .then(() => true)
          .catch(() => false),
    },
    {
      name: "GingrTech Platform Connectivity Test",
      fn: async () => {
        try {
          localStorage.setItem("dataset", "gogreen-english-usd-3l");
          const data = await getReferenceData();
          return data;
        } catch (error) {
          return false;
        }
      },
    },
    {
      name: "GingrTech Platform Data Test",
      fn: async (data) => {
        return (
          data && data.hasOwnProperty("Products") && Array.isArray(data["Products"]) && data["Products"].length > 0
        );
      },
    },
  ];

  const initialTestResults: TestResult[] = tests.map((test) => ({ name: test.name, status: "pending" }));
  const [results, setResults] = useState<TestResult[]>(initialTestResults);

  useEffect(() => {
    async function runTests() {
      let previousData;
      for (let i = 0; i < tests.length; i++) {
        setResults((oldResults) =>
          oldResults.map((testResult) =>
            testResult.name === tests[i].name ? { ...testResult, status: "loading" } : testResult
          )
        );

        await new Promise((res) => setTimeout(res, 1000)); // Extra delay for effect

        const result = await tests[i].fn(previousData);
        setResults((oldResults) =>
          oldResults.map((testResult) =>
            testResult.name === tests[i].name
              ? { name: tests[i].name, result: result !== false, status: "complete" }
              : testResult
          )
        );

        previousData = result !== false ? result : null;
      }
    }
    runTests();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={6} lg={4} style={{ margin: "auto" }}>
        <Box className={classes.header} boxShadow={3}>
          <img
            src="https://dev.go.gingr.tech/static/media/GT_logo.817d1ce4b91ca11a4dc1.png"
            alt="GingrTech Logo"
            className={classes.logo}
          />
        </Box>
        <Box className={classes.testBox} boxShadow={3} padding={2} borderRadius={2}>
          <Typography variant="h4" component="h4">
            Connectivity Tests
          </Typography>
          <List>
            {results.map((testResult, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  {testResult.status === "loading" && <CircularProgress size={24} color="inherit" />}
                  {testResult.status === "complete" && testResult.result && <CheckCircleIcon color="success" />}
                  {testResult.status === "complete" && !testResult.result && <Cancel color="error" />}
                </ListItemIcon>
                <ListItemText
                  primary={testResult.name}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: testResult.status === "loading" ? "bold" : "normal",
                    },
                  }}
                />
                {testResult.status === "complete" && (
                  <Typography
                    variant="body2"
                    style={{
                      color: testResult.result ? "green" : "red",
                    }}
                  >
                    {testResult.result ? "Passed" : "Failed"}
                  </Typography>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};
export default FirewallConsole;
