import React, { useState } from "react";
import { IGameState, ICo2ReportViewModel } from "../../types/gameState.types";
import { ITextSet } from "../../types/text.types";
import { Table, TableHead, TableBody, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import NumberFormat from "../numberFormat/NumberFormat";

const getDiff = (value: number, oldValue: number) => {
  const toReturn = value > oldValue ? ((value - oldValue) / oldValue) * 100 : -(((oldValue - value) / oldValue) * 100);
  return toReturn;
};
const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: theme.spacing(0.4),
    borderBottom: "none",
    paddingLeft: theme.spacing(1),
  },
  numberCell: {
    textAlign: "left",
  },
  head: {
    padding: theme.spacing(0.1),
    paddingLeft: theme.spacing(1),
    textAlign: "left",
  },
  tableBody: {
    overflowY: "auto",
    // backgroundColor: theme.palette.secondary.light
  },
  table: {
    height: "80vh",
    //overflowY: 'auto',
    padding: theme.spacing(3),
  },
  padding: {
    paddingLeft: theme.spacing(1),
  },
  section: {
    backgroundColor: "white",
    height: "3vh",
  },
  gap: {
    padding: theme.spacing(1),
  },
  subheader: {
    minWidth: "15%",
  },
}));

interface Props {
  gameState: IGameState;
  text: ITextSet;
}

const Co2ReportTable: React.FC<Props> = (props) => {
  const { gameState, text } = props;
  const [co2Reports, setCo2Reports] = useState(gameState.Co2Reports.slice(0, gameState.Round + 1));

  const classes = useStyles();

  const numberCell = clsx(classes.tableCell, classes.numberCell);

  const extract = (value: keyof ICo2ReportViewModel, addDataAttr = false, diff?: boolean) => {
    return co2Reports.map((report, index) => (
      <TableCell component="td" key={index} className={numberCell} data-attr={addDataAttr ? value : null}>
        {report[value].toFixed(2).replace(/[.,]00$/, "")}
        {diff && report["Round"] !== 0 ? (
          <>
            {" "}
            (
            <NumberFormat value={getDiff(report[value], co2Reports[0][value])} displayPlus={true} />
            %)
          </>
        ) : null}
      </TableCell>
    ));
  };

  const subHead = clsx(classes.tableCell, classes.subheader);

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell component="th" className={classes.head}></TableCell>
          <TableCell component="th" className={classes.head}></TableCell>
          <TableCell component="th" className={classes.head}>
            STANDARD PERIOD
          </TableCell>
          <TableCell component="th" className={classes.head}>
            {text.tradingReport.level} 1
          </TableCell>
          <TableCell component="th" className={classes.head}>
            {text.tradingReport.level} 2
          </TableCell>
          <TableCell component="th" className={classes.head}>
            {text.tradingReport.level} 3
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="tourCO2ReportSales">
        <TableRow>
          <TableCell component="td" className={classes.tableCell} colSpan={7}>
            <strong>Sales Emissions</strong>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Scope 1 Sales Emissions (tCO2e){" "}
          </TableCell>
          {extract("TotalSalesScope1Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Scope 2 Sales Emissions (tCO2e)
          </TableCell>
          {extract("TotalSalesScope2Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Scope 3 Sales Emissions (tCO2e)
          </TableCell>
          {extract("TotalSalesScope3Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Total Sales Emissions (tCO2e)
          </TableCell>
          {extract("TotalSalesTotalEmissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Units Produced (Units)
          </TableCell>
          {extract("TotalUnitsProduced", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Sales Emissions Per Unit (tCO2e / Unit)
          </TableCell>
          {extract("Co2SalesCostPerUnit", true, true)}
        </TableRow>
        <TableRow className={classes.section}>
          <TableCell colSpan={7} className={classes.gap} />
        </TableRow>
      </TableBody>
      <TableBody className="tourCO2ReportOperations">
        <TableRow>
          <TableCell component="th" className={classes.tableCell} colSpan={7}>
            <strong>Operational Emissions</strong>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Scope 1 Operational Emissions (tCO2e)
          </TableCell>
          {extract("TotalOpsScope1Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Scope 2 Operational Emissions (tCO2e)
          </TableCell>
          {extract("TotalOpsScope2Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Scope 3 Operational Emissions (tCO2e)
          </TableCell>
          {extract("TotalOpsScope3Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Total Operational Emissions (tCO2e)
          </TableCell>
          {extract("TotalOpsTotalEmissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Operational Emissions Per Week (tCO2e / Week)
          </TableCell>
          {extract("Co2OperationalCostPerWeek", true, true)}
        </TableRow>
        <TableRow className={classes.section}>
          <TableCell colSpan={7} className={classes.gap} />
        </TableRow>
      </TableBody>
      <TableBody className="tourCO2ReportTotal">
        <TableRow>
          <TableCell component="th" className={classes.tableCell} colSpan={7}>
            <strong>Total Emissions</strong>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Total Scope 1 Emissions (tCO2e)
          </TableCell>
          {extract("TotalCo2Scope1Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Total Scope 2 Emissions (tCO2e)
          </TableCell>
          {extract("TotalCo2Scope2Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Total Scope 3 Emissions (tCO2e)
          </TableCell>
          {extract("TotalCo2Scope3Emissions", true)}
        </TableRow>
        <TableRow>
          <TableCell component="td" className={subHead}></TableCell>
          <TableCell component="td" className={classes.tableCell}>
            Total Co2 Emissions (tCO2e)
          </TableCell>
          {extract("TotalCo2ScopeTotalEmissions", true)}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Co2ReportTable;
