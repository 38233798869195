import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface Props {
  title: string;
  children: any;
  open: boolean;
  setOpen: any;
  onConfirm?: any;
}

const ConfirmDialog: React.FC<Props> = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {onConfirm && (
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)} color="primary">
            <Typography variant="subtitle2" component="p" color="error">
              No
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="primary"
          >
            <Typography variant="subtitle2" component="p" style={{ color: "#3CBD89" }}>
              Yes
            </Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
export default ConfirmDialog;
