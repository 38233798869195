import { gameStateAxios as API } from "./api";
import { IPlayerEvent } from "../../types/playerEvents.types";
import { integrations_v1alpha } from "googleapis";

API.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_GAME_STATE_API_KEY ?? "";

let gameStatusRequestRunning = false;
let gameStatusLastUpdatedRequestRunning = false;

export const getGameStatus = async () => {
  if (!gameStatusRequestRunning) {
    gameStatusRequestRunning = true;
    try {
      const response = await API.get(`/GetCurrentGameState`);
      gameStatusRequestRunning = false;
      return response.data;
    } catch {
      gameStatusRequestRunning = false;
      return null;
    }
  }

  return null;
};

export const getGameStatusLastUpdated = async () => {
  if (!gameStatusLastUpdatedRequestRunning) {
    gameStatusLastUpdatedRequestRunning = true;
    try {
      const response = await API.get(`/GetMVGeneratedDateTime`);
      gameStatusLastUpdatedRequestRunning = false;
      return response.data;
    } catch {
      gameStatusLastUpdatedRequestRunning = false;
      return null;
    }
  }

  return null;
};

export const getGameStatusSeconds = async () => {
  if (!gameStatusRequestRunning) {
    gameStatusRequestRunning = true;
    try {
      const response = await API.get(`/GetCurrentGameState`);
      gameStatusRequestRunning = false;
      return response.data.SecondsElapsedInWeek;
    } catch {
      gameStatusRequestRunning = false;
      return null;
    }
  }

  return null;
};

export const getTimeSinceRoundStart = async () => {
  return await API.get(`/GetTimeSinceRoundStart`);
};

export const getTimeSinceChallengeStart = async () => {
  return await API.get(`/GetTimeSinceChallengeStart`);
};

export const play = async () => {
  await API.post(`/Play`);
};

export const pause = async () => {
  await API.post(`/Pause`);
};

export const endCurrentRound = async () => {
  await API.post(`/EndRound`);
};

export const restart = async () => {
  await API.post(`/Restart`);
};

export const resume = async () => {
  await API.post(`/Resume`);
};

export const selectNextRound = async () => {
  await API.post(`/SelectNextRound`);
};

export const selectPreviousRound = async () => {
  await API.post(`/SelectPreviousRound`);
};

export const setCommission = async (commissionPct: string, commissionSetting: string) => {
  await API.post(`/SetCommission`, null, {
    params: { commissionPct, commissionSetting },
  });
};

export const setCapability = async (capability: string, level: number, value: number) => {
  await API.post(`/SetCapability`, null, {
    params: { capability, level, value },
  });
};

export const depositCash = async (amount: string) => {
  await API.post(`/DepositCash`, null, {
    params: { amount },
  });
};

export const repayInvestment = async (amount: string) => {
  await API.post(`/RepayInvestment`, null, {
    params: { amount },
  });
};
export const chargeConsultancyFee = async (amount: string) => {
  await API.post(`/ChargeConsultancyFee`, null, {
    params: { amount },
  });
};

export const advanceOrderState = async (orderNumber: string) => {
  await API.post(`/AdvanceOrderState`, null, {
    params: { orderNumber },
  });
};

export const authoriseOrder = async (orderNumber: string) => {
  await API.post(`/AuthoriseOrder`, null, {
    params: { orderNumber },
  });
};

export const applyMarketingCampaign = async (campaignId: string | undefined, targetAudience: string) => {
  await API.post(`/ApplyMarketingCampaign`, null, {
    params: { campaignId, targetAudience },
  });
};

export const applyInvestment = async (investmentTypeCode: string) => {
  await API.post(`/ApplyInvestment`, null, {
    params: { investmentTypeCode },
  });
};

export const deployAdditionalProductionLine = async (product: string, usingLoan: string) => {
  await API.post(`/DeployAdditionalProductionLine`, null, {
    params: { product, usingLoan },
  });
};

export const clearMarketingCampaign = async () => {
  await API.post(`/ClearMarketingCampaign`);
};

export const setAutoInvoice = async (status: boolean) => {
  await API.post(`/SetAutoInvoice`, null, {
    params: { status },
  });
};

export const persistRoundEndState = async (state: string) => {
  await API.post(`/PersistRoundEndState`, null, {
    params: { state },
  });
};

export const recordFormSubmission = async (submission: IPlayerEvent) => {
  await API.post("/RecordFormSubmission", {
    id: submission.id,
    GameId: submission.GameId,
    Console: submission.Console,
    Round: submission.Round,
    Week: submission.Week,
    DateTime: submission.DateTime,
    Feedback: submission.Feedback,
    Submission: submission.Submission,
  });
};

export const logDebug = async (value: string) => {
  await API.post(`/LogDebug`, null, {
    params: { value },
  });
};

export const archiveLead = async (orderNumber: string, isArchived: boolean) => {
  await API.post("/ArchiveLead", null, {
    params: { orderNumber, isArchived },
  });
};

export const sendChatMessage = async (chatMessage: string) => {
  const message = {
    message: chatMessage,
  };
  return await API.post(`/ChatMessage`, message);
};

export const getConfluencePage = async (pageId: string) => {
  return await API.get(`/GetConfluencePage/${pageId}`);
};
