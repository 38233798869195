import React, { useState } from "react";

import BankBalanceChart from "../../../components/bankBalanceChart/BankBalanceChart";
import ReportRoundSelector from "../../../components/reportRoundSelector/ReportRoundSelector";

import { IScreenProps } from "../../../types/app.types";
import { Grid, AppBar, Tabs, Tab, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import StatBox from "../../../components/statBox/StatBox";
import TabPanel from "../../../components/tabPanel/TabPanel";

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

const CashReport: React.FC<IScreenProps> = ({ gameState, text, currencyParser }) => {
  const [roundSelection, setRoundSelection] = useState(gameState.Round - 1);

  const classes = useStyles();

  return (
    <Grid container className={classes.cont}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12} className={classes.margin}>
            <ReportRoundSelector onChange={setRoundSelection} gameState={gameState} initial={roundSelection} />
          </Grid>
          <Grid item xs={12} className="tourCashReport">
            <BankBalanceChart
              gameState={gameState}
              text={text.cashReport}
              data={gameState.BankReportValues[roundSelection]}
              mode={"chart"}
              height={70}
              currencyParser={currencyParser}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};

export default CashReport;
