import { PlusOne } from "@mui/icons-material";
import React from "react";

interface Props {
  value: number;
  displayPlus?: boolean;
}

const NumberFormat: React.FC<Props> = (props) => {
  const { value, displayPlus } = props;

  return <>{value < 0 ? <>&minus;{value * -1}</> : displayPlus ? <>+{value}</> : <>{value}</>}</>;
};

export default NumberFormat;
