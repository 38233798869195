import {
  GameControlsRoute,
  MainScreenRoute,
  MDRoute,
  MarketingRoute,
  SalesRoute,
  ProductionRoute,
  FinanceRoute,
  ReportsBaseRoute,
} from "../routing/routes";

import { RoleIDs } from "../routing/routeWrapper";

import { IRoleSelectionText } from "../types/text.types";
import { IAllRoles } from "../types/app.types";

const roles = (text: IRoleSelectionText): IAllRoles => {
  return {
    [RoleIDs.Facilitator]: {
      id: RoleIDs.Facilitator,
      nameTitleCase: "",
      nameUpperCase: text.commanderUpper,
      landingPageHref: "game" + GameControlsRoute.toUrl(),
      sidebarOptions: [
        {
          title: "- Summary",
          icon: null,
          id: "salesSideSummary",
          href: "game/" + MainScreenRoute.toUrl(),
        },
        {
          title: "- CEO",
          icon: null,
          id: "salesSideMD",
          href: "game/" + MDRoute.toUrl(),
        },
        {
          title: "- Finance",
          icon: null,
          id: "salesSideFinance",
          href: "game/" + FinanceRoute.toUrl(),
        },
        {
          title: "- Delivery",
          icon: null,
          id: "salesSideDelivery",
          href: "game/" + ProductionRoute.toUrl(),
        },
        {
          title: "- Sales",
          icon: null,
          id: "salesSideSales",
          href: "game/" + SalesRoute.toUrl(),
        },
        {
          title: "- Marketing",
          icon: null,
          id: "salesSideMarketing",
          href: "game/" + MarketingRoute.toUrl(),
        },
      ],
    },
    [RoleIDs.MD]: {
      id: RoleIDs.MD,
      nameTitleCase: text.managingDirectorTitle,
      nameUpperCase: text.managingDirectorUpper,
      landingPageHref: "game" + MDRoute.toUrl(),
      sidebarOptions: [
        {
          title: "- Summary",
          icon: null,
          id: "mdSideSummary",
          href: MainScreenRoute.toUrl(),
        },
        {
          title: "- CEO",
          icon: null,
          id: "mdSideMD",
          href: MDRoute.toUrl(),
        },
        {
          title: "- Finance",
          icon: null,
          id: "mdSideFinance",
          href: FinanceRoute.toUrl(),
        },
        {
          title: "- Delivery",
          icon: null,
          id: "mdSideDelivery",
          href: ProductionRoute.toUrl(),
        },
        {
          title: "- Sales",
          icon: null,
          id: "mdSideSales",
          href: SalesRoute.toUrl(),
        },
        {
          title: "- Marketing",
          icon: null,
          id: "mdSideMarketing",
          href: MarketingRoute.toUrl(),
        },
      ],
    },
    [RoleIDs.Marketing]: {
      id: RoleIDs.Marketing,
      nameTitleCase: text.marketingDepartmentTitle,
      nameUpperCase: text.marketingDepartmentUpper,
      landingPageHref: "game" + MarketingRoute.toUrl(),
      sidebarOptions: [
        {
          title: "- Summary",
          icon: null,
          id: "salesSideSummary",
          href: MainScreenRoute.toUrl(),
        },
        {
          title: "- CEO",
          icon: null,
          id: "salesSideMD",
          href: MDRoute.toUrl(),
        },
        {
          title: "- Finance",
          icon: null,
          id: "salesSideFinance",
          href: FinanceRoute.toUrl(),
        },
        {
          title: "- Delivery",
          icon: null,
          id: "salesSideDelivery",
          href: ProductionRoute.toUrl(),
        },
        {
          title: "- Sales",
          icon: null,
          id: "salesSideSales",
          href: SalesRoute.toUrl(),
        },
        {
          title: "- Marketing",
          icon: null,
          id: "salesSideMarketing",
          href: MarketingRoute.toUrl(),
        },
      ],
    },
    [RoleIDs.Sales]: {
      id: RoleIDs.Sales,
      nameTitleCase: text.salesTeamTitle,
      nameUpperCase: text.salesTeamUpper,
      landingPageHref: "game" + SalesRoute.toUrl(),
      sidebarOptions: [
        {
          title: "- Summary",
          icon: null,
          id: "salesSideSummary",
          href: MainScreenRoute.toUrl(),
        },
        {
          title: "- CEO",
          icon: null,
          id: "salesSideMD",
          href: MDRoute.toUrl(),
        },
        {
          title: "- Finance",
          icon: null,
          id: "salesSideFinance",
          href: FinanceRoute.toUrl(),
        },
        {
          title: "- Delivery",
          icon: null,
          id: "salesSideDelivery",
          href: ProductionRoute.toUrl(),
        },
        {
          title: "- Sales",
          icon: null,
          id: "salesSideSales",
          href: SalesRoute.toUrl(),
        },
        {
          title: "- Marketing",
          icon: null,
          id: "salesSideMarketing",
          href: MarketingRoute.toUrl(),
        },
      ],
    },
    [RoleIDs.Production]: {
      id: RoleIDs.Production,
      nameTitleCase: text.productionTeamTitle,
      nameUpperCase: text.productionTeamUpper,
      landingPageHref: "game" + ProductionRoute.toUrl(),
      sidebarOptions: [
        {
          title: "- Summary",
          icon: null,
          id: "salesSideSummary",
          href: MainScreenRoute.toUrl(),
        },
        {
          title: "- CEO",
          icon: null,
          id: "salesSideMD",
          href: MDRoute.toUrl(),
        },
        {
          title: "- Finance",
          icon: null,
          id: "salesSideFinance",
          href: FinanceRoute.toUrl(),
        },
        {
          title: "- Delivery",
          icon: null,
          id: "salesSideDelivery",
          href: ProductionRoute.toUrl(),
        },
        {
          title: "- Sales",
          icon: null,
          id: "salesSideSales",
          href: SalesRoute.toUrl(),
        },
        {
          title: "- Marketing",
          icon: null,
          id: "salesSideMarketing",
          href: MarketingRoute.toUrl(),
        },
      ],
    },
    [RoleIDs.Finance]: {
      id: RoleIDs.Finance,
      nameTitleCase: text.financeDepartmentTitle,
      nameUpperCase: text.financeDepartmentUpper,
      landingPageHref: "game" + FinanceRoute.toUrl(),
      sidebarOptions: [
        {
          title: "- Summary",
          icon: null,
          id: "salesSideSummary",
          href: MainScreenRoute.toUrl(),
        },
        {
          title: "- CEO",
          icon: null,
          id: "salesSideMD",
          href: MDRoute.toUrl(),
        },
        {
          title: "- Finance",
          icon: null,
          id: "salesSideFinance",
          href: FinanceRoute.toUrl(),
        },
        {
          title: "- Delivery",
          icon: null,
          id: "salesSideDelivery",
          href: ProductionRoute.toUrl(),
        },
        {
          title: "- Sales",
          icon: null,
          id: "salesSideSales",
          href: SalesRoute.toUrl(),
        },
        {
          title: "- Marketing",
          icon: null,
          id: "salesSideMarketing",
          href: MarketingRoute.toUrl(),
        },
      ],
    },
  };
};

export default roles;
