import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import {
  applyMarketingCampaign,
  clearMarketingCampaign,
  recordFormSubmission,
} from "../../../../modules/gameState/service";
import {
  IAudienceViewModel,
  IGameState,
  IMarketingCampaignViewModel,
  IReferenceData,
} from "../../../../types/gameState.types";
import { IFacilitatorConsoleText } from "../../../../types/text.types";
import controlsTheme from "./controlsTheme";
import { IPlayerEvent } from "../../../../types/playerEvents.types";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  gameState: IGameState;
  text: IFacilitatorConsoleText;
  referenceData: IReferenceData;
  console: string;
}

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(3),
  },
  header: {
    marginLeft: "0",
    marginRight: "0",
  },
  leftGrid: {
    textAlign: "right",
    paddingRight: theme.spacing(1),
  },
  rightGrid: {
    textAlign: "left",
    paddingLeft: theme.spacing(1),
  },
}));

export const MarketingPanel: React.FC<Props> = ({ gameState, referenceData, text, console }) => {
  const [campaignToActivate, setCampaignToActivate] = useState(
    referenceData?.MarketingCampaigns && referenceData?.MarketingCampaigns.length > 0
      ? referenceData?.MarketingCampaigns[0].Id
      : undefined
  );
  const [audienceToActivate, setAudienceToActivate] = useState(0);
  const [campaignFeedback, setCampaignFeedback] = useState(<></>);

  const audienceSelectionEnabled = gameState.Round > 1 && campaignToActivate !== "MC002";

  // const handleCampaignChange = (
  //   event: SelectChangeEvent<{ value: string }>
  // ) => {
  //   setCampaignToActivate(event.target.value as string);
  // };

  const handleCampaignChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setCampaignToActivate(selectedValue);
  };

  const handleAudienceToActivateChange = (event: SelectChangeEvent<number>) => {
    const selectedValue = event.target.value;
    setAudienceToActivate(Number(selectedValue));
  };

  const applyCampaign = async () => {
    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
      setCampaignFeedback(<Typography color="error">Cannot apply campaign - game has gone bust</Typography>);
      return false;
    }
    if (gameState.RunningState == "Finished Round" && gameState.Week >= 26) {
      setCampaignFeedback(<Typography color="error">Cannot apply campaign - level has finished</Typography>);
      return false;
    }
    if (gameState.AutoFac) {
      setCampaignFeedback(<Typography color="error">Marketing campaigns unavailable in demo.</Typography>);
      return false;
    }
    let message;

    const newDate: Date = new Date();
    const form: IPlayerEvent = {
      id: localStorage.getItem("guid") + newDate.toString(),
      GameId: localStorage.getItem("guid"),
      Console: console,
      Round: gameState.Round.toString(),
      Week: gameState.Week.toString(),
      Submission:
        "Campaign To Activate: " + campaignToActivate + ", Audience To Activate: " + audienceToActivate.toString(),
      DateTime: newDate,
      Feedback: "",
    };
    try {
      await applyMarketingCampaign(campaignToActivate, audienceToActivate.toString());
      message = `${text.marketingCampaign} ${campaignToActivate} ${text.hasBeenApplied}.`;
      if (Number(audienceToActivate) > 0) {
        message += ` ${text.theTargetAudienceIs} ${audienceToActivate}.`;
      }
      setCampaignToActivate(referenceData.MarketingCampaigns[0].Id);
      setAudienceToActivate(0);
      form.Feedback = message;
      recordFormSubmission(form);
      setCampaignFeedback(<Typography style={{ color: "#3CBD89" }}>{message}</Typography>);
    } catch {
      form.Feedback = "Error Applying Marketing Campaign";
      recordFormSubmission(form);
      setCampaignFeedback(<Typography color="error">Error applying Marketing Campaign</Typography>);
    }
  };

  const cancelApplyCampaign = () => {
    setCampaignToActivate("");
    setAudienceToActivate(0);
  };

  const deactivateMarketingCampaign = async () => {
    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
      setCampaignFeedback(<Typography color="error">Cannot deactivate campaign - game has gone bust</Typography>);
      return false;
    }
    if (gameState.RunningState == "Finished Round" && gameState.Week >= 26) {
      setCampaignFeedback(<Typography color="error">Cannot deactivate campaign - level has finished</Typography>);
      return false;
    }
    if (gameState.RunningState != "Running") {
      setCampaignFeedback(<Typography color="error">Cannot deactivate campaign - Game not running</Typography>);
      return false;
    }
    if (gameState.AutoFac) {
      setCampaignFeedback(<Typography color="error">Marketing campaigns unavailable in demo.</Typography>);
      return false;
    }

    try {
      await clearMarketingCampaign();
      setCampaignFeedback(<Typography style={{ color: "#3CBD89" }}>{text.marketingCampaignDeactivated}</Typography>);
    } catch {
      setCampaignFeedback(<Typography color="error">{"Error applying Marketing Campaign"}</Typography>);
    }
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.padding}>
      <Grid item xs={12}>
        <Typography component="p" variant="subtitle2" color="primary">
          {text.campaignDesc}
        </Typography>
        <span>&nbsp;</span>
      </Grid>
      <Grid xs={6} item className={classes.leftGrid}>
        <InputLabel id="lblCampaign">{text.campaignID}</InputLabel>
        <Select<string>
          value={campaignToActivate}
          displayEmpty
          onChange={handleCampaignChange}
          defaultValue={campaignToActivate}
          aria-label={"Campaign"}
          name={"Campaign"}
          labelId="lblCampaign"
        >
          {referenceData.MarketingCampaigns &&
            referenceData.MarketingCampaigns.map((campaign: IMarketingCampaignViewModel) => (
              <MenuItem value={campaign.Id} key={campaign.Id}>
                <Typography variant="subtitle2" component="p" color="textPrimary">
                  {campaign.Id}
                </Typography>
              </MenuItem>
            ))}
        </Select>
      </Grid>
      <Grid xs={6} item className={classes.rightGrid}>
        <InputLabel id="lblAudience">{text.targetAudience}</InputLabel>
        <Select<number>
          value={audienceToActivate}
          displayEmpty
          onChange={handleAudienceToActivateChange}
          aria-label={"Target Audience"}
          name={"TargetAudience"}
          labelId="lblAudience"
        >
          {referenceData.MarketingAudiences.map((audience: IAudienceViewModel) => (
            <MenuItem value={audience.Id} key={audience.Id}>
              <Typography variant="subtitle2" component="p" color="textPrimary">
                {audience.Id}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid xs={12} item>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={controlsTheme}>
            <Button
              variant="contained"
              onClick={applyCampaign}
              disabled={!campaignToActivate || gameState.RunningState == "Ready"}
              className={classes.button}
              color="primary"
              name={text.apply}
            >
              <Typography variant="subtitle2" component="p">
                {text.apply}
              </Typography>
            </Button>
            <Button variant="contained" color="secondary" onClick={deactivateMarketingCampaign} name={text.deactivate}>
              <Typography variant="subtitle2" component="p">
                {text.deactivate}
              </Typography>
            </Button>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
      <Grid item xs={12} aria-live="polite">
        {campaignFeedback}
      </Grid>
    </Grid>
  );
};
