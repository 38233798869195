import React from "react";
import { Tooltip } from "@mui/material";

function useEventListener(eventName, handler, element = window) {
  const savedHandler = React.useRef();

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

const AccessibleTooltip = (props) => {
  const { text, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handler = React.useCallback((e) => {
    if (e.key === "Escape") {
      onClose();
    }
  }, []);

  useEventListener("keydown", handler);

  return (
    <Tooltip
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      interactive="true"
      arrow
      title={text}
      aria-label={text}
      {...rest}
    />
  );
};

export default AccessibleTooltip;
