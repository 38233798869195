import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Checkbox, Grid, ListItem, ListItemIcon, Theme, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { IRole } from "../../types/app.types";
import { IWrapperText } from "../../types/text.types";
import "./header.css";
import { IGameState } from "../../types/gameState.types";
import "./header.css";
import useInterval from "../..//hooks/useInterval";
import { Md5 } from "ts-md5";
import { AppContext } from "../../context";
import { MenuOpen, Menu } from "@mui/icons-material";

interface StyleProps {
  backgroundColour: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  main: {
    backgroundColor: ({ backgroundColour }) => backgroundColour,
    height: "100%",
    color: theme.palette.secondary.light,
    //padding: theme.spacing(1)
  },
  logo: {
    float: "right",
    maxHeight: "8vh",
    maxWidth: "35vh",
    padding: theme.spacing(1),
    margin: "3px 4px 0 0",
  },
  ready: {
    float: "right",
  },
  headerText: {
    minHeight: "8vh",
    float: "left",
    padding: "13px", // TODO: Revisit with theming
    color: "white",
  },
  countdown: {
    padding: "0 20px 0 10px",
    lineHeight: "1",
  },
}));

interface Props {
  toggleExpandSidebar: () => any;
  sidebarExpanded: boolean;
  isLargeScreen: boolean;
  role: IRole;
  currentPage: string;
  challengeTime: number;
  text: IWrapperText;
  gameState: IGameState;
}

const Header: React.FC<Props> = (props) => {
  const { role, challengeTime, currentPage, toggleExpandSidebar, text, gameState, sidebarExpanded, isLargeScreen } =
    props;
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(AppContext);
  const [bustText, setBustText] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [countdown, setCountdown] = useState(20);
  const [readyVisible, setReadyVisible] = useState(true);
  const [isCountdownRunning, setIsCountdownRunning] = useState(false);

  // TODO: readyVisible should only be true for demo games.

  const pageNames: Record<string, any> = {
    "/game/controls": text.sidebar.controls,
    "/game/main": text.sidebar.summary,
    "/game/marketing": text.sidebar.marketing,
    "/game/sales": text.sidebar.sales,
    "/game/ceo": text.sidebar.ceo,
    "/game/production": text.sidebar.delivery,
    "/game/finance": text.sidebar.finance,
    "/game/reports/trading": text.sidebar.reports,
    "/game/reports/cash": text.sidebar.reports,
    "/game/reports/lead_cash": text.sidebar.reports,
    "/game/reports/co2": text.sidebar.reports,
    "/game/reports/production": text.sidebar.reports,
    "/game/handbook": text.sidebar.handbook,
    "/game/ots": text.sidebar.opportunityTracking,
    "/game/ops": text.sidebar.operationsPlanning,
  };

  useInterval(
    () => {
      // Your custom logic here
      setCountdown(countdown - 1);

      if (countdown <= 1) {
        setIsCountdownRunning(false);
      }
    },
    isCountdownRunning ? 1000 : null
  );

  useEffect(() => {
    const rocketSessionId = localStorage.getItem("rocketSessionId") ?? "";
    const isReady = state.participants?.readyUsers?.includes(rocketSessionId) ?? false;
    dispatch({ type: "SET_ISREADY", payload: isReady });
    setIsReady(isReady);
  }, [state.participants]);

  useEffect(() => {
    if (Object.keys(state.countdown).length > 0) {
      setCountdown(state.countdown.seconds);
      setIsCountdownRunning(state.countdown.isRunning);
    }
  }, [state.countdown]);

  useEffect(() => {
    if (gameState.RunningState == "Running") {
      if (gameState.BankCurrentNumeric > 0) {
        setBustText("Warning: You are currently using your Overdraft");
      } else {
        setBustText("Level has Started");
      }
    }

    if (gameState.RunningState == "Round Finished") {
      if (gameState.BankCurrentNumeric < -60000) {
        setBustText("You have gone Bust! Look at Your Videos");
      }
      if (gameState.Round == 1 && gameState.Week >= 26 && gameState.BankCurrentNumeric > -60000) {
        setBustText(
          "Well Done! You have managed to get through the Practice Level. Please review the end of Level A Success Video"
        );
      }
      if (gameState.Round == 2 && gameState.Week >= 26 && gameState.BankCurrentNumeric > -60000) {
        setBustText(
          "Well Done! You have managed to get through a Level A. Please review the end of Level A Success Video"
        );
      }
      if (gameState.Round == 3 && gameState.Week >= 26 && gameState.BankCurrentNumeric > -60000) {
        setBustText(
          "Well Done! You have managed to get through a Level B. Please review the end of Level B Success Video"
        );
      }
    } else {
      setBustText("");
    }
  }, [gameState.BankCurrentNumeric, challengeTime]);

  const roundTime = Math.floor(Number(challengeTime) / 60);
  const time =
    gameState.BankCurrentNumeric < 0 &&
    gameState.Round < 26 &&
    ((roundTime > 40 && roundTime < 67) ||
      (roundTime > 141 && roundTime < 168) ||
      (roundTime > 242 && roundTime > 270));
  const theme = useTheme();
  const classes = useStyles({ backgroundColour: theme.custom.mainHeader });
  const dataset = localStorage.getItem("dataset");
  const hashedDataset = dataset !== null ? Md5.hashStr(dataset) : undefined;
  const { pathname } = useLocation();

  const handleIsReady = (e: any) => {
    dispatch({ type: "SET_ISREADY", payload: e.target.checked });
    setIsReady(e.target.checked);
  };

  return (
    <Grid container className={classes.main}>
      <Grid item md={4} sm={6} xs={12}>
        {!isLargeScreen && !sidebarExpanded ? (
          <ListItem onClick={toggleExpandSidebar} button>
            <ListItemIcon>
              <Menu style={{ color: "white" }} />
            </ListItemIcon>
          </ListItem>
        ) : null}

        {!state.gameState.AutoFac && localStorage.getItem("authFac") === hashedDataset ? (
          <Typography variant="h6" component="span" className={classes.headerText}>
            {gameState.GameName + " - " + gameState.NumberOfRounds + " Levels"}
          </Typography>
        ) : null}

        {state.gameState.AutoFac ? (
          <Box
            border={1}
            padding={0.5}
            borderRadius={2}
            margin={0.5}
            bgcolor="background.paper"
            alignSelf="flex-start"
            display="inline-flex"
          >
            <Grid container className="tourReady">
              <Grid item xs={6} style={{ paddingLeft: "12px" }}>
                <Box display="flex" alignItems="center">
                  <Typography color="primary" variant="body1" style={{ whiteSpace: "nowrap" }}>
                    I&apos;m Ready
                  </Typography>
                  <Checkbox
                    color="primary"
                    onChange={handleIsReady}
                    checked={isReady}
                    disabled={state.gameState.IsStarted}
                    style={{ padding: 0 }}
                  />
                </Box>
                <Typography color="primary" variant="body2" style={{ whiteSpace: "nowrap" }}>
                  {state.participants.ready} / {state.participants.connected} Ready (Min 5)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="primary" variant="h3" className={classes.countdown} align="right">
                  {!state.gameState.IsStarted && (
                    <>
                      00:
                      {countdown.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Grid>
      <Grid item md={6} sm={6} xs={6}>
        <Typography variant="h3" component="h1" color="white">
          {pageNames[pathname]}
        </Typography>
      </Grid>
      <Grid item md={2} sm={6} xs={6}>
        <img src={theme.custom.headerLogoUrl} alt={"Header logo"} className={classes.logo} />
      </Grid>
    </Grid>
  );
};

export default Header;
