import axios from "axios";
import config from "../../config";

export const socketDataAxios = (() => {
  const socketDataAxios = axios.create({
    baseURL: config.locations.gameStateAPI,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  socketDataAxios.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_GAME_STATE_API_KEY ?? "";

  return socketDataAxios;
})();
