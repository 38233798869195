import { Grid, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import roles from "../../entities/roles";
import useDynamicText from "../../hooks/useDynamicText";
import { IAllRoles } from "../../types/app.types";
import AccessibleTooltip from "../../components/accessibleTooltip/AccessibleTooltip";

const useStyles = makeStyles((theme) => ({
  cont: {
    marginTop: "15vh",
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
  },
  roleOpt: {
    height: "8vh",
    flex: "center",
    marginBottom: "1vh",
    borderRadius: "2px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const RoleSelectionPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const text = useDynamicText();
  const classes = useStyles();

  if (!text) {
    return <Loading />;
  }

  const allRoles: IAllRoles = roles(text.roleSelection);

  const chooseColor = (roleId: string) => {
    switch (roleId) {
      case "Fac":
        return "#5490c0";
        break;
      case "Mark":
        return "#0084d3";
        break;
      case "Sales":
        return "#cd8113";
        break;
      case "MD":
        return "#806ba9";
        break;
      case "Prod":
        return "#4a8a72";
      case "Fin":
        return "#c0081f";
        break;
      default:
        break;
    }
  };

  return (
    <Grid container>
      <Grid container direction="row" className={classes.cont}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <AccessibleTooltip title="Select Role">
              <h1>Role Selection</h1>
            </AccessibleTooltip>

            {Object.values(allRoles)
              .filter((role) => role.id !== "Fac")
              .map((role) => (
                <div
                  key={role.id}
                  className={classes.roleOpt}
                  tabIndex={0}
                  style={{
                    backgroundColor: chooseColor(role.id),
                    color: "white",
                    textAlign: "center",
                  }}
                  onClick={() => navigate(role.landingPageHref)}
                >
                  <Typography variant="h5" component="h5" style={{ color: "white" }}>
                    {role.nameUpperCase}
                  </Typography>
                </div>
              ))}
          </Paper>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Grid>
  );
};

export default RoleSelectionPage;
