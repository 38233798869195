export default (value: number, inThousands = false): string => {
  let adjustedValue = value;
  let suffix = "";

  if (inThousands) {
    adjustedValue = Math.round(value / 1000);
    suffix = "k";
  }

  return `${adjustedValue < 0 ? "(" : ""}$${Math.abs(adjustedValue)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${adjustedValue < 0 ? ")" : ""}${suffix}`;
};
