import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { IGameState, IOrderDataViewModel } from "../../../../types/gameState.types";
import { Button, InputAdornment, Paper, TextField, Typography, TooltipProps, Tooltip, Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Theme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Warning } from "@mui/icons-material";
import controlsTheme from "../../facilitator/controlPanels/controlsTheme";
import { recordFormSubmission } from "../../../../modules/gameState/service";
import { IPlayerEvent } from "../../../../types/playerEvents.types";
import { ITextSet } from "../../../../types/text.types";
import appStyles from "../../../../assets/appStyles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface SubmitHandler {
  (orderNumber: string): void;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginLeft: theme.spacing(1),
  },
  container: {
    //display: 'flex',
    //justifyContent: 'space-between',
    padding: "10px 40px",
    textAlign: "center",
    minHeight: "30vh",
  },
  cheating: {
    paddingTop: "1px",
    height: "calc(100% - 23px)",
  },
  form: {
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: "4px",
    padding: "4px",
  },
}));

interface Props {
  orderList: IOrderDataViewModel[];
  progressOrderFunction: SubmitHandler;
  gameState: IGameState;
  console: string;
  text: ITextSet;
}

const RaiseInvoice: React.FC<Props> = ({ orderList, progressOrderFunction, gameState, console, text }) => {
  const [show, setShow] = useState(false);
  const [feedback, setFeedback] = useState(<Typography></Typography>);
  const [companyName, setCompanyName] = useState("");
  const [service, setService] = useState("");
  const [billableHours, setBillableHours] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const classes = useStyles();
  const appClasses = appStyles();

  const clearFields = (event?: any) => {
    setBillableHours("");
    setCompanyName("");
    setFeedback(<Typography></Typography>);
    setPurchaseOrder("");
    setInvoiceAmount("");
    setService("");
  };

  const updateCompanyName = (event: any) => {
    setCompanyName(event.target.value.toUpperCase());
  };

  const updateService = (event: any) => {
    if (!event.target.value) {
      return setService("");
    }
    setService(event.target.value[0].toUpperCase());
  };

  const updateBillableHours = (event: any) => {
    setBillableHours(event.target.value.replace(/\D/, ""));
  };

  const updatePurchaseOrder = (event: any) => {
    setPurchaseOrder(event.target.value.toUpperCase());
  };

  const updateInvoiceAmount = (event: any) => {
    setInvoiceAmount(event.target.value.replace(/\D/, ""));
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
      setFeedback(<Typography color="error">Cannot raise invoice - game has gone bust</Typography>);
      return false;
    }
    if (gameState.RunningState == "Finished Round" && gameState.Week >= 26) {
      setFeedback(<Typography color="error">Cannot raise invoice - level has finished</Typography>);
      return false;
    }

    if (gameState.RunningState != "Running") {
      setFeedback(<Typography color="error">Cannot raise invoice - Game not running</Typography>);
      return false;
    }

    if (!companyName || !service || !purchaseOrder || !billableHours || !invoiceAmount) {
      setFeedback(<Typography color="error">All fields are required</Typography>);
      return false;
    }

    setFeedback(<Typography></Typography>);

    const order = orderList.find((order) => order.CompanyName.toUpperCase() === companyName);

    const submission: string =
      "Company Name: " +
      companyName +
      ", Service: " +
      service +
      ", Billable Hours: " +
      billableHours +
      ",  Purchase Order: " +
      purchaseOrder +
      ",  Invoice Amount: " +
      invoiceAmount;
    const newDate: Date = new Date();

    if (!order || order.OrderStatus !== "Awaiting Invoicing") {
      provideFeedback("No such order.", true);

      const form: IPlayerEvent = {
        id: localStorage.getItem("guid") + newDate.toString(),
        GameId: localStorage.getItem("guid"),
        Console: console,
        Round: gameState.Round.toString(),
        Week: gameState.Week.toString(),
        Submission: `${submission} - check company name and order status`,
        Feedback: "No such order",
        DateTime: newDate,
      };
      recordFormSubmission(form);
      return;
    }

    if (
      order.ProductName !== service ||
      order.NumberOfUnits !== parseInt(billableHours) ||
      order.TotalCost !== parseInt(invoiceAmount) ||
      order.PurchaseOrder !== purchaseOrder
    ) {
      provideFeedback("Provided invoice details are incorrect.", true);
      const submissionProductName =
        order.ProductName === service ? service : `${service} (expected ${order.ProductName})`;
      const submissionNumberOfUnits =
        order.NumberOfUnits === parseInt(billableHours)
          ? billableHours
          : `${billableHours} (expected ${order.NumberOfUnits})`;
      const submissionTotalCost =
        order.TotalCost === parseInt(invoiceAmount) ? invoiceAmount : `${invoiceAmount} (expected ${order.TotalCost})`;
      const submissionPurchaseOrder =
        order.PurchaseOrder === purchaseOrder ? purchaseOrder : `${purchaseOrder} (expected ${order.PurchaseOrder})`;
      const submissionWithExpectedValues =
        "Company Name: " +
        companyName +
        ", Service: " +
        submissionProductName +
        ", Billable Hours: " +
        submissionNumberOfUnits +
        ",  Purchase Order: " +
        submissionPurchaseOrder +
        ",  Invoice Amount: " +
        submissionTotalCost;

      const form: IPlayerEvent = {
        id: localStorage.getItem("guid") + newDate.toString(),
        GameId: localStorage.getItem("guid"),
        Console: console,
        Round: gameState.Round.toString(),
        Week: gameState.Week.toString(),
        Submission: submissionWithExpectedValues,
        Feedback: "Provided invoice details are incorrect.",
        DateTime: newDate,
      };
      recordFormSubmission(form);
      return;
    }

    try {
      await progressOrderFunction(order.OrderNumber);
      provideFeedback("Success!", false);

      const form: IPlayerEvent = {
        id: localStorage.getItem("guid") + newDate.toString(),
        GameId: localStorage.getItem("guid"),
        Console: console,
        Round: gameState.Round.toString(),
        Week: gameState.Week.toString(),
        Submission: submission,
        Feedback: "Success!",
        DateTime: newDate,
      };
      recordFormSubmission(form);
      setCompanyName("");
      setPurchaseOrder("");
      setService("");
      setBillableHours("");
      setInvoiceAmount("");
    } catch {
      const form: IPlayerEvent = {
        id: localStorage.getItem("guid") + newDate.toString(),
        GameId: localStorage.getItem("guid"),
        Console: console,
        Round: gameState.Round.toString(),
        Week: gameState.Week.toString(),
        Submission: submission,
        Feedback: "Error progressing order.",
        DateTime: newDate,
      };
      provideFeedback("Error progressing order.", true);
    }

    function provideFeedback(message: string, error: boolean) {
      error
        ? setFeedback(<Typography color="error">{message}</Typography>)
        : setFeedback(<Typography style={{ color: "#3CBD89" }}>{message}</Typography>);
      setTimeout(() => setFeedback(<Typography></Typography>), 10000);
    }
  };

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 600,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      maxWidth: 600,
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <Paper className={classes.cheating}>
      <div className={appClasses.panelHeader}>
        <h2 className={appClasses.panelTitle}>Raise Invoice</h2>
      </div>
      <div className={classes.container}>
        <form noValidate autoComplete="off" className={classes.form}>
          <Grid container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                value={companyName}
                id="companyNameInput"
                onChange={updateCompanyName}
                autoFocus
                label={text.financeConsole.companyName}
                className={classes.field}
                onKeyDown={handleEnterPress}
                name={text.financeConsole.companyName.replace(" ", "")}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                value={service}
                id="productIdInput"
                onChange={updateService}
                label={text.financeConsole.product}
                className={classes.field}
                onKeyDown={handleEnterPress}
                name={text.financeConsole.product.replace(" ", "")}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                value={billableHours}
                id="billableHoursInput"
                onChange={updateBillableHours}
                label={text.financeConsole.billableHours}
                className={classes.field}
                onKeyDown={handleEnterPress}
                name={text.financeConsole.billableHours.replace(" ", "")}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                value={invoiceAmount}
                id="invoiceAmountInput"
                onChange={updateInvoiceAmount}
                label="Invoice Amount"
                className={classes.field}
                onKeyDown={handleEnterPress}
                name="InvoiceAmount"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                value={purchaseOrder}
                id="purchaseOrderInput"
                onChange={updatePurchaseOrder}
                label="Purchase Order"
                className={classes.field}
                onKeyDown={handleEnterPress}
                name="Purchase Order"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={controlsTheme}>
                  <Button
                    id="financeConsoleBtn"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    // disabled={
                    //   !companyName || !service || !purchaseOrder || !billableHours || !invoiceAmount || (gameState.RunningState == "Ready")
                    // }
                    className={classes.button}
                    name="Submit"
                  >
                    <Typography variant="subtitle2" component="p">
                      Submit
                    </Typography>
                  </Button>
                  <Button
                    id="financeConsoleClearBtn"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={clearFields}
                    className={classes.button}
                    name="Clear"
                  >
                    <Typography variant="subtitle2" component="p">
                      Clear
                    </Typography>
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
            </Grid>
            <Grid item xs={12} aria-live="polite">
              {feedback}
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
};

export default RaiseInvoice;
