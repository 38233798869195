import React from "react";
import { Grid, Select, MenuItem, InputLabel } from "@mui/material";
import { Box } from "@mui/material";
import { IGameState, IReferenceData } from "../../../../types/gameState.types";
import { setCapability } from "../../../../modules/gameState/service";

interface Props {
  referenceData: IReferenceData;
  gameState: IGameState;
}

export const Capabilities: React.FC<Props> = ({ referenceData, gameState }) => {
  return (
    <Grid container spacing={1}>
      {referenceData.Capabilities?.map((capability, index) => (
        <Grid item key={index} xs={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <h3>{capability}</h3>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <InputLabel id="level-0-label">Level 0</InputLabel>
                <Select
                  defaultValue={1}
                  style={{ margin: "0 5px" }}
                  onChange={(event) => setCapability(capability, 0, Number(event.target.value))}
                  value={gameState.CapabilityValues[capability][0]}
                >
                  {[1, 2, 3, 4, 5].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <InputLabel id="level-2-label">Level 2</InputLabel>
                <Select
                  defaultValue={1}
                  style={{ margin: "0 5px" }}
                  onChange={(event) => setCapability(capability, 1, Number(event.target.value))}
                  value={gameState.CapabilityValues[capability][1]}
                >
                  {[1, 2, 3, 4, 5].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
