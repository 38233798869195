import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import InvestmentReel from "../../../../components/reels/InvestmentReel";
import { applyInvestment, recordFormSubmission } from "../../../../modules/gameState/service";
import { IGameState, IReferenceData } from "../../../../types/gameState.types";
import { IPlayerEvent } from "../../../../types/playerEvents.types";
import { IFacilitatorConsoleText, ITextSet } from "../../../../types/text.types";
import controlsTheme from "./controlsTheme";
import appStyles from "../../../../assets/appStyles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  gameState: IGameState;
  referenceData: IReferenceData;
  text: ITextSet;
  console: string;
}

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(2),
  },
  container: {
    //display: 'flex',
    //justifyContent: 'space-between',
    padding: "10px 10px",
    // height: '20vh'
  },
  cheating: {
    height: "100%",
    padding: "1px 40px 10px 40px",
  },
  activeInvestment: {
    paddingTop: theme.spacing(1),
  },
}));

const InvestmentPanel: React.FC<Props> = (props) => {
  const { gameState, text } = props;
  const screen = props.console;

  const additionalInvestments = {
    REBRAND: {
      label: text.facilitatorConsole.rebrand,
      key: "REBRAND",
    },
    TRAINING: {
      label: text.facilitatorConsole.salesTraining,
      key: "SALES TRAINING",
    },
    EQUIPMENT: {
      label: text.facilitatorConsole.equipmentUpgrade,
      key: "EQUIPMENT UPGRADE",
    },
    OFFICE: {
      label: text.facilitatorConsole.cleanOffice,
      key: "CLEAN OFFICE",
    },
    CO2ElectricVehicles: {
      label: "CO2 ELECTRIFICATION OF VEHICLES",
      key: "CO2 ELECTRIFICATION OF VEHICLES",
    },
    CO2RenewableEnergy: {
      label: "CO2 RENEWABLE ENERGY",
      key: "CO2 RENEWABLE ENERGY",
    },
    CO2SRemoteWorking: {
      label: "CO2 REMOTE WORKING",
      key: "CO2 REMOTE WORKING",
    },
    CO2CommunityEngagement: {
      label: "CO2 BCORP",
      key: "CO2 BCORP",
    },
    CO2SolarHydro: {
      label: "CO2 SOLAR AND HYDRO",
      key: "CO2 SOLAR AND HYDRO",
    },
    CO2supplyChainReview: {
      label: "CO2 SUPPLY CHAIN REVIEW",
      key: "CO2 SUPPLY CHAIN REVIEW",
    },
    CO2ServicesReview: {
      label: "CO2 SERVICES REVIEW",
      key: "CO2 SERVICES REVIEW",
    },
    CO2DigitalOffice: {
      label: "CO2 DIGITAL OFFICE",
      key: "CO2 DIGITAL OFFICE",
    },
  };

  const classes = useStyles();
  const appClasses = appStyles();

  const [investmentToActivate, setInvestmentToActivate] = useState("");
  const [investmentFeedback, setInvestmentFeedback] = useState(<Typography> </Typography>);

  const currentBankBalance = gameState.BankValueList[gameState.BankValueList.length - 1];

  const applyAdditionalInvestment = async () => {
    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
      setInvestmentFeedback(<Typography color="error">Cannot apply initiative - game has gone bust</Typography>);
      return false;
    }
    if (gameState.RunningState == "Finished Round" && gameState.Week >= 26) {
      setInvestmentFeedback(<Typography color="error">Cannot apply initiative - level has finished</Typography>);
      return false;
    }
    if (gameState.RunningState != "Running") {
      setInvestmentFeedback(<Typography color="error"> Cannot apply initiative - Game not running</Typography>);
      return false;
    }
    if (gameState.AutoFac) {
      setInvestmentFeedback(<Typography color="error">Initiatives unavailable in demo.</Typography>);
      return false;
    }
    if (
      gameState.Investments.filter((i) => i.isActive)
        .map((i) => i.InvestmentType)
        .includes(investmentToActivate)
    ) {
      setInvestmentFeedback(<Typography color="error">Initiative already active.</Typography>);
      return false;
    }

    const newDate = new Date();
    const form: IPlayerEvent = {
      id: localStorage.getItem("guid") + newDate.toString(),
      GameId: localStorage.getItem("guid"),
      Console: screen,
      Round: gameState.Round.toString(),
      Week: gameState.Week.toString(),
      Submission: "Investment to Activate: " + text.mdConsole.investments[investmentToActivate],
      DateTime: newDate,
      Feedback: "",
    };

    try {
      await applyInvestment(investmentToActivate);
      const label = text.mdConsole.investments[investmentToActivate];
      setInvestmentToActivate(`SELECT ${text.wrapper.generic.investments}`);
      form.Feedback = `${label} ${text.facilitatorConsole.investmentOptionSelected}.`;
      recordFormSubmission(form);
      setInvestmentFeedback(
        <Typography
          style={{ color: "#3CBD89" }}
        >{`${label} ${text.facilitatorConsole.investmentOptionSelected}.`}</Typography>
      );
    } catch {
      form.Feedback = `Error selecting additional ${text.wrapper.generic.investments}`;
      recordFormSubmission(form);
      setInvestmentFeedback(<Typography color="error">Error selecting additional investment</Typography>);
      setInvestmentToActivate(`SELECT ${text.wrapper.generic.investments}`);
    }
  };

  const additionalInvestmentEnabled = () => {
    return (
      investmentToActivate &&
      // TODO dynamically calculate lower limit
      !(investmentToActivate === additionalInvestments.REBRAND.key && currentBankBalance < 10000) &&
      !(investmentToActivate === additionalInvestments.EQUIPMENT.key && currentBankBalance < -10000)
    );
  };

  const cancelAdditionalInvestment = () => {
    setInvestmentToActivate("");
  };

  const handleChange = (event: any) => {
    setInvestmentToActivate(event.target.value as string);
  };

  const activeInvestments = gameState.Investments.filter((i) => i.isActive);
  const investmentTape = `ACTIVE ${text.wrapper.generic.investments.toUpperCase()}: ${
    activeInvestments.length > 0
      ? activeInvestments.map((i) => text.mdConsole.investments[i.InvestmentType]).join(" - ")
      : "NONE"
  }`;

  const dataset = localStorage.getItem("dataset");

  return (
    <Paper className={classes.cheating}>
      <div className={appClasses.panelHeader}>
        <h2 className={appClasses.panelTitle}>{text.facilitatorConsole.investments}</h2>
      </div>
      <div className={classes.container}>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <FormControl style={{ minWidth: 320 }}>
              <InputLabel id="lblInvestment">{text.wrapper.generic.investment.toUpperCase()}</InputLabel>
              <Select
                label={text.wrapper.generic.investment.toUpperCase()}
                value={investmentToActivate}
                defaultValue={investmentToActivate}
                onChange={handleChange}
                name={text.wrapper.generic.investment.replace(" ", "")}
                labelId="lblInvestment"
              >
                {Object.keys(text.mdConsole.investments).map((i) => {
                  return (
                    <MenuItem key={i} value={i}>
                      {text.mdConsole.investments[i]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={controlsTheme}>
                <Button
                  disabled={
                    !additionalInvestmentEnabled() ||
                    gameState.RunningState == "Ready" ||
                    gameState.Round == 0 ||
                    investmentToActivate == `SELECT  ${text.wrapper.generic.investment.toUpperCase()}`
                  }
                  onClick={applyAdditionalInvestment}
                  variant="contained"
                  color="primary"
                  name={text.facilitatorConsole.apply.replace(" ", "")}
                >
                  <Typography variant="subtitle2" component="p">
                    {text.facilitatorConsole.apply}
                  </Typography>
                </Button>
              </ThemeProvider>
            </StyledEngineProvider>
          </Grid>
          <Grid item xs={8}>
            {investmentFeedback}
          </Grid>
          <Grid xs={12} className={classes.activeInvestment} item>
            <InvestmentReel investmentTape={investmentTape}></InvestmentReel>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default React.memo(InvestmentPanel);
