import { Box, Grid, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as CSS from "csstype";
import IframeResizer from "iframe-resizer-react";
import React, { useEffect, useState } from "react";
import { IScreenProps } from "../../../types/app.types";
import "../facilitator/mainScreen.css";
import "./Videos.css";
import { useNavigate, useLocation } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
}));
const Handbook: React.FC<IScreenProps> = ({ gameState, text }) => {
  document.title = `Handbook | ${text.wrapper.gameName}`;

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const verticalBoxStyle: CSS.Properties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const [showPracVideo, setShowPracVideo] = useState(false);
  const [showAPassVideo, setShowAPassVideo] = useState(false);
  const [showAFailVideo, setShowAFailVideo] = useState(false);
  const [showBPassVideo, setShowBPassVideo] = useState(false);
  const [showBFailVideo, setShowBFailVideo] = useState(false);
  const [key, setKey] = useState("VideoOne");
  //const handbook = "https://etherpadtest2.azurewebsites.net/doc/handbook-" + localStorage.getItem("guid")
  const handbook = process.env.REACT_APP_ENV_ETHERPAD + "/doc/p/Handbook-" + localStorage.getItem("guid");

  useEffect(() => {
    if (gameState.Round == 1 && (gameState.Week == 26 || gameState.BankCurrentNumeric < -60000)) {
      setShowPracVideo(true);
    }
    if (gameState.Round == 2 && gameState.Week == 26 && gameState.BankCurrentNumeric > -60000) {
      setShowAPassVideo(true);
    }
    if (gameState.Round == 2 && gameState.BankCurrentNumeric < -60000) {
      setShowAFailVideo(true);
    }
    if (gameState.Round == 3 && gameState.Week >= 26 && gameState.BankCurrentNumeric > -60000) {
      setShowBPassVideo(true);
    }
    if (gameState.Round == 3 && gameState.BankCurrentNumeric < -60000) {
      setShowBFailVideo(true);
    }
  }, [gameState.BankCurrentNumeric]);

  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <React.Fragment>
      <Grid container className={classes.cont}>
        <Grid item xs={12}>
          <Button className={classes.button} variant="outlined" onClick={() => window.open(handbook, "_blank")}>
            Open in new Tab
          </Button>
        </Grid>
        <Grid item xs={12}>
          <IframeResizer
            log
            src={handbook}
            style={{
              width: "1px",
              minWidth: "80vw",
              height: "1px",
              minHeight: "85vh",
            }}
            title="Handbook"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Handbook;
