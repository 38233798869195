import { useEffect, useState } from "react";
import { IPlayerEvents } from "../types/playerEvents.types";
import { getPlayerEvents } from "../modules/referenceData/service";

function usePlayerEvents(): IPlayerEvents | null {
  const [playerEvents, setPlayerEvents] = useState(null);

  useEffect(() => {
    async function retrieve() {
      const data = await getPlayerEvents();
      setPlayerEvents(data);
    }

    retrieve();
  }, []);

  return playerEvents;
}

export default usePlayerEvents;
