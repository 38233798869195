import React, { useState, useCallback, useEffect } from "react";
import JoyRide, { ACTIONS, LIFECYCLE, STATUS } from "react-joyride";
import { IWrapperText } from "../../types/text.types";
import { Box, Paper, useTheme } from "@mui/material";
import { RunCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IGameState } from "../../types/gameState.types";
import YouTube from "react-youtube";

interface Props {
  text: IWrapperText;
  run: boolean;
  setRun: (val: boolean) => void;
}

// Tour component
const DemoWelcomeTour: React.FC<Props> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const defaultOptions = {
    arrowColor: "#fff",
    backgroundColor: "#fff",
    beaconSize: 36,
    overlayColor: "rgba(0, 0, 0, 0.5)",
    primaryColor: theme.palette.primary.main,
    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
    textColor: "#333",
    width: undefined,
    zIndex: 100,
  };

  const videoOptions = {
    height: "315",
    width: "420",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const TOUR_STEPS = [
    {
      target: ".tourMain",
      content: <YouTube videoId="5Jehps-0APc" opts={videoOptions} />,
      title: `Welcome to ${props.text.gameName}`,
      styles: {
        //this styles override the styles in the props
        options: {
          //width of modal
          width: "600",
        },
      },
      disableBeacon: true,
    },
    {
      target: ".tourHandbook",
      content: (
        <Box>
          <h3>Handbook</h3>
          <p>
            A good place to start is the company handbook. Here you&apos;ll find details on the roles and how to run
            your business.
          </p>
        </Box>
      ),
    },
    {
      target: ".tourRoles",
      content: (
        <Box>
          <h3>Roles</h3>
          <p>
            Once you have all read the handbook, decide who is going to do what in the business. You need at least one
            person handling each role.
          </p>
        </Box>
      ),
    },
    {
      target: ".tourHelp",
      content: (
        <Box>
          <h3>Need Help?</h3>
          <p>Ask the RocketBot...</p>
        </Box>
      ),
    },
    {
      target: ".tourReady",
      content: (
        <Box>
          <h3>Ready to Start?</h3>
          <p>Tick the I&apos;m Ready box. Once 5 players are ready the game countdown will start.</p>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const storedRunStatus = localStorage.getItem("tourStatus");

    if (storedRunStatus === null) {
      props.setRun(true);
      localStorage.setItem("tourStatus", "true");
    } else {
      props.setRun(storedRunStatus === "true");
    }
  }, []);

  const callback = (data: any) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem("tourStatus", "false");
      props.setRun(false);
    }

    if (data.status === STATUS.RUNNING && data.action === ACTIONS.START && data.lifecycle === LIFECYCLE.READY) {
      console.log("DemoWelcomeTour data", data);
      // navigate("/game/main");
    }
  };

  return (
    <>
      <JoyRide
        steps={TOUR_STEPS}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        run={props.run}
        styles={{
          options: defaultOptions,
        }}
        callback={callback}
      />
    </>
  );
};
export default DemoWelcomeTour;
