import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";

interface Props {
  duration: number;
  children: React.ReactNode;
}

const Tickertape: React.FC<Props> = (props) => {
  const { children, duration } = props;

  const useStyles = makeStyles((theme) => ({
    marquee: {
      margin: "0 auto",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    marqueeSpan: {
      // display: 'inline-block',
      // paddingLeft: '100%',
      animation: `$marquee ${duration}s linear infinite`,
    },
    "@keyframes marquee": {
      "0%": {
        transform: "translate(0, 0)",
      },
      "100%": {
        transform: "translate(-100%, 0)",
      },
    },
  }));

  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <div className={classes.marquee}>
        <div className={classes.marqueeSpan}>{children}</div>
      </div>
    </React.Fragment>
  );
};

export default Tickertape;
