import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../context";
import { ReportRouter } from "../routing/ReportRouter";
import useDynamicText from "../hooks/useDynamicText";

import {
  TradingReportRoute,
  CashReportRoute,
  ProductionReportRoute,
  LeadToCashReportRoute,
  Co2ReportRoute,
  FinalReportRoute,
} from "../routing/routes";

import Loading from "../components/loading/Loading";
import { Grid, Button, Theme, Paper, Box, Tabs, Typography, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { StyledTabs, StyledTab } from "../components/tabs/Tabs";

const useStyles = makeStyles((theme: Theme) => ({
  grid: {},
}));

const ReportContainer: React.FC = () => {
  const { pathname } = useLocation();
  const baseReportUrl = "/game/reports";
  const [value, setValue] = useState(baseReportUrl + TradingReportRoute.toUrl());
  const { state, dispatch } = useContext(AppContext);

  const handleChange = (event: any, newValue: any) => {
    navigate(newValue);
    setValue(newValue);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const text = useDynamicText();
  const classes = useStyles();

  if (!text) {
    return <Loading />;
  }

  const dataset = localStorage.getItem("dataset");

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12}>
        <Paper className={classes.grid}>
          <Box sx={{ borderBottom: 1, borderColor: "primary" }}>
            <StyledTabs value={value} variant="fullWidth" onChange={handleChange}>
              <StyledTab label={"Trading Report"} value={baseReportUrl + TradingReportRoute.toUrl()} tabIndex={1} />
              <StyledTab label={"Cash Report"} value={baseReportUrl + CashReportRoute.toUrl()} tabIndex={1} />
              <StyledTab label={"Process"} value={baseReportUrl + LeadToCashReportRoute.toUrl()} tabIndex={1} />
              {dataset?.includes("gogreen") ? (
                <StyledTab label={"CO2 Report"} value={baseReportUrl + Co2ReportRoute.toUrl()} tabIndex={1} />
              ) : null}
              <StyledTab
                label={text.productionReport.title}
                value={baseReportUrl + ProductionReportRoute.toUrl()}
                tabIndex={1}
              />
              {dataset?.includes("culture") && state.gameState.RunningState == "Finished Game" ? (
                <StyledTab label={"Final Report"} value={baseReportUrl + FinalReportRoute.toUrl()} tabIndex={1} />
              ) : null}
            </StyledTabs>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ReportRouter />
      </Grid>
    </Grid>
  );
};

export default ReportContainer;
