import axios from "axios";
import config from "../../config";

export const referenceDataAxios = (() => {
  const referenceDataAxios = axios.create({
    baseURL: config.locations.gameStateAPI,
  });

  referenceDataAxios.interceptors.request.use((config) => {
    config.baseURL += `/${localStorage.getItem("dataset")}`;
    return config;
  });
  referenceDataAxios.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_GAME_STATE_API_KEY ?? "";

  return referenceDataAxios;
})();
