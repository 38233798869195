import { useEffect, useState, useContext, useRef } from "react";
import { getGameStatus, getGameStatusLastUpdated } from "../modules/gameState/service";
import { IGameState } from "../types/gameState.types";
import { AppContext } from "../context";
import hash from "object-hash";

// TODO split IGameState into console specific sub-states and return dynamically from API & hook
// The interfaces for these can be grouped into a top level super-interface like ITextSet.
// Finally, use the following function signature to accept any of the sub object keys as input to this hook

// function useDynamicText<K extends keyof IGameStates>(role: K, delay: number): IGameStates[K] | null {

function useGameState(delay: number): IGameState | null {
  const [gameState, setGameState] = useState({} as IGameState);
  const { state, dispatch } = useContext(AppContext);
  const [gameStateLastUpdated, setGameStateLastUpdated] = useState<Date | null>(new Date());
  const [updateGameState, setUpdateGameState] = useState(0);

  const [weekHash, setWeekHash] = useState("");
  const [ordersHash, setOrdersHash] = useState("");
  const [runningStateHash, setRunningStateHash] = useState("");
  const [activeMarketing, setActiveMarketing] = useState("");
  const [investmentsHash, setInvestmentsHash] = useState("");
  const [availableMarketingLeadsHash, setAvailableMarketingLeadsHash] = useState("");
  const [capabilitiesHash, setCapabilitiesHash] = useState("");

  const calculateHash = (object: any) => hash(object);

  const request = async () => {
    const newState = await getGameStatus();

    if (newState) {
      const currWeekHash = calculateHash(newState.Week);
      const currOrdersHash = calculateHash(newState.VisibleOrderList);
      const currRunningHash = calculateHash(newState.RunningState);
      const currActiveMarketing = calculateHash(newState.ActiveCampaign + newState.ActiveAudience);
      const currInvestmentHash = calculateHash(newState.Investments);
      const currAvailableMarketingLeadsHash = calculateHash(newState.AvailableMarketingLeads);
      const currCapabilitiesHash = calculateHash(newState.CapabilityValues);

      const isNew =
        currWeekHash !== weekHash ||
        ordersHash !== currOrdersHash ||
        currRunningHash !== runningStateHash ||
        currActiveMarketing !== activeMarketing ||
        currInvestmentHash !== investmentsHash ||
        currAvailableMarketingLeadsHash !== availableMarketingLeadsHash ||
        currCapabilitiesHash !== capabilitiesHash;

      if (isMounted.current && isNew) {
        setGameState(newState);
        dispatch({ type: "SET_GAMESTATE", payload: newState });

        if (state.rktCode !== localStorage.getItem("guid")) {
          dispatch({
            type: "SET_RKTCODE",
            payload: localStorage.getItem("guid"),
          });
        }

        setWeekHash(currWeekHash);
        setOrdersHash(currOrdersHash);
        setRunningStateHash(currRunningHash);
        setActiveMarketing(currActiveMarketing);
        setInvestmentsHash(currInvestmentHash);
        setAvailableMarketingLeadsHash(currAvailableMarketingLeadsHash);
        setCapabilitiesHash(currCapabilitiesHash);
      }
    }
  };

  const tick = async () => {
    const lastUpdated = new Date(state.gameState.LastUpdated);

    const totalSecondsSinceLevelStart = state.gameState.Week * 60 + state.gameState.SecondsElapsedInWeek;
    const totalSecondsSinceLastUpdate =
      state.gameState.RunningState !== "Running"
        ? 0
        : Math.floor((new Date().getTime() - lastUpdated.getTime()) / 1000);
    const minutes = Math.floor((totalSecondsSinceLevelStart + totalSecondsSinceLastUpdate) / 60);
    const seconds = totalSecondsSinceLevelStart + totalSecondsSinceLastUpdate - minutes * 60;

    if (isMounted.current && state.gameState.RunningState === "Running" && (seconds === 30 || seconds === 0)) {
      setUpdateGameState((prevState) => prevState + 1);
    }
  };

  const isMounted = useRef(true);
  useEffect(() => {
    const id = setInterval(tick, 1000);
    return () => {
      clearInterval(id);
    };
  }, [state.gameState.LastUpdated]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    request().catch(console.error);
  }, [state.gameStateLastUpdated, updateGameState]);

  return gameState;
}

export default useGameState;
