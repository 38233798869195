import React from "react";

import PrivateRoute from "./PrivateRoute";
import ScreenContainer from "../containers/ScreenContainer";

import { IScreenProps } from "../types/app.types";

interface Props {
  path: string;
  component: React.FC<IScreenProps>;
  togglePanel?: any;
  sidebarExpanded?: boolean;
  panelOpen?: boolean;
}

const ScreenRoute: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <ScreenContainer
        component={props.component}
        togglePanel={props.togglePanel}
        panelOpen={props.panelOpen}
        sidebarExpanded={props.sidebarExpanded}
      />
    </React.Fragment>
  );
};

export default ScreenRoute;
