import { Divider, List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  AccountBalance,
  Assessment,
  Build,
  Business,
  BusinessCenter,
  Call,
  ExitToApp,
  Home,
  MenuBook,
  RingVolume,
  Games,
  Sports,
  SportsEsports,
  ChevronLeft,
  ChevronRight,
  MenuOpen,
  Menu,
} from "@mui/icons-material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
// import './sidebar.css'
import { EtherpadOPSRoute, EtherpadOTSRoute, ReportsBaseRoute } from "../../routing/routes";
import { IRole } from "../../types/app.types";
import { IGameState } from "../../types/gameState.types";
import { IWrapperText, ITextSet } from "../../types/text.types";
import { Md5 } from "ts-md5";
import AccessibleTooltip from "../accessibleTooltip/AccessibleTooltip";

interface Props {
  role: IRole;
  text: ITextSet;
  gameState: IGameState;
  sidebarExpanded: boolean;
  textSidebar: boolean;
  togglePanel: any;
  toggleSidebarExpanded: any;
}

const Sidebar: React.FC<Props> = (props) => {
  const { role, gameState, text, sidebarExpanded, textSidebar, togglePanel, toggleSidebarExpanded } = props;

  //const docs = useHandbookDoc();

  const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      //height: "100%"
    },
    header: {
      paddingLeft: theme.spacing(2),
      textAlign: "left",
    },
    image: {
      padding: theme.spacing(1),
    },
    anchor: {
      textDecoration: "none",
      color: "black",
    },
    logout: {
      marginTop: "auto",
    },
    visibleHidden: {
      clip: "rect(1px, 1px, 1px, 1px)",
      height: "1px",
      overflow: "hidden",
      position: "absolute",
      whiteSpace: "nowrap",
      width: "1px",
      "&:focus": {
        clip: "auto",
        height: "auto",
        overflow: "auto",
        position: "absolute",
        width: "auto",
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      justifyContent: "flex-end",
    },
    item: {
      padding: "8px",
    },
  }));

  const classes = useStyles();

  const { pathname } = useLocation();

  const dataset = localStorage.getItem("dataset");
  const hashedDataset = dataset !== null ? Md5.hashStr(dataset) : "";

  return (
    <div className={classes.wrapper} role={"navigation"}>
      <a href="#mainContainer" className={classes.visibleHidden}>
        Skip to main content
      </a>
      {/* <List> */}

      <ListItem onClick={toggleSidebarExpanded} button>
        <ListItemIcon>{sidebarExpanded ? <MenuOpen /> : <Menu />}</ListItemIcon>
        <ListItemText primary={<Typography color="primary">{textSidebar ? "Collapse" : ""}</Typography>} />
      </ListItem>
      {localStorage.getItem("authFac") == hashedDataset && (
        <AccessibleTooltip title={text.wrapper.sidebar.controls}>
          <ListItem button selected={pathname == "/game/controls"} component={Link} to="/game/controls">
            <ListItemIcon>
              <Games />
            </ListItemIcon>
            <ListItemText
              primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.controls : ""}</Typography>}
            />
          </ListItem>
        </AccessibleTooltip>
      )}

      <AccessibleTooltip title={text.wrapper.sidebar.summary}>
        <ListItem button selected={pathname == "/game/main"} component={Link} to="/game/main">
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.summary : ""}</Typography>}
          />
        </ListItem>
      </AccessibleTooltip>
      <div className="tourRoles">
        <AccessibleTooltip title={text.wrapper.sidebar.marketing}>
          <ListItem button selected={pathname == "/game/marketing"} component={Link} to="/game/marketing">
            <ListItemIcon>
              <RingVolume />
            </ListItemIcon>
            <ListItemText
              primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.marketing : ""}</Typography>}
            />
          </ListItem>
        </AccessibleTooltip>

        <AccessibleTooltip title={text.wrapper.sidebar.sales}>
          <ListItem button selected={pathname == "/game/sales"} component={Link} to="/game/sales">
            <ListItemIcon>
              <Call />
            </ListItemIcon>
            <ListItemText
              primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.sales : ""}</Typography>}
            />
          </ListItem>
        </AccessibleTooltip>

        <AccessibleTooltip title={text.wrapper.sidebar.ceo}>
          <ListItem button selected={pathname == "/game/ceo"} component={Link} to="/game/ceo">
            <ListItemIcon>
              <BusinessCenter />
            </ListItemIcon>
            <ListItemText
              primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.ceo : ""}</Typography>}
            />
          </ListItem>
        </AccessibleTooltip>

        <AccessibleTooltip title={text.wrapper.sidebar.delivery}>
          <ListItem button selected={pathname == "/game/production"} component={Link} to="/game/production">
            <ListItemIcon>
              <Build />
            </ListItemIcon>
            <ListItemText
              primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.delivery : ""}</Typography>}
            />
          </ListItem>
        </AccessibleTooltip>

        <AccessibleTooltip title={text.wrapper.sidebar.finance}>
          <ListItem button selected={pathname == "/game/finance"} component={Link} to="/game/finance">
            <ListItemIcon>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText
              primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.finance : ""}</Typography>}
            />
          </ListItem>
        </AccessibleTooltip>
      </div>
      {/* <Divider />
                {textSidebar ?
                    <div className={classes.header}>
                        <h4>{text.wrapper.sidebar.resources}</h4>
                    </div>
                    :
                    <div className={classes.header}>
                        <h4>{text.wrapper.sidebar.resourcesMinimal}</h4>
                    </div>
                } */}

      <AccessibleTooltip
        title={gameState.Round == 0 ? text.wrapper.sidebar.reportsDisabled : text.wrapper.sidebar.reports}
      >
        <ListItem
          button
          selected={pathname.includes("reports")}
          component={Link}
          to="/game/reports/trading"
          disabled={gameState.Round == 0}
        >
          <ListItemIcon>
            <Assessment />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.reports : ""}</Typography>}
          />
        </ListItem>
      </AccessibleTooltip>

      <AccessibleTooltip title={text.wrapper.sidebar.handbook}>
        <ListItem
          button
          selected={pathname == "/game/handbook"}
          component={Link}
          to="/game/handbook"
          className="tourHandbook"
        >
          <ListItemIcon>
            <MenuBook />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="primary">{textSidebar ? text.wrapper.sidebar.handbook : ""}</Typography>}
          />
        </ListItem>
      </AccessibleTooltip>

      <AccessibleTooltip title={text.wrapper.sidebar.opportunityTrackingTooltip}>
        <ListItem button selected={pathname == EtherpadOTSRoute.toUrl()} component={Link} to="/game/ots">
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography color="primary">{textSidebar ? text.wrapper.sidebar.opportunityTracking : ""}</Typography>
            }
          />
        </ListItem>
      </AccessibleTooltip>

      <AccessibleTooltip title={text.wrapper.sidebar.operationsPlanningTooltip}>
        <ListItem button selected={pathname == EtherpadOPSRoute.toUrl()} component={Link} to="/game/ops">
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography color="primary">{textSidebar ? text.wrapper.sidebar.operationsPlanning : ""}</Typography>
            }
          />
        </ListItem>
      </AccessibleTooltip>
      {/* </List> */}
    </div>
  );
};

export default Sidebar;
