import { Button, Divider, Grid, InputAdornment, InputLabel, Paper, TextField, Typography } from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { useState } from "react";
import controlsTheme from "../../pages/consoles/facilitator/controlPanels/controlsTheme";
import { IGameState, ILeadViewModel } from "../../types/gameState.types";
import { ITextSet } from "../../types/text.types";
import SalesSearchBox from "../salesSearchBox/SalesSearchBox";
import { IPlayerEvent } from "../../types/playerEvents.types";
import { recordFormSubmission } from "../../modules/gameState/service";
import appStyles from "../../assets/appStyles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Labels {
  title: string;
  enterOrderNumber: string;
  lastThreeNumbers: string;
  submit: string;
  clear: string;
  feedback: string;
}

interface ChangeHandler {
  (event: any): void;
}

interface SubmitHandler {
  (value: any): void;
}

interface ClearHandler {
  (): void;
}

interface Props {
  labels: Labels;
  text: ITextSet;
  role: "md" | "sales";
  gameState: IGameState;
  currencyParser: Intl.NumberFormat;
}

const useStyles = makeStyles((theme) => ({
  container: {
    // display: 'flex',
    // justifyContent: 'space-between',
    padding: "10px 10px 20px",
  },
  cheating: {
    paddingTop: "1px",
    height: "100%",
    padding: "10px 20px",
  },
  searchCell: {
    padding: theme.spacing(0.1),
    textAlign: "center",
  },
  button: {
    margin: "1px",
  },
}));

const SalesOrderSearch: React.FC<Props> = (props) => {
  const { labels, gameState, text, currencyParser } = props;
  const classes = useStyles();
  const appClasses = appStyles();
  const [searchInput, setSearchInput] = useState("");
  const [retrievedOrderNumber, setRetrievedOrderNumber] = useState("");
  const [feedback, setFeedback] = useState(<Typography>&nbsp;</Typography>);

  const updateOnNumericInput = (event: any) => {
    setSearchInput(event.target.value.replace(/\D/, ""));
  };
  const searchableLeads: ILeadViewModel[] = gameState.AvailableSalesSearchableLeads.concat(
    gameState.AvailableSalesRepeatLeads
  );

  const retrieveOrder = () => {
    if (!gameState) return;

    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
      setFeedback(<Typography color="error">Cannot search order - game has gone bust</Typography>);
      return false;
    }
    if (gameState.RunningState == "Finished Round" && gameState.Week >= 26) {
      setFeedback(<Typography color="error">Cannot search order - level has finished</Typography>);
      return false;
    }
    if (gameState.RunningState != "Running") {
      setFeedback(<Typography color="error">Cannot search order - Game not running</Typography>);
      return false;
    }

    setFeedback(<Typography>&nbsp;</Typography>);
    let orderNumber = searchInput;
    const newDate = new Date();
    const form: IPlayerEvent = {
      id: localStorage.getItem("guid") + newDate.toString(),
      GameId: localStorage.getItem("guid"),
      Console: "Sales",
      Round: gameState.Round.toString(),
      Week: gameState.Week.toString(),
      Submission: orderNumber,
      DateTime: newDate,
      Feedback: "",
    };
    orderNumber = "GOA00" + searchInput;

    if (!searchableLeads.some((l) => l.OrderNumber === orderNumber)) {
      setFeedback(<Typography color="error">Feedback: {text.salesConsole.invalidOrderNumber}</Typography>);
      setTimeout(() => setFeedback(<Typography>&nbsp; </Typography>), 10000);
      form.Feedback = text.salesConsole.invalidOrderNumber;
      recordFormSubmission(form);
    }
    recordFormSubmission(form);
    setRetrievedOrderNumber(orderNumber);
    setSearchInput("");
  };

  const cancelOrderRetrieval = () => {
    setSearchInput("");
    retrieveOrder();
  };

  const handleSubmit = () => {
    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
      setFeedback(<Typography color="error">Feedback: Cannot retrieve order - game has gone bust</Typography>);
      return false;
    }
    if (gameState.RunningState == "Finished Round" && gameState.Week >= 26) {
      setFeedback(<Typography color="error">Feedback: Cannot retrieve order - level has finished</Typography>);
      return false;
    }
    retrieveOrder();
    cancelOrderRetrieval();
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  const lead = searchableLeads.find((lead) => lead?.OrderNumber === retrievedOrderNumber);

  return (
    <Paper className={classes.cheating}>
      <div className={appClasses.panelHeader}>
        <h2 className={appClasses.panelTitle}>{labels.title}</h2>
      </div>
      <Grid container spacing={6} className={classes.container}>
        <Grid item md={4} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">GOA00</InputAdornment>,
                }}
                value={searchInput}
                id="salesSearchInput"
                onChange={updateOnNumericInput}
                onKeyDown={handleEnterPress}
                label={labels.enterOrderNumber}
                aria-label={"Order Number"}
                name={"OrderNumber"}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={controlsTheme}>
                  <Button
                    id="mdAuthBtn"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={
                      (gameState.RunningState == "Finished Round" && gameState.Week == 26) ||
                      gameState.RunningState == "Ready"
                    }
                    className={classes.button}
                    name={labels.submit}
                  >
                    <Typography component="p" variant="subtitle2">
                      {labels.submit}
                    </Typography>
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={cancelOrderRetrieval}
                    disabled={gameState.RunningState == "Ready"}
                    className={classes.button}
                    name={labels.clear}
                  >
                    <Typography component="p" variant="subtitle2">
                      {labels.clear}
                    </Typography>
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography component="p" variant="subtitle1" color="textPrimary">
                <strong>{lead?.OrderNumber}</strong>
                {lead?.ContactForename} {lead?.ContactSurname} {lead ? "at" : "Order Details"} {lead?.CompanyName}
              </Typography>
              <Divider />
            </Grid>
            <Grid item md={4} xs={12}>
              <SalesSearchBox title={text.salesConsole.product} value={lead?.ProductName ?? "-"} />
            </Grid>
            <Grid item md={4} xs={12}>
              <SalesSearchBox title={text.salesConsole.unit} value={lead?.NumberOfUnits ?? "-"} />
            </Grid>
            <Grid item md={4} xs={12}>
              <SalesSearchBox
                title={text.salesConsole.orderValue}
                value={lead ? currencyParser.format(lead.TotalOrderValue) : "-"}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SalesSearchBox title={text.salesConsole.requiredWithin} value={lead?.RequiredWithinWeeks ?? "-"} />
            </Grid>
            <Grid item md={4} xs={12}>
              <SalesSearchBox
                title={text.salesConsole.lateDeliveryFine}
                value={lead?.CompensationForLateDelivery ?? "-"}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SalesSearchBox title={text.salesConsole.purchaseOrder} value={lead?.PurchaseOrder ?? "-"} />
            </Grid>
          </Grid>
          <Grid item xs={12} aria-live="polite">
            {feedback}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SalesOrderSearch;
