import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Paper, Typography } from "@mui/material";
import clsx from "clsx";
import { IProductionItemViewModel, IGameState } from "../../../../../types/gameState.types";
import { IProductionConsoleText, ITextSet } from "../../../../../types/text.types";
import StatBox from "../../../../../components/statBox/StatBox";
import ProductionPanel from "../ProductionPanel/ProductionPanel";
import appStyles from "../../../../../assets/appStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  cheating: {
    paddingTop: "1px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  innerText: {
    textAlign: "center",
  },
  margin: {
    marginBottom: "1vh",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  padding: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  fullHeight: {
    height: "100%",
  },
}));

interface Props {
  title: string;
  text: IProductionConsoleText;
  currentProductionItem: IProductionItemViewModel;
  lastCompletedItem: IProductionItemViewModel;
  variation?: "minimal" | "normal" | undefined;
  prodText: ITextSet;
  gameState: IGameState;
  queue: number;
}

const ProductionQueueStatus: React.FC<Props> = ({
  text,
  currentProductionItem,
  title,
  lastCompletedItem,
  variation,
  gameState,
  prodText,
  queue,
}) => {
  const classes = useStyles();
  const appClasses = appStyles();

  const orderStateTextMap: any = {
    "IN PRODUCTION": text.inProduction,
    "SETTING UP": text.settingUp,
    QUEUED: text.queued,
    "-": "-",
  };

  return (
    <Paper className={classes.cheating}>
      <div className={appClasses.panelHeader}>
        <h2 className={appClasses.panelTitle}>{title}</h2>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.fullHeight}>
            <Grid item xs={12}>
              <Grid container spacing={1} className={classes.fullHeight}>
                <Grid item md={3} xs={6}>
                  <StatBox value={currentProductionItem.OrderNumber} title={text.orderNumber} />
                </Grid>
                <Grid item md={2} xs={6}>
                  <StatBox value={currentProductionItem.Product} title={text.product} />
                </Grid>
                <Grid item md={4} xs={6}>
                  <StatBox value={orderStateTextMap[currentProductionItem.Status]} title={text.status} />
                </Grid>
                <Grid item md={3} xs={6}>
                  <StatBox
                    value={`${currentProductionItem.WeeksRemainingInState} ${text.weeks}`}
                    title={text.timeRemaining}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} xs={12} className={classes.fullHeight}>
          <ProductionPanel gameState={gameState} text={prodText} queue={queue} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>{text.recentlyCompletedOrders}</Typography>
          <Typography>
            {text.justNow} - {lastCompletedItem.OrderNumber}
          </Typography>
          <Typography>
            {text.product} - {lastCompletedItem.Product}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductionQueueStatus;
