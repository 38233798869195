// import './mainScreen.css'
// import * as CSS from 'csstype'
import { CssBaseline, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { People, PhoneInTalk } from "@mui/icons-material";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import BankBalanceChart from "../../../components/bankBalanceChart/BankBalanceChart";
import BankCO2BalanceChart from "../../../components/bankBalanceChart/bankCO2BalanceChart";
import Co2Stats from "../../../components/co2Stats/Co2Stats";
import NpsStats from "../../../components/npsStats/NpsStats";
import NewsReel from "../../../components/newsReel/NewsReel";
import StatBox from "../../../components/statBox/StatBox";
import { IScreenProps } from "../../../types/app.types";
import CurrentOrderStatus from "./components/CurrentOrderStatus";
import SecondsWrapper from "../../../components/secondsWrapper/SecondsWrapper";
import Tickertape from "../../../components/tickertape/tickertape";
import InvestmentReel from "../../../components/reels/InvestmentReel";
import { AppContext } from "../../../context";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
  padding: {
    maxWidth: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    //display: 'flex',
    overflow: "auto",
    //flexDirection: 'column',
    // height: '60vh',
  },
  orderListImg: {
    width: "2.5vh",
    marginTop: "-0.8vh",
  },
  orderListSpan: {
    marginLeft: "1vh",
    fontSize: "2vh",
  },
  fixedHeight: {
    //height: '80vh',
    width: "100%",
  },
  grid: {
    padding: theme.spacing(3),
  },
  newsGrid: {
    // padding: theme.spacing(3),
    // height: '85vh',
    maxHeight: "85vh",
  },
  main: {
    // backgroundColor: theme.palette.secondary.main,
  },
  news: {
    // backgroundColor: theme.palette.secondary.light,
    height: "100%",
  },
  bankBal: {
    marginBottom: theme.spacing(1),
    // maxWidth: !useMediaQuery(theme.breakpoints.up("sm")) ? '116vh' : 'auto' // TODO
  },
  fullHeight: {
    height: "100%",
    // backgroundColor: theme.palette.secondary.main,
  },
  verticalDivider: {
    margin: "10px 0 10px -1px",
  },
  noVerticalPadding: {
    padding: "0 12px !important",
    width: "10vw",
  },
}));

const MainScreen: React.FC<IScreenProps> = ({ gameState, text, referenceData, currencyParser, numberParser }) => {
  document.title = `Summary | ${text.wrapper.gameName}`;

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const activeInvestments = gameState.Investments.filter((i) => i.isActive);
  const investmentTape = `ACTIVE ${text.wrapper.generic.investments.toUpperCase()}: ${
    activeInvestments.length > 0
      ? activeInvestments.map((i) => text.mdConsole.investments[i.InvestmentType]).join(" - ")
      : "NONE"
  }`;
  const dataset = localStorage.getItem("dataset");
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);

  return (
    <div className={classes.padding}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={2}>
          <Grid container>
            <Grid item md={12} sm={4} xs={12}>
              <StatBox
                value={gameState.Round !== 0 ? "Level " + gameState.Round : "WALKAROUND"}
                title={text.facilitatorConsole.currentLevel}
                colorCust="white"
                backgroundColor={theme.palette.primary.main}
              />
            </Grid>
            <Grid item md={12} sm={4} xs={12} className="tourWeek">
              <SecondsWrapper text={text.gameScreen.currentWeek} week={gameState.Week} />
            </Grid>
            <Grid item md={12} sm={4} xs={12}>
              <StatBox
                value={gameState.WeeksInRound - gameState.Week}
                title={text.gameScreen.weeksRemaining}
                colorCust="white"
                backgroundColor={theme.palette.primary.main}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginBottom: "10px" }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <StatBox
                value={currencyParser.format(gameState.TotalSales)}
                title={text.gameScreen.totalSales}
                variant="minimal"
                backgroundColor="#FFCB4E"
              />

              <StatBox
                value={currencyParser.format(gameState.TotalCommission == 0 ? 0 : gameState.TotalCommission)}
                title={text.gameScreen.commissionPaid}
                variant="minimal"
                backgroundColor="#FFCB4E"
              />

              <StatBox
                value={gameState.NewOrders}
                title={text.gameScreen.newOrdersTaken}
                variant="minimal"
                backgroundColor="#FFCB4E"
              />

              <StatBox
                value={gameState.CompletedOrders}
                title={text.gameScreen.ordersCompleted}
                variant="minimal"
                backgroundColor="#FFCB4E"
              />
            </Grid>
            {dataset?.includes("gogreen") ? (
              <React.Fragment>
                <Grid item xs={12}>
                  <Divider style={{ marginBottom: "10px" }} />
                </Grid>
                <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
                  <Co2Stats
                    text={text}
                    itemSize={12}
                    currIndirectPerWeek={gameState.CO2IndirectPerWeek}
                    currDirectPerWeek={gameState.CO2DirectPerUnitPerWeek}
                    standardDirectPerUnit={gameState.Co2Reports[0].Co2SalesCostPerUnit}
                    standardOpsCostPerWeek={gameState.Co2Reports[0].Co2OperationalCostPerWeek}
                    legend={true}
                  />
                </Grid>
              </React.Fragment>
            ) : null}
            {dataset?.includes("culture") ? (
              <React.Fragment>
                <Grid item xs={12}>
                  <Divider style={{ marginBottom: "10px" }} />
                </Grid>
                <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
                  <NpsStats text={text} itemSize={12} gameState={gameState} legend={true} />
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.bankBal} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
              {dataset?.includes("gogreen") ? (
                <BankCO2BalanceChart
                  gameState={gameState}
                  text={text.gameScreen}
                  height={35}
                  currencyParser={currencyParser}
                />
              ) : (
                <BankBalanceChart
                  gameState={gameState}
                  text={text.gameScreen}
                  height={35}
                  currencyParser={currencyParser}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <CurrentOrderStatus
                gameState={gameState}
                text={text}
                referenceData={referenceData}
                currencyParser={currencyParser}
                numberParser={numberParser}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container className={classes.newsGrid} spacing={3}>
            <Grid item xs={6}>
              <StatBox
                title={text.marketingConsole.campaign}
                tooltip={text.marketingConsole.activeCampaign}
                value={gameState.ActiveCampaign}
                color="primary"
              >
                <PhoneInTalk />
              </StatBox>
            </Grid>
            <Grid item xs={6}>
              <StatBox
                title={text.marketingConsole.audience}
                tooltip={text.marketingConsole.targetAudience}
                value={gameState.ActiveAudience}
                color="primary"
              >
                <People />
              </StatBox>
            </Grid>
            <Grid item xs={12} className={classes.noVerticalPadding}>
              <InvestmentReel investmentTape={investmentTape} />
            </Grid>
            <div className={fixedHeightPaper}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {text.mdConsole.detailedNewsUpdates}
              </Typography>
              <NewsReel title="" newsItems={gameState.NewsItems} role={"main"} full />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MainScreen;
