import React from "react";
import "./orderSelector.css";
import { IGameState } from "../../types/gameState.types";
import { Button, InputAdornment, Grid, Paper, TextField, Typography, InputLabel } from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import controlsTheme from "../../pages/consoles/facilitator/controlPanels/controlsTheme";
import appStyles from "../../assets/appStyles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Labels {
  title: string;
  enterOrderNumber: string;
  lastThreeNumbers: string;
  submit: string;
  clear: string;
  feedback: string;
}

interface ChangeHandler {
  (event: any): void;
}

interface SubmitHandler {
  (value: any): void;
}

interface ClearHandler {
  (): void;
}

interface Props {
  labels: Labels;
  onChange: ChangeHandler;
  onSubmit: SubmitHandler;
  onClear: ClearHandler;
  value: string;
  feedback: any;
  role: "md" | "sales";
  gameState: IGameState;
  ariaLabel: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 40px",
  },
  cheating: {
    paddingTop: "1px",
    minHeight: "25vh",
    // maxHeight: '25vh',
    padding: "10px 40px",
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const OrderSelector: React.FC<Props> = (props) => {
  const { labels, onChange, onSubmit, onClear, value, feedback, role, gameState } = props;
  const classes = useStyles();
  const appClasses = appStyles();

  const handleSubmit = () => {
    if (gameState.RunningState == "Gone Bust" && gameState.Week < 26) {
    }
    onSubmit(value);
    onClear();
  };

  const handleClear = () => {
    onClear();
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  return (
    <Paper className={classes.cheating}>
      <div className={appClasses.panelHeader}>
        <h2 className={appClasses.panelTitle}>{labels.title}</h2>
      </div>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <Typography variant="subtitle1" component="p">
            {labels.enterOrderNumber}
          </Typography>
          <Typography variant="subtitle2" component="p">
            {labels.lastThreeNumbers}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="start">GOA00</InputAdornment>,
            }}
            value={value}
            id="mdAuthInput"
            onChange={onChange}
            onKeyDown={handleEnterPress}
            label={"Order Number"}
            aria-label={"Order Number"}
          />
          <div aria-live="polite">{feedback}</div>
        </Grid>
        <Grid item xs={12}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={controlsTheme}>
              <Button
                id="mdAuthBtn"
                variant="contained"
                onClick={handleSubmit}
                disabled={gameState.RunningState == "Ready"}
                color="primary"
                className={classes.button}
              >
                <Typography variant="subtitle2" component="p">
                  {labels.submit}
                </Typography>
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                onClick={handleClear}
                disabled={gameState.RunningState == "Ready"}
              >
                <Typography variant="subtitle2" component="p">
                  {labels.clear}
                </Typography>
              </Button>
            </ThemeProvider>
          </StyledEngineProvider>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrderSelector;
