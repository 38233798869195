import React, { Component, ReactNode, ErrorInfo } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import Shocked from "../assets/Shocked.png";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: any;
  errorInfo?: any;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <Grid container spacing={5} style={{ alignContent: "center", marginTop: "10vh" }}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img src={Shocked} alt="Bad Robot" height="200px" />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography component="span" variant="h4">
              500
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography component="span" variant="subtitle1">
              Oh no. Something unexpected happened... Try going <a href="/game/main">back to the simulation</a>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <details className="error-details">
              <summary>Click for error details</summary>
              {errorInfo && errorInfo.componentStack.toString()}
            </details>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
