import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Card, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  value: string | number;
  title: string;
}

const SalesSearchBox: React.FC<Props> = (props) => {
  const { value, title } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.margin}>
      <Typography component="p" variant="subtitle2">
        {title}
      </Typography>
      <Typography component="p" variant="subtitle1">
        <strong>{value}</strong>
      </Typography>
    </div>
  );
};

export default SalesSearchBox;
