import {
  Button,
  Checkbox,
  CssBaseline,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Archive, People, PhoneInTalk, Unarchive } from "@mui/icons-material";
import clsx from "clsx";
import React, { useContext } from "react";
import Co2Stats from "../../../components/co2Stats/Co2Stats";
import NpsStats from "../../../components/npsStats/NpsStats";
import StatBox from "../../../components/statBox/StatBox";
import { archiveLead } from "../../../modules/gameState/service";
import { IScreenProps } from "../../../types/app.types";
import { ILeadViewModel } from "../../../types/gameState.types";
import { MarketingPanel } from "../facilitator/controlPanels";
import { blue, green, red } from "@mui/material/colors";
import appStyles from "../../../assets/appStyles";
import AccessibleTooltip from "../../../components/accessibleTooltip/AccessibleTooltip";
import { AppContext } from "../../../context";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme) => ({
  padding: {
    maxWidth: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  fixedHeight: {
    //height: 'auto',
  },
  table: {
    maxHeight: "100%",
    overflowY: "auto",
  },
  tableCont: {
    //padding: theme.spacing(3),
    minHeight: "85vh",
    maxHeight: "85vh",
    paddingTop: "1px",
  },
  tableHeadText: {
    color: "white",
    fontWeight: 500,
  },
  tableCell: {
    padding: theme.spacing(0.5),
    textAlign: "center",
  },
  tableCellBg: {
    padding: theme.spacing(1),
    minWidth: "40%",
    textAlign: "left",
  },
  tableCellBgArchived: {
    opacity: 0.5,
  },
  background: {
    //backgroundColor: theme.palette.secondary.main
  },
  hundred: {
    height: "90%",
  },
  campaign: {
    paddingTop: "1px",
  },
  wrapper: {
    // overflowY: 'auto',
    minHeight: "75vh",
    maxHeight: "75vh",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: "86vw", // TODO
  },
  left: {
    textAlign: "left",
  },
  tableBody: {
    overflow: "auto",
  },
  tableContainer: {
    minHeight: "75vh",
    maxHeight: "75vh",
    overflow: "scroll",
  },
  errorBtn: {
    backgroundColor: red[500],
    color: "white",
  },
  successBtn: {
    backgroundColor: "#495abf",
    color: "white",
  },
}));

const MarketingConsole: React.FC<IScreenProps> = ({ gameState, text, referenceData, currencyParser }) => {
  document.title = `Marketing | ${text.wrapper.gameName}`;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showArchived, setShowArchived] = React.useState(false);
  const classes = useStyles();
  const appClasses = appStyles();
  const { state, dispatch } = useContext(AppContext);

  const archive = (orderNumber: string, isArchived: boolean) => {
    archiveLead(orderNumber, isArchived);
  };

  const leadSourceDetails = (lead: ILeadViewModel) => (
    <div className={classes.left}>
      <Typography variant="subtitle2" component="p">
        {lead.TimePassed}
      </Typography>
      <Typography variant="subtitle1" component="p">
        {lead.LeadSource} {text.marketingConsole.lead} - {text.marketingConsole.campaign} {lead.CampaignCode}
      </Typography>
      <p>
        {lead.ContactForename} {lead.ContactSurname} {text.marketingConsole.at} {lead.CompanyName} ({lead.ContactEmail})
      </p>
    </div>
  );

  function handleShowArchived() {
    setShowArchived(!showArchived);
  }

  const dataset = localStorage.getItem("dataset");

  return (
    <div className={classes.padding}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Paper className={classes.tableCont}>
                <div className={appClasses.panelHeader}>
                  <h2 className={appClasses.panelTitle}>{text.marketingConsole.marketingLeads}</h2>
                </div>
                <div className={classes.wrapper}>
                  <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCellBg}>{text.marketingConsole.marketingLeads}</TableCell>
                          <AccessibleTooltip title={text.marketingConsole.jobTitle}>
                            <TableCell className={classes.tableCell} style={{ minWidth: "10%" }}>
                              TITLE
                            </TableCell>
                          </AccessibleTooltip>
                          <AccessibleTooltip title={text.marketingConsole.orderNumber}>
                            <TableCell className={classes.tableCell} style={{ minWidth: "15%" }}>
                              #
                            </TableCell>
                          </AccessibleTooltip>
                          <AccessibleTooltip title={text.marketingConsole.customer}>
                            <TableCell className={classes.tableCell} style={{ minWidth: "12%" }}>
                              CUST
                            </TableCell>
                          </AccessibleTooltip>
                          <TableCell className={classes.tableCell} style={{ minWidth: "7%" }}>
                            {text.marketingConsole.product}
                          </TableCell>
                          <AccessibleTooltip title={text.marketingConsole.estOrderValue}>
                            <TableCell className={classes.tableCell} style={{ minWidth: "12%" }}>
                              VALUE
                            </TableCell>
                          </AccessibleTooltip>

                          <TableCell className={classes.tableCell} style={{ minWidth: "6%" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gameState.AvailableMarketingLeads.map((lead) => (
                          <TableRow key={lead.OrderNumber}>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableCellBg}
                              style={lead.isArchived ? { opacity: 0.5 } : {}}
                            >
                              {leadSourceDetails(lead)}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={lead.isArchived ? { opacity: 0.5 } : {}}>
                              {lead.JobTitle}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={lead.isArchived ? { opacity: 0.5 } : {}}>
                              {lead.OrderNumber}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={lead.isArchived ? { opacity: 0.5 } : {}}>
                              {lead.PreviousCustomer}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={lead.isArchived ? { opacity: 0.5 } : {}}>
                              {lead.ProductName}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={lead.isArchived ? { opacity: 0.5 } : {}}>
                              {currencyParser.format(lead.TotalOrderValue)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Button
                                onClick={() => archive(lead.OrderNumber, lead.isArchived)}
                                color="primary"
                                variant="outlined"
                                name={lead.isArchived ? "Unarchive" : "Archive"}
                              >
                                {lead.isArchived ? (
                                  <AccessibleTooltip title="Unarchive Lead">
                                    <React.Fragment>
                                      <Unarchive fontSize="small" style={{ color: "#495abf" }} />
                                      <Typography>Unarchive Lead</Typography>
                                    </React.Fragment>
                                  </AccessibleTooltip>
                                ) : (
                                  <AccessibleTooltip title="Archive Lead">
                                    <React.Fragment>
                                      <Archive fontSize="small" color={"error"} />
                                      <Typography>Archive Lead</Typography>
                                    </React.Fragment>
                                  </AccessibleTooltip>
                                )}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <StatBox
                    title={text.marketingConsole.activeCampaign}
                    value={gameState.ActiveCampaign}
                    color="primary"
                  >
                    <PhoneInTalk />
                  </StatBox>
                </Grid>
                <Grid item xs={6}>
                  <StatBox
                    title={text.marketingConsole.targetAudience}
                    value={gameState.ActiveAudience}
                    color="primary"
                  >
                    <People />
                  </StatBox>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.campaign}>
                    <div className={appClasses.panelHeader}>
                      <h2 className={appClasses.panelTitle}>{text.facilitatorConsole.marketingCampaigns}</h2>
                    </div>
                    <MarketingPanel
                      gameState={gameState}
                      referenceData={referenceData}
                      text={text.facilitatorConsole}
                      console={"Marketing"}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} style={{ width: state.isLargeScreen ? "auto" : "86vw" }}>
                  {dataset?.includes("gogreen") ? (
                    <Co2Stats
                      text={text}
                      itemSize={6}
                      currIndirectPerWeek={gameState.CO2IndirectPerWeek}
                      currDirectPerWeek={gameState.CO2DirectPerUnitPerWeek}
                      standardDirectPerUnit={gameState.Co2Reports[0].Co2SalesCostPerUnit}
                      standardOpsCostPerWeek={gameState.Co2Reports[0].Co2OperationalCostPerWeek}
                    />
                  ) : null}
                  {dataset?.includes("culture") ? <NpsStats text={text} itemSize={6} gameState={gameState} /> : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default MarketingConsole;
