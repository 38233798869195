import React, { useContext } from "react";
import "./timeline.css";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AppContext } from "../../context";

interface IItemRenderer {
  (item: any): JSX.Element;
}

interface Props {
  title: string;
  items: any[];
  renderer: IItemRenderer;
  role?: "main" | "md" | "none";
}

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    paddingLeft: theme.spacing(1),
  },
  timeline: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Timeline: React.FC<Props> = ({ title, items, role = "none", renderer }) => {
  const { state, dispatch } = useContext(AppContext);
  const classes = useStyles();
  return (
    // <div className="timeline">
    //     <div className="timelineHead">
    //         <h4>{title}</h4>
    //     </div>
    //     <div className="timelineBody">
    <React.Fragment>
      <h3 className={classes.padding}>{title}</h3>
      {items.map((item, index) => (
        <div className={`timelineItem ${index === 0 ? `background_${role}` : ""} ${classes.timeline}`} key={index}>
          <div className="timelineItemContent">{renderer(item)}</div>
        </div>
      ))}
    </React.Fragment>
    //     </div>
    // </div>
  );
};

export default Timeline;
