import React from "react";
import { useEffect, useState } from "react";
import Papa from "papaparse";
import chatbotMessages from "../../assets/chatbot-messages.csv";

const MessageParser = ({ children, actions }) => {
  const [commandMessages, setCommandMessages] = useState([]);

  const parse = (message) => {
    const lowerCase = message.toLowerCase();

    if (message.includes("hello")) {
      actions.handleHello();
    }
    if (
      lowerCase.includes("messageparser") ||
      lowerCase.includes("parse") ||
      lowerCase.includes("parser") ||
      lowerCase.includes("message parser")
    ) {
      return actions.handleMessageParserDocs();
    }

    if (lowerCase === "restart tour") {
      return actions.handleWelcomeTourAction();
    }
    if (lowerCase.includes("welcome") || lowerCase.includes("tour") || lowerCase.includes("guide")) {
      return actions.handleWelcomeTourRequest();
    }

    if (lowerCase.includes("action") || lowerCase.includes("actionprovider")) {
      return actions.handleActionProviderDocs();
    }

    if (lowerCase.includes("config")) {
      return actions.handleConfigDocs();
    }

    if (lowerCase.includes("widget")) {
      return actions.handleWidgetDocs();
    }

    if (lowerCase.includes("finance")) {
      return actions.handleFinanceDocs();
    }

    if (lowerCase.includes("tim") || lowerCase.includes("rocket")) {
      return actions.handleSimpleMessages("That would be an ecumenical question.");
    }

    for (const command of commandMessages) {
      if (command.keywords.some((keyword) => lowerCase.includes(keyword))) {
        return actions.handleSimpleMessages(command.message);
      }
    }

    return actions.handleDefault(message);
  };

  useEffect(() => {
    fetch(chatbotMessages)
      .then((response) => response.text())
      .then((csvText) => {
        Papa.parse(csvText, {
          header: false,
          skipEmptyLines: true,
          complete: (results) => {
            const parsedData = results.data.map((row) => ({
              keywords: row[0].split(",").map((keyword) => keyword.trim().replace(/(^"|"$)/g, "")),
              message: row[1].trim().replace(/(^"|"$)/g, ""),
            }));
            setCommandMessages(parsedData);
          },
        });
      });
  }, []);

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
