import React, { useContext } from "react";

import Timeline from "../timeline/Timeline";

import { INewsItemViewModel } from "../../types/gameState.types";
import { Typography } from "@mui/material";
import { AppContext } from "../../context";

interface Props {
  title: string;
  newsItems: INewsItemViewModel[];
  role: "main" | "md";
  full?: boolean;
}

const NewsReel: React.FC<Props> = ({ title, newsItems, role, full = false }) => {
  const { state } = useContext(AppContext);

  // TODO: Currently restricting news to 2 items for demo. Change this if custom news items created for demo.

  return (
    <Timeline
      title={title}
      items={state.gameState.AutoFac ? newsItems.slice(0, 2) : newsItems}
      renderer={(newsItem: INewsItemViewModel) => (
        <>
          <Typography variant="h5" component="p">
            {newsItem.TimePassed}
          </Typography>
          <Typography variant="h4" component="p">
            {newsItem.Headline}
          </Typography>
          <p>{full ? newsItem.FullArticle : newsItem.Summary}</p>
        </>
      )}
      role={role}
    />
  );
};

export default NewsReel;
