import React, { useState, useCallback, useEffect } from "react";
import JoyRide, { ACTIONS, LIFECYCLE, STATUS } from "react-joyride";
import { IWrapperText } from "../../types/text.types";
import { Box, Paper, useTheme } from "@mui/material";
import { RunCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IGameState } from "../../types/gameState.types";

interface Props {
  text: IWrapperText;
  gameState: IGameState;
  run: boolean;
  setRun: (val: boolean) => void;
}

// Tour component
const DemoWrapUpTour: React.FC<Props> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const currencyLocale = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    currencySign: "accounting",
  });

  const defaultOptions = {
    arrowColor: "#fff",
    backgroundColor: "#fff",
    beaconSize: 36,
    overlayColor: "rgba(0, 0, 0, 0.5)",
    primaryColor: theme.palette.primary.main,
    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
    textColor: "#333",
    width: undefined,
    zIndex: 100,
  };

  const TOUR_STEPS = [
    {
      target: ".tourMain",
      content: (
        <iframe width="420" height="315" title="Welcome video" src="https://youtu.be/embed/Oig9oJ6PvaU"></iframe>
      ),
      title: `Congratulations on finishing ${props.text.gameName}!`,
      styles: {
        //this styles override the styles in the props
        options: {
          //width of modal
          width: "600",
        },
      },
      disableBeacon: true,
    },
    {
      target: ".tourMain",
      content: (
        <Box>
          <h3>How did you do?</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourTradingReportCash",
      content: (
        <Box>
          <h3>Trading Report - Cash</h3>
          <p>
            {props.gameState.TradingReports[0].EndBalance > props.gameState.TradingReports[0].StartingBalance ? (
              <span>End Balance &gt; Start Balance - Yay!</span>
            ) : (
              <span>End Balance &lt; Start Balance - Oops!</span>
            )}
          </p>
          <p>
            End Balance: {currencyLocale.format(props.gameState.TradingReports[0].EndBalance)} - Starting Balance:{" "}
            {currencyLocale.format(props.gameState.TradingReports[0].StartingBalance)} ={" "}
            {props.gameState.TradingReports[0].EndBalance > props.gameState.TradingReports[0].StartingBalance ? (
              <span style={{ color: "green" }}>
                {currencyLocale.format(props.gameState.TradingReports[0].NETChange)}
              </span>
            ) : (
              <span style={{ color: "red" }}>{currencyLocale.format(props.gameState.TradingReports[0].NETChange)}</span>
            )}
          </p>
        </Box>
      ),
    },
    {
      target: ".tourTradingReportOrders",
      content: (
        <Box>
          <h3>Trading Report - Order Completion</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourTradingReportSales",
      content: (
        <Box>
          <h3>Trading Report - Sales</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourTradingReportIndirect",
      content: (
        <Box>
          <h3>Trading Report - Indirect Costs</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourMain",
      content: (
        <Box>
          <h3>Cash Report</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourCashReport",
      content: (
        <Box>
          <h3>Cash Report</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourMain",
      content: (
        <Box>
          <h3>Process Report</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourProcessReport",
      content: (
        <Box>
          <h3>Process Report</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourMain",
      content: (
        <Box>
          <h3>CO2 Report</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourCO2ReportSales",
      content: (
        <Box>
          <h3>CO2 Report - Sales Emissions</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourCO2ReportOperations",
      content: (
        <Box>
          <h3>CO2 Report - Operational Emissions</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourCO2ReportTotal",
      content: (
        <Box>
          <h3>CO2 Report - Total Emissions</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourMain",
      content: (
        <Box>
          <h3>Production Report</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourProductionReportTable",
      content: (
        <Box>
          <h3>Production Report</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
    {
      target: ".tourProductionReportChart",
      content: (
        <Box>
          <h3>Production Report - Chart</h3>
          <p>Let&apos;s take a look at the reports and see...</p>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const storedRunStatus = localStorage.getItem("tourStatus");

    if (storedRunStatus === null) {
      props.setRun(true);
      localStorage.setItem("tourStatus", "true");
    } else {
      props.setRun(storedRunStatus === "true");
    }
  }, []);

  const callback = (data: any) => {
    if (data.status === STATUS.RUNNING && data.lifecycle === LIFECYCLE.READY) {
      if (data.index === 1) {
        navigate("/game/reports/trading");
      } else if (data.index === 6) {
        navigate("/game/reports/cash");
      } else if (data.index === 8) {
        navigate("/game/reports/lead_cash");
      } else if (data.index === 10) {
        navigate("/game/reports/co2");
      } else if (data.index === 14) {
        navigate("/game/reports/production");
      }
    }
  };

  return (
    <>
      <JoyRide
        steps={TOUR_STEPS}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        run={props.run}
        styles={{
          options: defaultOptions,
        }}
        callback={callback}
      />
    </>
  );
};
export default DemoWrapUpTour;
